import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable, ReplaySubject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { SignatureCaptureBusiness } from './signature-capture.business';
import { VoidReasonComponent } from '../void-reason/void-reason.component';
import { MatDialog } from '@angular/material/dialog';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { SigcapLog,SigcapLogSearchRequest,UISigcap,CloseFailedDMPosting } from 'src/app/retail/shop/view-categories/signature-capture/signature-capture.model';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import {SignatureCaptureService} from "src/app/retail/shop/signature-capture/signature-capture.service"
import _ from 'lodash';

@Component({
  selector: 'app-signature-capture',
  templateUrl: './signature-capture.component.html',
  styleUrls: ['./signature-capture.component.scss'],
  providers: [SignatureCaptureBusiness],
  encapsulation: ViewEncapsulation.None
})
export class SignatureCaptureComponent implements OnInit {

  signatureCapForm: FormGroup;
  retryButton: ButtonValue;
  closeButton: ButtonValue;
  cancelButton: ButtonValue;
  searchButton: ButtonValue;
  refreshButton: ButtonValue;
  captions: any;
  floatLabel: string;
  commonCaptions: any;
  advanceForm: FormGroup;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  headerOptions: TableHeaderOptions[];
  originalData$: Promise<UISigcap[]>;
  originalData: Observable<UISigcap[]>;
  tableOptions: TableOptions;
  tableContent: Observable<any[]>;
  isAllSelected: boolean;
  count: number = 0;
  searchText = '';
  isBatchProcessPending: boolean = false;
  userList: any[] = [];
  batchInProgressMsg:string;


  constructor(private fb: UntypedFormBuilder,private localization: RetailLocalization,public propertyInfo: RetailPropertyInformation,
    private business :SignatureCaptureBusiness,public dialog: MatDialog,private utilities: RetailUtilities,private sigcapService:SignatureCaptureService) {
    this.captions = this.localization.captions.shop;
    this.commonCaptions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.formGenerator();
    this.tableOptions = this.business.getTableOptions();
    this.headerOptions = this.business.getHeaderOptions();

    this.retryButton = {
      type: 'primary',
      label: this.captions.retry,
      disabledproperty: true
    };
    
    this.closeButton = {
      type: 'primary',
      label: this.captions.Close,
      disabledproperty: true
    };
    this.refreshButton = {
      type: 'primary',
      label: this.captions.TransactionLogScreen.Refresh,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.reset,
      disabledproperty: true
    };
    this.searchButton = {
      type: 'primary',
      label: this.captions.search,
      disabledproperty: false
    };
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.reset,
      disabledproperty: true
    };
  

    this.batchInProgressMsg = this.captions.TransactionLogScreen.BatchInProgressBannerLbl;
    this.advanceForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.searchButton.disabledproperty = false;
      this.searchButton = {...this.searchButton};
      this.cancelButton.disabledproperty = !( this.advanceForm.valid && this.advanceForm.dirty);
      this.cancelButton = {...this.cancelButton};
    });
    this.getMasterData();
  }
  formGenerator() {
    this.signatureCapForm = this.fb.group({
      fromDate : this.propertyInfo.CurrentDate,
      toDate : this.propertyInfo.CurrentDate,
      tableData : ''
    });
    this.advanceForm = this.fb.group({
      ticketNumber : '',
      user : '',
      transactionType: ''
    });
  }
  tableRetry(e, key) {
    this.RetryDMPosting([e.id] , [e.transactionId]);
  }
  async getMasterData(){
    this.userList = await this.sigcapService.getClerkInfo();
    await this.isBatchProcessingPending();
    this.getFailedDMPosting(true);
  }

  async getFailedDMPosting(isFromRefresh: boolean = false, isFromDayEnd: boolean = false){
    this.utilities.ToggleLoader(true, this.commonCaptions.lbl_processing);
    let clerkIds = this.advanceForm.controls.user.value ?
    this.userList.filter(x => (x.userName.toLowerCase().includes(this.advanceForm.controls.user.value.toLowerCase()))) : [];
    if (clerkIds.length === 0 && this.advanceForm.controls.user.value) {
     clerkIds = [{id : 0}];
    }
    const request: SigcapLogSearchRequest = {
      ticketNumber: this.advanceForm.controls.ticketNumber.value,
      startDate: this.localization.ConvertDateToISODateTime(this.signatureCapForm.controls.fromDate.value),
      endDate: this.localization.ConvertDateToISODateTime(this.signatureCapForm.controls.toDate.value),
      transactionType: this.advanceForm.controls.transactionType.value,
      clerkIdList:  clerkIds ? clerkIds.map(x => x.id) : [],
      isAllSelected: false,
      id: [],
      transactionId: [],
    };
    this.originalData$ = this.business.getTableContent(request,this.userList);
    this.originalData$.then(response => {
      this.count = response?.length;
      response = _.orderBy(response, 'ticketNumber', 'asc');
      if (this.isBatchProcessPending){
        this.headerOptions[0].isCheckboxDisabled = true;
        response.forEach(row => row.isCheckBoxDisabled = true);
      }else{
        this.headerOptions[0].isCheckboxDisabled = false;
        response.forEach(row => row.isCheckBoxDisabled = false);
      }
      this.originalData = of(response);
      this.tableContent = this.originalData;
      this.signatureCapForm.get('tableData').setValue(response);
      if (!isFromRefresh){
        this.refreshGrid(true);
        this.searchButton.disabledproperty = false;
      }else {
        this.DisableActionsForBatchRetry(true);
        this.utilities.ToggleLoader(false);
      }
    });
  }
  tableClose(e, key) {
    const dialogRef = this.dialog.open(VoidReasonComponent, {
      height: 'auto',
      width: '40%',
      data: { headername: this.localization.captions.shop.ReasonForClose, closebool: true },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(async resultData => {
      if (resultData?.action !== 'cancel') {
        const request: CloseFailedDMPosting = {
          ids: [e.id],
          closeComment: resultData.reason
        }
        await this.CloseFailedDMPosting(request);
        this.getTableData();
      }

    });
  }
  tableAction(e) {
    switch (e.fromType) {
      case FromTypeEnum.allcheckbox:
        this.isAllSelected = e.value;
        this.settableData(e);
        this.retryButton.disabledproperty = !e.value;
        this.closeButton.disabledproperty = !e.value;
        break;
      case FromTypeEnum.rowcheck:
        this.settableData(e);
        const datalength = this.signatureCapForm.get('tableData').value;
        this.isAllSelected = datalength && datalength?.length === datalength.filter(x => x.checked)?.length;
        this.retryButton.disabledproperty = e.checkedData.length > 0 ? false : true;
        this.closeButton.disabledproperty = e.checkedData.length > 0 ? false : true;
        break;
      default:
        this.retryButton.disabledproperty = true;
        this.closeButton.disabledproperty = true;
    }
  }
  settableData(e) {
    this.signatureCapForm.markAsDirty();
    this.signatureCapForm.get('tableData').setValue(e.array);
  }
  search(e) {
    this.refreshGrid(true);
  }
  async refreshGrid($event){
    this.retryButton.disabledproperty = true;
    this.refreshButton.disabledproperty = true;
    this.closeButton.disabledproperty = true;
    await this.isBatchProcessingPending();
    try {
        this.getFailedDMPosting(true);
    } catch (ex) {
      this.utilities.ToggleLoader(false);
      this.DisableActionsForBatchRetry(true);
    }
  }
  DisableActionsForBatchRetry(disable: boolean){
    this.retryButton.disabledproperty = disable;
    this.retryButton = {...this.retryButton};
    this.closeButton.disabledproperty = disable;
    this.closeButton = {...this.closeButton};
  }
  cancel(e) {
    this.advanceForm.reset();
    this.signatureCapForm.get('fromDate').setValue(this.propertyInfo.CurrentDate);
    this.signatureCapForm.get('toDate').setValue(this.propertyInfo.CurrentDate);
    this.retryButton.disabledproperty = true;
    this.closeButton.disabledproperty = true;
    // this.tableContent = of([]);
    this.count = 0;
    this.signatureCapForm.get('tableData').setValue(this.tableContent);
    this.searchButton.disabledproperty = false;
   }
   retry(e) {
    const data = this.signatureCapForm.get('tableData').value.filter(x => x.checked);
     this.RetryDMPosting(data.map(x => x.id) , data.map(x => x.transactionId))
   }
   close(e) {
    const dialogRef = this.dialog.open(VoidReasonComponent, {
      height: 'auto',
      width: '40%',
      data: { headername: this.localization.captions.shop.ReasonForClose, closebool: true },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(async resultData => {
      if (resultData.action.toLowerCase() === 'ok') {
        const request :CloseFailedDMPosting = {
          ids : this.signatureCapForm.get('tableData').value.filter(x => x.checked).map(x => x.id),
          closeComment : resultData.reason
        }
       await this.CloseFailedDMPosting(request);
       this.getTableData();
      }
    });
  }
  fromDate(e) {
    const startDate = this.localization.getDate(this.signatureCapForm.controls.fromDate.value);
    const endDate = this.localization.getDate(this.signatureCapForm.controls.toDate.value);
    if (startDate >= endDate) {
      this.signatureCapForm.controls.toDate.setValue(startDate);
    }
    this.searchButton.disabledproperty = false;
  }

  toDate(e) {
    const startDate = this.localization.getDate(this.signatureCapForm.controls.fromDate.value);
    const endDate = this.localization.getDate(this.signatureCapForm.controls.toDate.value);
    if (startDate >= endDate) {
      this.signatureCapForm.controls.fromDate.setValue(endDate);
    }
    this.searchButton.disabledproperty = false;
  }

  async CloseFailedDMPosting(request:CloseFailedDMPosting){
    await this.business.CloseFailedDMPosting(request);
  }

  getTableData() {
    this.searchButton.disabledproperty = false;
    this.searchButton = {...this.searchButton};
    try{
      this.refreshGrid(true);
    }
    catch (ex) {
        this.utilities.ToggleLoader(false);
        this.searchButton.disabledproperty = false;
        console.log(ex);
    }
  }

  RetryDMPosting(ids: number[], transactionIds: number[]) {
    const clerkIds = this.advanceForm.controls.user.value ?
    this.userList.filter(x => (x.userName.toLowerCase().includes(this.advanceForm.controls.user.value.toLowerCase()))) : [];
    const request: SigcapLogSearchRequest = {
      startDate: this.localization.ConvertDateToISODateTime(this.signatureCapForm.controls.fromDate.value),
      endDate: this.localization.ConvertDateToISODateTime(this.signatureCapForm.controls.toDate.value),
      id: ids,
      transactionId: transactionIds,
      isAllSelected: this.isAllSelected,
      clerkIdList: clerkIds ? clerkIds.map(x => x.id) : [],
      ticketNumber: this.advanceForm.controls.ticketNumber.value,
      transactionType: this.advanceForm.controls.transactionType.value,
     };
    this.utilities.ToggleLoader(true,this.commonCaptions.lbl_processing);
    try{
      this.business.RetryDMPosting(request).then(x => {
        console.log(x);
        this.getTableData();
    });
    }
    catch (ex) {
      this.utilities.ToggleLoader(false);
    }
  }

  async isBatchProcessingPending(){
    const result = Boolean((await this.business.isBatchProcessingPending()).result) ;
    this.isBatchProcessPending = result;
    this.refreshButton.disabledproperty = !result;
    if (result) {
        this.DisableActionsForBatchRetry(result);
      }
    this.utilities.ToggleLoader(false);
  }
}

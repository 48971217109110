<label class="approval-status"><span class="status-color {{className}}"></span><span class="status-label">{{params.value}}</span>
    <span *ngIf="approvalStatusField && params.colDef?.field === 'approvalstatusid'" class="icon-information"  (click)="infoClick($event)"></span>
    <span *ngIf="approvalStatusField && params.colDef?.field === 'ApprovalStatus'" [matMenuTriggerFor]="commentsMenu"
    #clickInfoIconTrigger="matMenuTrigger" (click)="$event.stopPropagation()" class="icon-information"></span></label>
<mat-menu (click)="$event.stopPropagation()" class="comments-info" #commentsMenu="matMenu" xPosition="after" yPosition="below" >
    <div class="comments-header" (click)="$event.stopPropagation()">
        <label class="subheading-medium">{{params.value}}</label>
        <span class="cancel-icon icon-requisition-denied" (click)="closeMenu()"></span>
    </div>
    <div class="comments-scroll" (click)="$event.stopPropagation()">
        <div class="comments-content" (click)="$event.stopPropagation()">
           <mat-label class="supplier-name-label">{{params.data.Name}}</mat-label><br>
           <mat-label class="supplier-comments-label product-body-2">{{params.data.Comments}}</mat-label>
        </div>
    </div>
</mat-menu>
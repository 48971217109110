import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, ReplaySubject, from, of } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { TableOptions, ButtonValue, DropdownOptions, AgTimeConfig, AgDropdownConfig, TableHeaderOptions, AgysControlOnDateChange} from 'src/app/common/Models/ag-models';
import { EventSchedulerHistoryBusiness } from './event-scheduler-history.business';
import { MatDialogRef } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { CommonPropertyInformation } from '../../services/common-property-information.service';
import {UI, API, JobStatus} from 'src/app/common/shared/shared/event-scheduler-history/event-scheduler-history.model';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { Product } from "src/app/common/Models/common.models";
import * as saveAs from 'file-saver'
@Component({
  selector: 'app-event-scheduler-history',
  templateUrl: './event-scheduler-history.component.html',
  styleUrls: ['./event-scheduler-history.component.scss'],
  providers: [EventSchedulerHistoryBusiness],
  encapsulation: ViewEncapsulation.None
})
export class EventSchedulerHistoryComponent implements OnInit {
  captions: any;
  filterForm: UntypedFormGroup;
  applyButton: ButtonValue;
  cancelButton: ButtonValue;
  IsEditModeEnabledFlag = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchText = '';
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  tableContent: Observable<UI.ScheduleJobHistory[]>;
  originalTableContent: Observable<UI.ScheduleJobHistory[]>;
  breakPointNumber: number;
  isViewOnly = false;
  @ViewChild('filterPopover') filterPopover;
  usersDropDownConfig: AgDropdownConfig;
  usersDropDownOption: Promise<DropdownOptions[]>;
  extractNameDropDownConfig: AgDropdownConfig;
  extractNameDropDownOption: Promise<DropdownOptions[]>;
  fromTimeInput: AgTimeConfig;
  toTimeInput: AgTimeConfig;
  jobTypeFilterOptions: { id: number; value: string; viewValue: string; }[];
  statusFilterOptions: { id: number; value: string; viewValue: string; }[];
  minStartDate : Date;
  minEndDate: Date;
  maxDate : Date;
  jobStatusEnum = JobStatus;
  currentProductId:number;
  zipFileName = "AutoInvoice.zip";
  constructor(private localization: Localization,
              private dialogRef: MatDialogRef<EventSchedulerHistoryComponent>,
              private fb: UntypedFormBuilder,
              private business: EventSchedulerHistoryBusiness,
              private utilities: CommonUtilities,
              private propertyInformation: CommonPropertyInformation) {
    this.captions = this.localization.captions;
    this.currentProductId = Number(this.utilities.GetPropertyInfo('ProductId'));
  }

  ngOnInit(): void {
    this.formGenerator();
    this.initializeInputs();
    this.initializeTable();
  }

  formGenerator() {
    this.filterForm = this.fb.group({
      jobType: 0,
      fromDate: '',
      toDate: '',
      status: 0
    });

    this.filterFormDefaultValuePatch();
    this.filterForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.applyButton.disabledproperty = !(this.filterForm.valid);
    });
  }

  filterFormDefaultValuePatch(){
    const propDate = this.localization.getDate(new Date());
    let minimumDate = propDate;
    minimumDate.setDate(minimumDate.getDate() - 30);
    this.minStartDate = minimumDate;
    this.filterForm.controls.fromDate.setValue(minimumDate);
    this.filterForm.controls.toDate.setValue(this.localization.getDate(new Date()));
    this.minEndDate = propDate;
    this.maxDate = propDate;
    this.filterForm.controls.jobType.setValue(0);
    this.filterForm.controls.status.setValue(0);
  }


  initializeInputs() {
    this.jobTypeFilterOptions = this.business.getJobTypeFilterOptions();
    this.statusFilterOptions = this.business.getStatusFilterOptions();
    this.applyButton = {
      type: 'primary',
      label: this.captions.btn_apply,
      disabledproperty: true
    };

    this.cancelButton = {
      type: 'secondary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    };
  }

  initializeTable() {
    this.tableOptions = this.business.getTableOptions();
    this.headerOptions = this.business.getHeaderOptions();
    this.applyDefaultFilter();
  }

  searchValueChange(event) {
    this.searchText = event;
  }

  viewMessage(e){
    console.log(e);
  }

  async applyDefaultFilter() {
    const formValues = this.filterForm.getRawValue();
    const fromDate = formValues.fromDate ? this.localization.convertDateTimeToAPIDateTime(this.localization.getDate(formValues.fromDate)) : '';
    const toDate = formValues.toDate ? this.localization.convertDateTimeToAPIDateTime(this.localization.getDate(formValues.toDate)) : '';
    const jobType = formValues.jobType;
    const jobStatus = formValues.status;
    const filter: API.ScheduleJobHistoryFilter = {
      from: fromDate,
      to: toDate,
      jobType: jobType === 0 ? null: jobType,
      jobStatus: jobStatus === 0 ? null: Number(jobStatus) - 1
    };
    this.refreshTable(filter);
  }

  applyCustomFilter() {
    const formValues = this.filterForm.getRawValue();
    const fromDate = formValues.fromDate ? this.localization.convertDateTimeToAPIDateTime(this.localization.getDate(formValues.fromDate)) : '';
    const toDate = formValues.toDate ? this.localization.convertDateTimeToAPIDateTime(this.localization.getDate(formValues.toDate)) : '';
    const filter: API.ScheduleJobHistoryFilter = {
      from: fromDate,
      to: toDate,
      jobType: formValues.jobType === 0 ? null: formValues.jobType,
      jobStatus: formValues.status === 0 ? null: Number(formValues.status) - 1
    };
    this.refreshTable(filter);
    this.filterPopover.hide();
  }

    async tableAction(event) {
        switch (event.fromType) {
            case FromTypeEnum.menuoption:
                const menuaction = event.Obj.id;
                switch (menuaction) {
                    case UI.ScheduleHistoryActionOptions.download:
                        await this.exportJobOutput(event);
                        break;
                    case UI.ScheduleHistoryActionOptions.retry:
                        this.retryJobManually(event);
                        break;
                    case UI.ScheduleHistoryActionOptions.emailSentStatus:
                        break;
                }
        }
    }

    retryJobManually(event: any) {
        this.utilities.ToggleLoader(true);
        this.business.retryJobManually(event.menuData.id).then(result => {
          this.utilities.ToggleLoader(false);
        });
      }

    async exportJobOutput(event: any){
        let jobName = event.menuData.jobName;
        if(jobName == 'Auto-ARInvoice')
        {
          this.utilities.ToggleLoader(true);
          let schedulerId = event.menuData.referenceId;
          let jobHistoryId = event.menuData.id;
          let documentIds = await this.business.getAllDocumentIdsForScheduler(schedulerId, jobHistoryId);
          let res = (await this.business.getReportZipfile(documentIds)).subscribe(res => {
            saveAs(res,this.zipFileName)
          })
          console.log(documentIds);
          this.utilities.ToggleLoader(false);
        }
        else 
        {
          this.utilities.ToggleLoader(true);
          await this.business.exportJobOutput(event.menuData.id, event.menuData.downloadFormat, event.menuData.jobName);
          this.utilities.ToggleLoader(false);
        }
    }
  emitSelectedJobTypeFilter(e) {
    // if (e.value != 3) {
    //   this.filterForm.controls.fromDate.disable();
    //   this.filterForm.controls.toDate.disable();
    // }
    // else {
    //   this.filterForm.controls.fromDate.enable();
    //   this.filterForm.controls.toDate.enable();
    // }
  }

  onSyncClick(e) {
    if (this.filterForm.dirty && this.filterForm.valid) {
      this.applyCustomFilter();
    }
    else {
      this.applyDefaultFilter();
    }
  }

  refreshTable(filter: UI.ScheduleJobHistoryFilter) {
    this.utilities.ToggleLoader(true);
    this.originalTableContent = from(this.business.getJobHistoryByFilter(filter));
    this.tableContent = this.originalTableContent.pipe(map(x => x));
    this.utilities.ToggleLoader(false);
  }

  resetForm() {
    this.filterForm.reset();
  }

  cancel() {
    //this.filterForm.reset();
    this.filterFormDefaultValuePatch();
    this.filterPopover.hide();
  }

  close(e) {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  startDateChanged(arg: AgysControlOnDateChange) {
    if (this.isStartDateExceedsEndDate()) {
      this.filterForm.get('toDate').setValue(arg.dateISOString);
    }
    this.minEndDate = this.filterForm.get('fromDate').value;
  }

  isStartDateExceedsEndDate(): boolean {
    const fromDate = this.filterForm.get('fromDate').value;
    const toDate = this.filterForm.get('toDate').value;
    return this.utilities.getDateValue(fromDate) > this.utilities.getDateValue(toDate);
  }
}



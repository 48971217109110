import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from "@angular/forms";
import * as moment from "moment";
import * as _ from "lodash"; // STORAGE THE BACK ARRAY
import {
  HttpMethod,
  HttpServiceCall
} from "../../shared/service/http-call.service";
import {
  TherapistSchedule,
  TherapistComboOptions,
  TherapistScheduleEditModel,
  ServiceGroup,
  StaffScheduleRequest
} from "../../shared/business/view-settings.modals";
import { Host, ImgRefType, SPAManagementBreakPoint, PromptType, ButtonOptions, ButtonType } from "../../shared/globalsContant";
import { SpaUtilities } from "../../shared/utilities/spa-utilities";
import { MatDialog } from '@angular/material/dialog';
import { SpaLocalization } from "../../core/localization/spa-localization";
import { BaseResponse, MoreFilterOptions } from "../../shared/business/shared.modals";
import { ImageProcessorService } from "../../shared/service/image-processor-service";
import { UserAlerts } from "../../core/config/alerts-config";
import { BreakPointAccess } from "../../shared/service/breakpoint.service";
import { SpaPropertyInformation } from "../../core/services/spa-property-information.service";
import { Subscription, ReplaySubject, BehaviorSubject } from "rxjs";
import { RecurringType, editStaffSchedule } from "src/app/settings/staff-schedule/staff-schedule.service";
import { SettingsService } from "../settings.service";
import { SettingDialogPopupComponent } from "../setting-dialog-popup/setting-dialog-popup.component";
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { takeUntil } from 'rxjs/operators';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { AgFieldConfig, AgTimeConfig } from "src/app/common/Models/ag-models";
import { InstructorScheduleService } from "./instructor-schedule.service";
@Component({
  selector: 'app-instructor-schedule',
  templateUrl: './instructor-schedule.component.html',
  styleUrls: ['./instructor-schedule.component.scss'],
  providers: [InstructorScheduleService],
  encapsulation: ViewEncapsulation.None
})
export class InstructorScheduleComponent implements OnInit, OnDestroy {
  public WeeklyarrayValues: any = [];
  public MonthlyarrayValues: any = [];
  public YearlyarrayValues: any = [];
  commonCaptions: any = this.localization.captions.common;
  BookAptCaptions: any = this.localization.captions.bookAppointment;
  selectedOccuranceType: any = [];
  selectedOccurType = "Day(s)";
  weekly = false;
  daily = true;
  monthly = false;
  yearly = false;
  public yearArray: any = this.localization.monthsArray;
  occurenceArray: any = [{ "id": 'daily', "name": this.commonCaptions.Daily, "isDisabled": false }, { "id": 'weekly', "name": this.commonCaptions.Weekly, "isDisabled": false },
  { "id": 'monthly', "name": this.commonCaptions.Monthly, "isDisabled": false }, { "id": 'yearly', "name": this.commonCaptions.Yearly, "isDisabled": false }];
  public repeatTime: any = [{ "id": 0, "name": this.BookAptCaptions.First }, { "id": 1, "name": this.BookAptCaptions.Second },
  { "id": 2, "name": this.BookAptCaptions.Third }, { "id": 3, "name": this.BookAptCaptions.Fourth }]
  defaultStartTime = this.localization.LocalizeTime(this.localization.TimeToDate("09:00"));
  defaultEndtime = this.localization.LocalizeTime(this.localization.TimeToDate("21:00"));
  defaultBreakDuration;
  recurringEndTime;
  breakStartTime;
  monthSelectedDate: Date;
  monthDateMin: Date = this.propertyInfo.CurrentDate;
  monthDateMax: Date = this.propertyInfo.CurrentDate;
  minToDate: any;
  minFromDate: any;
  locationArr: any = [];
  breakType: any = [];
  appointmentConfiguration: any;
  weekstart: any;
  weekend: any;
  aWeekArr: any;
  recurring = false;
  applyAllDays = false;
  startTimeArray: any = [];
  endTimeArray: any = [];
  overAllStartTime = false;
  overAllEndTime = false;
  validOnDirty = false;
  timeFormat: any;
  ScheduleData: any[];
  DayArr: any[] = [];
  fromDate: any;
  toDate: any;
  therapistlist: TherapistComboOptions[] = [];
  therapistCreationlist: TherapistComboOptions[] = [];
  allSchedules: TherapistSchedule[] = [];
  therapistSchedule: TherapistSchedule[] = [];
  therapistAppointments: any = [];
  createDateArray: any = [];
  therapistId: any;
  OCIcon = false;
  OffIcon = false;
  monthlyDay = false;
  monthlyDate = true;
  Formgrp: UntypedFormGroup;
  TherapistLocation: any = [];
  WorkStartTime: any = [];
  WorkEndTime: any = [];
  recurringDetails; recurringMonth: UntypedFormGroup; recurringWeek: UntypedFormGroup; recurringYear: UntypedFormGroup; recurringDay: UntypedFormGroup; day: UntypedFormGroup;
  captions: any;
  blankDateTime: any = "HH:mm"
  public IsReadOnly = true;
  StaffScheduleArr: any[] = [];
  startDateSubscription: Subscription;
  endDateSubscription: Subscription;
  StaffScheduleSubscriptions: Subscription;
  isFormValid: boolean;
  recurringForm: UntypedFormGroup;
  recurringSubscription: Subscription;
  dialogSubscription: Subscription;
  recurringPeriodType: string;
  newSchedule = false;
  editSchedule = false;
  type1Array = [];
  type2Array = [];
  viewCheckedFlag: any;
  endTimeTempHolder: any;
  startTimeTempHolder: any;
  initialLoads: boolean = true;
  callCounter: number = 0;
  currentDate: Date = this.propertyInfo.CurrentDate;
  recurringData: {};
  oldStartTime: string = '';
  oldEndTime: string = '';
  dayMaxTime = "11:59 pm";
  selectedRecurringData: any;
  defaultBreakTimeCount = 1;
  breakEndTime: string;
  UpdatedRecurringData: any;
  therapistListWithGroup: any = [];
  TherSerGrp: ServiceGroup[];
  selectedSerGrp: any = [];
  selectedServiceGroup: any = [];
  DataArray: any;
  selectedArr: any;
  filterSerGrpArr: any = [];
  filterOptions: MoreFilterOptions;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  windowsize: number;
  showcount: number;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  placeholderFormat: string;
  floatLabel: string;
  RemoveBreakTimeForChange: boolean = false;
  isOverWriteExitingCopied: boolean = false;
  copyPreviousInput: AgFieldConfig;
  noOfInstanceInput: AgFieldConfig;
  showCopySection = false;
  onSaved: boolean = false;
  isActualFilter: boolean = false;
  isOnCallFilter: boolean = false;
  triggerGetTherapist: boolean = true;
  isToday: boolean = false;
  selectedDate: any;
  fromGrid: boolean = false;
  fromGridTherapistId: any;
  daysArray: any = [];
  minDate: Date = this.propertyInfo.CurrentDate;
  therapistScheduleId: number;
  startTimeInputs: AgTimeConfig;
  endTimeInputs: AgTimeConfig;
  breakStartTimeInputs: AgTimeConfig;
  breakEndTimeInputs: AgTimeConfig;
  subject : BehaviorSubject<boolean> = new BehaviorSubject(false);
  scheduleId: any;
  constructor(
    public http: HttpServiceCall,
    public Form: UntypedFormBuilder,
    public utils: SpaUtilities, public fb: UntypedFormBuilder,
    public ss: SettingsService,
    public _su: InstructorScheduleService,
    public dialog: MatDialog,
    public localization: SpaLocalization,
    public imgService: ImageProcessorService,
    public userAlerts: UserAlerts,
    public breakPoint: BreakPointAccess,
    public propertyInfo: SpaPropertyInformation,
    public commonUtils: QuickLoginUtilities,
    public _retailPropertyInfo: RetailPropertyInformation) {
    this.floatLabel = this.localization.setFloatLabel;
    this.placeholderFormat = this.localization.inputDateFormat;
    this.buildRecurringArray();
    moment.locale(this.localization.localeCode);
    this.captions = this.localization.captions.staffSchedule;
    this.minFromDate = this.propertyInfo.CurrentDate;
    this.minToDate = this.propertyInfo.CurrentDate;
    this.timeFormat = this.localization.getTimeFormat();
    this.InitializeFormData();
    this.getDateWithoutTime(this.monthSelectedDate, this.monthDateMax);
    this.type1Array = [];
    this.type2Array = [];

    this.ss.tabLoaderEnable.next(true);
  }

  changeDate($event) {
    this.recurringMonth.controls.date.setValue($event.value);
  }
  InitializeFormData() {
    this.showCopySection = false;
    this.therapistSchedule = [];
    this.therapistAppointments = [];
    this.overAllEndTime = this.overAllStartTime = this.applyAllDays = this.validOnDirty = this.OffIcon = this.OCIcon = false;
    this.ScheduleData = this._su.formScheduleData();
    this.InitializeFormGroupData();
    this.InitializeRecurringForm();
    this.GetAllTherapistByServiceGroup("GetServiceTherapistsWithGroup");
    this.GetServiceGroup("GetAllServiceGrp");
  }

  isClosed(event) {
    document.getElementById('staff-creation-section').classList.remove('transform3dnone');
    if (this.Formgrp.controls.BreakStartTime.value && !this._su.IsValidTimeForBreak(this.Formgrp.controls.R_StartTime.value, this.Formgrp.controls.BreakStartTime.value)) {
      this.Formgrp.controls.BreakStartTime.setErrors({ incorrect: true });
      this.Formgrp.controls.BreakStartTime.markAsDirty();
      this.Formgrp.controls.BreakStartTime.markAsTouched();
      return;
    } else {
      this.Formgrp.controls.BreakStartTime.setErrors(null);
    }
    if (this.Formgrp.controls.BreakEndTime.value && !this._su.IsValidTimeForBreak(this.Formgrp.controls.R_StartTime.value, this.Formgrp.controls.BreakEndTime.value)) {
      this.Formgrp.controls.BreakEndTime.setErrors({ incorrect: true });
      this.Formgrp.controls.BreakEndTime.markAsDirty();
      this.Formgrp.controls.BreakEndTime.markAsTouched();
      return;
    } else {
      this.Formgrp.controls.BreakEndTime.setErrors(null);
    }
    if (this.Formgrp.controls.BreakStartTime.value && !this._su.IsValidTime(this.Formgrp.controls.BreakStartTime.value, this.Formgrp.controls.R_EndTime.value)) {
      this.Formgrp.controls.BreakStartTime.setErrors({ incorrect: true });
      this.Formgrp.controls.BreakStartTime.markAsDirty();
      this.Formgrp.controls.BreakStartTime.markAsTouched();
      return;
    } else {
      this.Formgrp.controls.BreakStartTime.setErrors(null);
    }
    if (this.Formgrp.controls.BreakEndTime.value && !this._su.IsValidTimeForBreak(this.Formgrp.controls.BreakEndTime.value, this.Formgrp.controls.R_EndTime.value)) {
      this.Formgrp.controls.BreakEndTime.setErrors({ incorrect: true });
      this.Formgrp.controls.BreakEndTime.markAsDirty();
      this.Formgrp.controls.BreakEndTime.markAsTouched();
      return;
    } else {
      this.Formgrp.controls.BreakEndTime.setErrors(null);
    }
    if (this.Formgrp.controls.BreakEndTime.value && !this._su.IsValidTime(this.Formgrp.controls.BreakStartTime.value, this.Formgrp.controls.BreakEndTime.value)) {
      this.Formgrp.controls.BreakEndTime.setErrors({ incorrect: true });
      this.Formgrp.controls.BreakEndTime.markAsDirty();
      this.Formgrp.controls.BreakEndTime.markAsTouched();
      return;
    } else {
      this.Formgrp.controls.BreakEndTime.setErrors(null);
    }
  }

  isClosedEdit(event, index) {
    document.getElementById("staff-creation-section").classList.remove("transform3dnone");
    if (!this._su.IsValidTime(this.Formgrp.controls.WorkStartTime['controls'][index].value.StartTime, this.Formgrp.controls.WorkEndTime['controls'][index].value.EndTime)) {
      this.Formgrp.controls.WorkEndTime['controls'][index].controls.EndTime.setErrors({ incorrect: true });
      this.Formgrp.controls.WorkEndTime['controls'][index].controls.EndTime.markAsDirty();
      this.Formgrp.controls.WorkEndTime['controls'][index].controls.EndTime.markAsTouched();
      this.isFormValid = false;
    } else {
      this.Formgrp.controls.WorkEndTime['controls'][index].controls.EndTime.setErrors(null);
      this.isFormValid = this.validateSave();
    }

  }

  openPicker(event) {
    this.breakStartTimeInputs.minTime = this.Formgrp.controls['R_StartTime'].value;
    this.breakStartTimeInputs.maxTime = this.Formgrp.controls['R_EndTime'].value
    this.breakEndTimeInputs.minTime = this.Formgrp.controls.BreakStartTime.value;
    this.breakEndTimeInputs.maxTime = this.Formgrp.controls['R_EndTime'].value;
    this.breakStartTimeInputs = {...this.breakStartTimeInputs}
    this.breakEndTimeInputs = {...this.breakEndTimeInputs}
    document.getElementById('staff-creation-section').classList.add('transform3dnone');
  }

  InitializeFormGroupData() {
    this.Formgrp = this.Form.group({
      Actual: "",
      OnCall: "",
      StaffId: this.fromGridTherapistId ? [this.fromGridTherapistId, Validators.required] : ["", Validators.required],
      StartDate: this.fromGrid ? [this.localization.getDate(this.selectedDate.row1), Validators.required] : [this.propertyInfo.CurrentDate, Validators.required],
      EndDate: this.fromGrid ? [this.localization.getDate(this.selectedDate.row1), Validators.required] : [this.utils.AddDays(this.propertyInfo.CurrentDate, 6), Validators.required],
      RecurringSchedule: [false, Validators.required],
      AllDays: false,
      AllRecurringDays: false,
      OverwriteExisting: false,
      OverallStartTime: this.defaultStartTime,
      OverallEndTime: this.defaultEndtime,
      TherapistLocation: this.Form.array([this.addTherapistLocation(0, this.ScheduleData[0].locationId)]),
      WorkStartTime: this.Form.array([
        this.addWorkStartTime(0, this.ScheduleData[0].startTime)
      ]),
      WorkEndTime: this.Form.array([
        this.addWorkEndTime(0, this.ScheduleData[0].endTime)
      ]),
      StartTimelabel: "",
      EndTimelabel: "",
      BreakTime: this.breakStartTime,
      BreakStartTime: this.breakStartTime,
      BreakEndTime: this.breakEndTime,
      Comments: "",
      R_Location: "",
      R_BreakType: "",
      R_StartTime: this.defaultStartTime,
      R_EndTime: this.defaultEndtime,
      RecurringWeeksNo: 0,
      BreakTimeDuration: this.defaultBreakDuration,
      RecurringPeriodTypeSelect: "weekly",
      RemoveBreakTime: false,
      CopyTherapistSchedule: false,
      IncludeBreak: false,
      noOfWeeks: "",
      noOfInstance: ""
    });

    this.startTimeInputs = {
      className: '',
      form: this.Formgrp,
      formControlName: 'R_StartTime',
      placeHolder: this.captions.StartTime,
      isTimeRequired: false,
      automationId:"'Txt_StaffSchedule_startTime'"
    };
    this.endTimeInputs = {
      className: '',
      form: this.Formgrp,
      formControlName: 'R_EndTime',
      placeHolder: this.captions.EndTime,
      isTimeRequired: false,
      minTime: this.Formgrp.controls['R_StartTime'].value,
      automationId: "'Txt_StaffSchedule_EndTime'"
    };
    this.breakStartTimeInputs = {
      className: '',
      form: this.Formgrp,
      formControlName: 'BreakStartTime',
      placeHolder: this.captions.BreakStartTime,
      isTimeRequired: false,
      minTime: this.Formgrp.controls['R_StartTime'].value,
      maxTime: this.Formgrp.controls['R_EndTime'].value,
      automationId: "'Txt_StaffSchedule_BreakstartTime'"
    };
    this.breakEndTimeInputs = {
      className: '',
      form: this.Formgrp,
      formControlName: 'BreakEndTime',
      placeHolder: this.captions.BreakEndTime,
      isTimeRequired: false,
      minTime: this.Formgrp.controls['BreakStartTime'].value,
      maxTime: this.Formgrp.controls['R_EndTime'].value,
      automationId: "'Txt_StaffSchedule_BreakEndTime'"
    };

    for (let i = 1; i < this.ScheduleData.length; i++) {
      this.TherapistLocation = this.Formgrp.get("TherapistLocation") as UntypedFormArray;
      this.TherapistLocation.push(this.addTherapistLocation(i, this.ScheduleData[i].locationId));
      this.WorkStartTime = this.Formgrp.get("WorkStartTime") as UntypedFormArray;
      this.WorkStartTime.push(
        this.addWorkStartTime(i, this.ScheduleData[i].startTime)
      );
      this.WorkEndTime = this.Formgrp.get("WorkEndTime") as UntypedFormArray;
      this.WorkEndTime.push(
        this.addWorkEndTime(i, this.ScheduleData[i].endTime)
      );
    }
    this.monthSelectedDate = this.Formgrp.controls.StartDate.value;
    this.monthDateMax = this.Formgrp.controls.EndDate.value;
    this.clearRecurringFields();
    this.copyPreviousInput = {
      className: 'ag_form-control--lg',
      form: this.Formgrp,
      formControlName: 'noOfWeeks',
      placeHolderId: this.captions.CopyPreviousWeek,
      placeHolder: this.captions.CopyPreviousWeek,
      maxlength: 1,
      disabled: false,
      maxValue: 8,
      minValue: 1,
      maximumValueErrorMsg: 'Maximum error',
      minimumValueErrorMsg: 'Minimum error',
      automationId: 'StaffSchedule'
      // errorMessageId :
    };
    this.noOfInstanceInput = {
      className: 'ag_form-control--lg',
      form: this.Formgrp,
      formControlName: 'noOfInstance',
      placeHolderId: this.captions.NoOfInstances,
      placeHolder: this.captions.NoOfInstances,
      maxlength: 1,
      disabled: false,
      maxValue: 52,
      minValue: 1,
      maximumValueErrorMsg: 'Maximum error',
      minimumValueErrorMsg: 'Minimum error',
      automationId: 'StaffSchedule'
    };
  }

  getDateWithoutTime = (selectedStartDate, selectedEndDate) => {
    const startDate = this.utils.GetDateWithoutTime(selectedStartDate);
    const endDate = this.utils.GetDateWithoutTime(selectedEndDate);
    const dayDiff = this.ss.DateDiff.inDays(startDate, endDate) + 1;
    const monthDiff = this.ss.DateDiff.inMonths(startDate, endDate);
    const yearDiff = this.ss.DateDiff.inYears(startDate, endDate);
    this.occurenceArray.forEach(repeatType => {
      switch (repeatType.id) {
        case 'daily':
          repeatType.isDisabled = dayDiff < 1;
          break;
        case 'weekly':
          repeatType.isDisabled = dayDiff < 8;
          break;
        case 'monthly':
          repeatType.isDisabled = monthDiff < 1;
          break;
        case 'yearly':
          repeatType.isDisabled = yearDiff < 1;
          break;
      }
    });
    const selectedOccuranceValues = this.selectedOccuranceType && this.selectedOccuranceType.length > 0 ? this.occurenceArray.filter(selectedOccurances => selectedOccurances.id == this.selectedOccuranceType[0].id) : [];
    if (selectedOccuranceValues && selectedOccuranceValues.length > 0 && selectedOccuranceValues[0].isDisabled) {
      this.selectedOccurance(this.occurenceArray[0]);
    }
  }
  clearRecurringFields(): void {
    if (this.recurringDay && this.recurringDay.controls.everyValue)
      this.recurringDay.controls.everyValue.setValue("");
    if (this.recurringWeek && this.recurringWeek.controls.everyValue)
      this.recurringWeek.controls.everyValue.setValue("");
    if (this.recurringMonth && this.recurringMonth.controls.everyValue)
      this.recurringMonth.controls.everyValue.setValue("");
    this.selectedOccuranceType = [this.occurenceArray[0]];
    this.buildRecurringArray();
  }

  buildRecurringArray = () => {
    const recurringArray = this.ss.BuildWeekDayArrayForRecurring();
    this.WeeklyarrayValues = [...recurringArray];
    this.MonthlyarrayValues = [...recurringArray];
    this.YearlyarrayValues = [...recurringArray];
  }

  radioChange($event) {
    if ($event.value == 'day') {
      this.monthlyDate = false;
      this.monthlyDay = true;
    } else {
      this.monthlyDate = true;
      this.monthlyDay = false;
    }
  }
  selectedOccurance(item) {
    this.recurringDay.reset();
    this.recurringWeek.reset();
    this.recurringMonth.reset();
    this.recurringMonth.controls.dayOrDate.setValue('date');
    this.monthlyDate = true;
    this.monthlyDay = false;
    this.recurringYear.reset();
    this.buildRecurringArray();
    if (this.selectedOccuranceType.indexOf(item) == -1) {
      this.selectedOccuranceType = [];
      this.selectedOccuranceType.push(item);
    } else {
      return;
    }

    switch (item.id) {
      case 'daily':
        this.selectedOccurType = this.BookAptCaptions.Days;
        this.resetOccuranceValues(true, false, false, false);
        break;
      case 'weekly':
        this.selectedOccurType = this.BookAptCaptions.Weeks;
        this.resetOccuranceValues(false, true, false, false);
        this.type1Array = [];
        break;
      case 'monthly':
        this.selectedOccurType = this.BookAptCaptions.Months;
        this.resetOccuranceValues(false, false, true, false);
        this.type2Array = [];
        break;
      case 'yearly':
        this.selectedOccurType = "";
        this.resetOccuranceValues(false, false, false, true);
        this.type2Array = [];
        break;
      default:
        break;
    }
    this.isFormValid = this.validateSave();
  }

  resetOccuranceValues = (isDay: boolean, isWeek: boolean, isMonth: boolean, isYear: boolean) => {
    this.daily = isDay;
    this.weekly = isWeek;
    this.monthly = isMonth;
    this.yearly = isYear;
  }
  /**
   * @function addBreakpop()
   * @params none
   * @description Open the popUp When click the add therapist
   */

  addBreakpop = () => {
    const breakpointNumber = [SPAManagementBreakPoint.AddTherapistBreak];
    const isUserAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
    if (!isUserAuthorized) {
      return;
    }
    const dialogRef = this.dialog.open(SettingDialogPopupComponent, {
      height: 'auto',
      data: { headername: this.BookAptCaptions.CreateTherapistBreak, closebool: true, templatename: "AST", datarecord: "e", popupConfigs: { operation: "add" } },
      disableClose: true,
      hasBackdrop: true
    });

    this.dialogSubscription = dialogRef.afterClosed().subscribe(() => {
      this.GetAllScheduleDetailsGrid();
    });
  }

  ngOnInit() {
    this.minFromDate = this.propertyInfo.CurrentDate;
    this.minToDate = this.propertyInfo.CurrentDate;
    this.IsReadOnly = this.breakPoint.IsViewOnly(SPAManagementBreakPoint.StaffSchedule);
    this.LoadSystemConfigurations();
    [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
    this.recurringMonth.controls.dayOrDate.setValue('date');
    // this.weekstart = moment(this.propertyInfo.CurrentDate).startOf("week");
    // this.weekend = moment(this.propertyInfo.CurrentDate).endOf("week");
    this.weekstart = this.localization.getStartOfWeekTenant(this.localization.getDate(this.propertyInfo.CurrentDate));
    this.weekend = this.localization.getLastOfWeekTenant(this.localization.getDate(this.propertyInfo.CurrentDate));
    this.aWeekArr = this._su.WeekArray(
      _.cloneDeep(this.weekstart),
      _.cloneDeep(this.weekend)
    );
    this.GetAllScheduleDetailsGrid();
    this.LoadLocations();
    this.LoadBreakType();
    [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);

    this.windowSize();

    this._su.staffScheduleUpdateNotifier$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (result.type === editStaffSchedule.deleted) {
          this.GetAllScheduleDetailsGrid();
        }
      });
  }

  windowSize() {
    this.windowsize = window.innerWidth;
    this.setFiterdatacount(this.windowsize)

  }

  // Handles onchange event of the form
  onChanges(): void {
    this.startDateSubscription = this.Formgrp.get("StartDate").valueChanges.subscribe(val => {
      this.minToDate = val;
      this.monthSelectedDate = val;
    });
    this.endDateSubscription = this.Formgrp.get("EndDate").valueChanges.subscribe(val => {
      this.monthDateMax = val;
    });


    this.StaffScheduleSubscriptions = this.Formgrp.valueChanges.subscribe(() => {
      this.SetFormValidity();
    });

    this.recurringSubscription = this.recurringForm.valueChanges.subscribe(() => {
      this.SetFormValidity();
    });
  }

  SetFormValidity() {
    this.isFormValid = this.validateSave();
  }

  /**
   * The function registers the onchange function
   * @param formGroup
   * @param fn
   */
  RegisterOnChange(formGroup: UntypedFormGroup, fn: any) {
    formGroup.valueChanges.subscribe(fn);
  }

  therapistListGetCall(routeURL: string) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: routeURL,
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  GetAllTherapistByServiceGroup(routeURL: string) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: routeURL,
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    })
  }

  GetServiceGroup(routeURL: string): void {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: routeURL,
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  selectedFilter(allTherapists) {
    this.therapistlist = _.orderBy(allTherapists, x => x.Type);
    this.displayFrontEndCellData();
  }

  updateSerGrpArr(selected: any, id: any): void {
    let allTherapists = []
    let therapists = [];

    if (id == 0) {
      if (selected.indexOf(id) === -1) {
        this.filterSerGrpArr = this.TherSerGrp.map(x => x.id);
      } else {
        this.filterSerGrpArr = [];
      }
    } else {
      if (this.filterSerGrpArr.find(x => x === id) == null) {
        this.filterSerGrpArr.push(id);
        if (this.filterSerGrpArr.length == (this.TherSerGrp.length - 1)) {
          this.filterSerGrpArr = this.TherSerGrp.map(x => x.id);
        }
      }
      else {
        this.filterSerGrpArr.splice(this.filterSerGrpArr.indexOf(this.filterSerGrpArr.find(x => x === id)), 1);
        if (this.filterSerGrpArr.some(x => x === 0)) {
          this.filterSerGrpArr.splice(this.filterSerGrpArr.indexOf(this.filterSerGrpArr.find(x => x == 0)), 1);
        }
      }

    }

    this.selectedSerGrp = this.filterSerGrpArr;
    this.selectedServiceGroup = this.filterSerGrpArr;
    this.therapistListGetCall("getActiveTherapist");
  }

  onResize(e) {
    this.windowsize = window.innerWidth;
    this.setFiterdatacount(this.windowsize)
  }

  setFiterdatacount(e) {
    if (e > 1150) {
      this.showcount = 3;
    } else {
      this.showcount = 1;
    }
  }

  filterTherapists(allTherapists: any[]) {
    let filteredTherapists = [];
    if (this.selectedSerGrp.filter(c => c != 0).length > 0) {
      for (let index = 0; index < this.selectedSerGrp.length; index++) {
        let therapists = this.therapistListWithGroup.filter(r =>
          r.ServiceGroupId == this.selectedSerGrp[index]);
        therapists.forEach(element => {
          let therapist = {
            Id: element.TherapistId,
            Type: `${element.FirstName} ${element.LastName}`,
            Description: "",
            Active: element.isActive
          }
          filteredTherapists.push(therapist)
        });

        if (filteredTherapists.length > 0) {
          allTherapists = filteredTherapists.reduce((unique, o) => {
            if (!unique.some(obj => obj.Id === o.Id)) {
              unique.push(o);
            }
            return unique;
          }, []);
        }
      }
    }
    this.selectedFilter(allTherapists);
  }

  openFilterDialog(ArrType) {
    let topPos = 180,
      leftPos = 366;
    this.DataArray = this.TherSerGrp;
    this.selectedArr = this.selectedSerGrp;
    this.filterOptions = this.utils.getFilterPopUpOption(true, this.DataArray, this.selectedArr, topPos, leftPos, ArrType, 'SS');
  }

  CreateTherapistSchedule(): void {
    let allSchedules: TherapistSchedule[] = [];
    let therapist: TherapistSchedule;
    this.therapistId = this.Formgrp.controls["StaffId"].value;
    this.createDateArray = this._su.CreateDateArray(
      _.cloneDeep(this.Formgrp.controls["StartDate"].value),
      _.cloneDeep(this.Formgrp.controls["EndDate"].value)
    );
    let scheduleDay = "";
    let scheduleData: any;
    if (this.editSchedule) {
      this.utils.ToggleLoader(false);
      for (let i = 0; i < this.createDateArray.length; i++) {
        scheduleDay = this.createDateArray[i].row2;
        scheduleData = this.GetScheduleData(scheduleDay);
        if (scheduleData != null)  {
          if (!scheduleData.Off) {
            let fromTime: any;
            let toTime: any;
            if (this.editSchedule) {
              fromTime = this.Formgrp.controls["WorkStartTime"].value[0].StartTime;
              toTime = this.Formgrp.controls["WorkEndTime"].value[0].EndTime;
            } else {
              fromTime = this.Formgrp.controls["WorkStartTime"].value[scheduleData.id - 1].StartTime;
              toTime = this.Formgrp.controls["WorkEndTime"].value[scheduleData.id - 1].EndTime;
            }
            if (!this._su.IsValidTime(fromTime, toTime)) {
              const start: any = this.Formgrp.controls["WorkStartTime"];
              if (this.editSchedule) {
                start.controls[0].controls["StartTime"].setErrors({ incorrect: true });
              } else {
                start.controls[scheduleData.id - 1].controls["StartTime"].setErrors({ incorrect: true });
              }
              return;
            }
            if (fromTime && fromTime.length > 0 && toTime && toTime.length > 0) {
              const startTime = `${this.createDateArray[i].row1} ${moment(fromTime, this.localization.timeFormat).format("hh:mm a")}`;
              const endTime = `${this.createDateArray[i].row1} ${moment(toTime, this.localization.timeFormat).format("hh:mm a")}`;
              therapist = {
                id: this.scheduleId,
                therapistId: this.therapistId,
                scheduleDate: this.createDateArray[i].row1,
                isOff: scheduleData.Off,
                availableTime: [{ id: 0, fromTime: startTime, toTime: endTime, locationId: scheduleData.locationId, availableOnCall: scheduleData.onCall}],
                comments: scheduleData.comments
              };
              allSchedules.push(therapist);
            }
          } else {
            therapist = {
              id: this.scheduleId,
              therapistId: this.therapistId,
              scheduleDate: this.createDateArray[i].row1,
              isOff: scheduleData.Off,
              availableTime: null,
              comments: scheduleData.comments
            };
            allSchedules.push(therapist);
          }
        }
      }
    }
    else if (!this.recurring) {

      allSchedules = this.buildSchedule();
    }
    else {
      allSchedules = this.buildRecurringSchedule();
    }
    if (this.editSchedule) {
      this.editTherapistSchedule(this.MapToApiModel(allSchedules[0], this.oldStartTime, this.oldEndTime), allSchedules);
    } else if (this.Formgrp.controls.CopyTherapistSchedule.value) {
      this.isOverWriteExitingCopied = false;
      this.utils.ToggleLoader(true);
      this.CopyTherapistSchedule();
    } else {
      this.CreateSchedule(allSchedules);
    }
  }

  CreateSchedule(allSchedules): void {
    // Build recurring therapist schedule
    if (allSchedules != null && allSchedules.length > 0) {
      this.http.CallApiWithCallback<boolean>({
        host: Host.schedule,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "createTherapistSchedule",
        method: HttpMethod.Post,
        uriParams: {
          isOverwiteExiting: this.Formgrp.controls["OverwriteExisting"].value
        },
        body: allSchedules,
        showError: true,
        extraParams: [allSchedules]
      });
    }
  }

  CopyTherapistSchedule(): void {
    const staffSchedRequest: StaffScheduleRequest = {
      therapistId: this.therapistId,
      noOfWeeks: this.Formgrp.controls["noOfWeeks"].value,
      noOfInstance: this.Formgrp.controls["noOfInstance"].value != "" ? this.Formgrp.controls["noOfInstance"].value : 0,
      startDate: this.utils.formatDate(this.localization.getDate(this.Formgrp.controls.StartDate.value)),
      isIncludeBreak: this.Formgrp.controls.IncludeBreak.value,
      isOverwriteExisting: this.isOverWriteExitingCopied,
      comments: this.Formgrp.controls["Comments"].value
    }

    this.http.CallApiWithCallback<boolean>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "copyTherapistSchedule",
      method: HttpMethod.Put,
      uriParams: {},
      body: staffSchedRequest,
      extraParams: []
    })
  }

  buildSchedule(): TherapistSchedule[] {
    const schedule: TherapistSchedule[] = [];
    let scheduleDates: Date[] = [];
    this.createDateArray = this._su.CreateDateArray(
      _.cloneDeep(this.Formgrp.controls["StartDate"].value),
      _.cloneDeep(this.Formgrp.controls["EndDate"].value)
    );
    const startDate: Date = this.utils.GetDateWithoutTime(this.localization.getDate(this.Formgrp.controls.StartDate.value));
    const endDate: Date = this.utils.GetDateWithoutTime(this.localization.getDate(this.Formgrp.controls.EndDate.value));
    let everyValue = 0;
    if (this.daily) {
      everyValue = (this.recurringDay.controls.everyValue.value || this.recurringDay.controls.everyValue.value == 0) ? Number(this.recurringDay.controls.everyValue.value) : 1;
      scheduleDates = this.ss.getRecurringDates(RecurringType.Daily, startDate, endDate, everyValue);
    }

    if (scheduleDates && scheduleDates.length > 0) {
      const recurringdata = this.Formgrp.controls.RecurringData.value;
      let scheduleDay = "";
      let scheduleData: any;
      for (let i = 0; i < this.ScheduleData.length; i++) {
        let currentDateData = this.createDateArray.find(x => x.idOfDay == this.ScheduleData[i].id);
        if (currentDateData) {
          scheduleData = this.GetScheduleData(currentDateData.row2);
          const startTime = recurringdata.WorkStartTime[scheduleData.id - 1].StartTime;
          const endTime = recurringdata.WorkEndTime[scheduleData.id - 1].EndTime;
          if ((startTime && !endTime) || (!startTime && endTime)) {

            const scheduleDataTemp = this.GetScheduleData(currentDateData.row2);
            this.DeleteLocationError(scheduleDataTemp);
            this.DeleteStartTimeError(scheduleDataTemp);
            this.DeleteEndTimeError(scheduleDataTemp);
            this.DeleteBreakTimeError(scheduleDataTemp);
            this.DeleteBreakEndTimeError(scheduleDataTemp);

            this.SetStartTimeError(scheduleData);
            this.SetEndTimeError(scheduleData);
            return;
          }
        }
      }
      let length = 0;
      for (let i = 0; i < this.ScheduleData.length; i++) {
        let currentDateData = this.createDateArray.find(x => x.idOfDay == this.ScheduleData[i].id);
        if (currentDateData) {
          scheduleData = this.GetScheduleData(currentDateData.row2);
          let scheduleDatasOffDuty = this.GetScheduleDataWithoutRecurring(currentDateData.row2);
          const startTime = recurringdata.WorkStartTime[scheduleData.id - 1].StartTime;
          const endTime = recurringdata.WorkEndTime[scheduleData.id - 1].EndTime;
          if ((!startTime && !endTime && !scheduleDatasOffDuty.Off)) {
            length = length + 1
          }
        }
      }
      if (length >= scheduleDates.length || length == this.ScheduleData.length) {
        return;
      }
      if (this.onSaved == true) {
        const recurringDateArray = this.createDateArray.filter(res => scheduleDates.some(x => res.row1 === moment(x).format("YYYY-MM-DD")));
        for (let i = 0; i < scheduleDates.length; i++) {

          const date = scheduleDates[i];

          scheduleDay = this.createDateArray[i].row2;
          scheduleData = this.GetScheduleData(scheduleDay);
          const scheduleDatas = this.GetScheduleDataWithoutRecurring(scheduleDay);


          const startTime = recurringdata.WorkStartTime[scheduleData.id - 1].StartTime;
          const endTime = recurringdata.WorkEndTime[scheduleData.id - 1].EndTime;
          const locationId = recurringdata.TherapistLocation[scheduleData.id - 1].location;
          if (!this._su.IsValidTime(startTime, endTime)) {
            const start: any = this.Formgrp.controls["WorkStartTime"];
            start.controls[scheduleData.id - 1].controls["StartTime"].setErrors({ incorrect: true });
            return;
          }
          if (!scheduleDatas.Off && startTime && endTime) {
            schedule.push({
              availableTime: [{
                fromTime: `${this.utils.formatDate(date)} ${startTime}`,
                toTime: `${this.utils.formatDate(date)} ${endTime}`,
                locationId: locationId,
                availableOnCall: scheduleDatas.onCall,
              }],
              therapistId: this.therapistId,
              isOff: scheduleDatas.Off,
              scheduleDate: this.utils.formatDate(date),
              comments: scheduleDatas.comments
            });

          }
          else if (scheduleDatas.Off) {
            schedule.push({
              availableTime: null,
              therapistId: this.therapistId,
              isOff: scheduleDatas.Off,
              scheduleDate: this.utils.formatDate(date),
              comments: scheduleData.comments 

            });
          }
        }

      }
      else {
        schedule.push({
          availableTime: null,
          therapistId: null,
          isOff: null,
          scheduleDate: null,
          comments:""
        });
      }
    }
    return schedule;
  }

  buildRecurringSchedule(): TherapistSchedule[] {
    const schedule: TherapistSchedule[] = [];
    let scheduleDates: Date[] = [];
    const startDate: Date = this.utils.GetDateWithoutTime(this.Formgrp.controls.StartDate.value);
    const endDate: Date = this.utils.GetDateWithoutTime(this.Formgrp.controls.EndDate.value);
    let everyValue = 0;
    if (this.daily) {
      everyValue = (this.recurringDay.controls.everyValue.value || this.recurringDay.controls.everyValue.value == 0) ? Number(this.recurringDay.controls.everyValue.value) : 1;
      scheduleDates = this.ss.getRecurringDates(RecurringType.Daily, startDate, endDate, everyValue);
    } else if (this.weekly) {
      everyValue = (this.recurringWeek.controls.everyValue.value || this.recurringWeek.controls.everyValue.value == 0) ? Number(this.recurringWeek.controls.everyValue.value) : 1;
      scheduleDates = this.ss.getRecurringDates(RecurringType.Weekly, startDate, endDate, everyValue, this.WeeklyarrayValues.filter(a => this.type1Array.includes(a.name)).map(x => x.value));
    } else if (this.monthly) {
      everyValue = (this.recurringMonth.controls.everyValue.value || this.recurringMonth.controls.everyValue.value == 0) ? Number(this.recurringMonth.controls.everyValue.value) : 1;
      if (this.monthlyDay) {
        scheduleDates = this.ss.getRecurringDates(RecurringType.Monthly, startDate, endDate, everyValue,
          this.MonthlyarrayValues.filter(a => this.type2Array.includes(a.name)).map(x => x.value), null, this.recurringMonth.controls.day.get('repeatedWeek').value);
      } else if (this.monthlyDate) {
        scheduleDates = this.ss.getRecurringDates(RecurringType.Monthly, startDate, endDate, everyValue, null, this.utils.GetDateWithoutTime(this.monthSelectedDate))
      }
    } else if (this.yearly) {
      everyValue = (this.recurringYear.controls.everyValue.value || this.recurringYear.controls.everyValue.value == 0) ? Number(this.recurringYear.controls.everyValue.value) : 1;
      const repeatedWeekDay = this.recurringYear.controls.repeatedWeek.value;
      scheduleDates = this.ss.getRecurringDates(RecurringType.Yearly, startDate, endDate, everyValue,
        this.YearlyarrayValues.filter(a => this.type2Array.includes(a.name)).map(x => x.value), this.utils.GetDateWithoutTime(this.monthSelectedDate), repeatedWeekDay)
    }

    if (scheduleDates && scheduleDates.length > 0) {
      const TherapAvailableOnCall = false, TherapIsOff = false;
      const recurringdata = this.Formgrp.controls.RecurringData.value;
      let scheduleDay = "";
      let scheduleData: any;
      for (let i = 0; i < this.ScheduleData.length; i++) {
        let currentDateData = this.createDateArray.find(x => x.idOfDay == this.ScheduleData[i].id);
        if (currentDateData) {
          scheduleData = this.GetScheduleData(currentDateData.row2);
          const startTime = recurringdata.WorkStartTime[scheduleData.id - 1].StartTime;
          const endTime = recurringdata.WorkEndTime[scheduleData.id - 1].EndTime;
          if (!startTime || !endTime) {

            const scheduleDataTemp = this.GetScheduleData(currentDateData.row2);
            this.DeleteLocationError(scheduleDataTemp);
            this.DeleteStartTimeError(scheduleDataTemp);
            this.DeleteEndTimeError(scheduleDataTemp);
            this.DeleteBreakTimeError(scheduleDataTemp);
            this.DeleteBreakEndTimeError(scheduleDataTemp);

            this.SetStartTimeError(scheduleData);
            this.SetEndTimeError(scheduleData);
            return;
          }
        }
      }
      if (this.onSaved == true) {
        const recurringDateArray = this.createDateArray.filter(res => scheduleDates.some(x => res.row1 === moment(x).format("YYYY-MM-DD")));
        for (let i = 0; i < scheduleDates.length; i++) {
          const date = scheduleDates[i];
          scheduleDay = recurringDateArray[i].row2;
          scheduleData = this.GetScheduleData(scheduleDay);
          const startTime = recurringdata.WorkStartTime[scheduleData.id - 1].StartTime;
          const endTime = recurringdata.WorkEndTime[scheduleData.id - 1].EndTime;
          const locationId = recurringdata.TherapistLocation[scheduleData.id - 1].location;
          if (!this._su.IsValidTime(startTime, endTime)) {
            const start: any = this.Formgrp.controls["WorkStartTime"];
            start.controls[scheduleData.id - 1].controls["StartTime"].setErrors({ incorrect: true });
            return;
          }
          schedule.push({
            availableTime: [{
              fromTime: `${this.utils.formatDate(date)} ${startTime}`,
              toTime: `${this.utils.formatDate(date)} ${endTime}`, locationId: locationId,
              availableOnCall: TherapAvailableOnCall,
            }],
            therapistId: this.therapistId,
            isOff: TherapIsOff,
            scheduleDate: this.utils.formatDate(date),
            comments: scheduleData.comments
          });
        }
      }
      else {
        schedule.push({
          availableTime: null,
          therapistId: null,
          isOff: null,
          scheduleDate: null,
          comments: ""
        });
      }
    }
    return schedule;
  }

  DeleteLocationError(scheduleData) {
    const location: any = this.Formgrp.controls["RecurringData"]['controls']["TherapistLocation"];
    if (!location.controls[scheduleData.id - 1].controls["location"].value) {
      location.controls[scheduleData.id - 1].controls["location"].setErrors(null);
      location.controls[scheduleData.id - 1].controls["location"].markAsDirty();
      location.controls[scheduleData.id - 1].controls["location"].markAsTouched();
    }
  }

  DeleteStartTimeError(scheduleData) {
    const start: any = this.Formgrp.controls["RecurringData"]['controls']["WorkStartTime"];
    if (!start.controls[scheduleData.id - 1].controls["StartTime"].value) {
      start.controls[scheduleData.id - 1].controls["StartTime"].setErrors(null);
      start.controls[scheduleData.id - 1].controls["StartTime"].markAsDirty();
      start.controls[scheduleData.id - 1].controls["StartTime"].markAsTouched();
    }
  }

  DeleteEndTimeError(scheduleData) {
    const end: any = this.Formgrp.controls["RecurringData"]['controls']["WorkEndTime"];
    if (!end.controls[scheduleData.id - 1].controls["EndTime"].value) {
      end.controls[scheduleData.id - 1].controls["EndTime"].setErrors(null);
      end.controls[scheduleData.id - 1].controls["EndTime"].markAsDirty();
      end.controls[scheduleData.id - 1].controls["EndTime"].markAsTouched();
    }
  }

  DeleteBreakTimeError(scheduleData) {
    const breakTime: any = this.Formgrp.controls["RecurringData"]['controls']["WorkBreakTime"],
      breakTimeArr: any[] = breakTime.controls[scheduleData.id - 1].controls;
    if (Array.isArray(breakTimeArr) && breakTimeArr.length) {
      breakTimeArr.forEach((val, idx) => {
        if (!val.controls["BreakStartTime"].value) {
          val.controls["BreakStartTime"].setErrors(null);
          val.controls["BreakStartTime"].markAsDirty();
          val.controls["BreakStartTime"].markAsTouched();
        }
      })
    }
  }

  DeleteBreakEndTimeError(scheduleData) {
    const breakEndTime: any = this.Formgrp.controls["RecurringData"]['controls']["WorkBreakEndTime"],
      breakEndTimeArr: any[] = breakEndTime.controls[scheduleData.id - 1].controls;
    if (Array.isArray(breakEndTimeArr) && breakEndTimeArr.length) {
      breakEndTimeArr.forEach((val, idx) => {
        if (!val.controls["BreakEndTime"].value) {
          val.controls["BreakEndTime"].setErrors(null);
          val.controls["BreakEndTime"].markAsDirty();
          val.controls["BreakEndTime"].markAsTouched();
        }
      })
    }
  }

  SetStartTimeError(scheduleData) {
    const start: any = this.Formgrp.controls["RecurringData"]['controls']["WorkStartTime"];
    if (!start.controls[scheduleData.id - 1].controls["StartTime"].value) {
      start.controls[scheduleData.id - 1].controls["StartTime"].setErrors({ incorrect: true });
      start.controls[scheduleData.id - 1].controls["StartTime"].markAsDirty();
      start.controls[scheduleData.id - 1].controls["StartTime"].markAsTouched();
    }
  }

  SetEndTimeError(scheduleData) {
    const end: any = this.Formgrp.controls["RecurringData"]['controls']["WorkEndTime"];
    if (!end.controls[scheduleData.id - 1].controls["EndTime"].value) {
      end.controls[scheduleData.id - 1].controls["EndTime"].setErrors({ incorrect: true });
      end.controls[scheduleData.id - 1].controls["EndTime"].markAsDirty();
      end.controls[scheduleData.id - 1].controls["EndTime"].markAsTouched();
    }
  }

  GetScheduleData(day: string): any {
    return this.ScheduleData.find(s => {
      return s.day == day;
    });
  }

  GetScheduleDataByCollection(day: string): any[] {
    return this.ScheduleData.filter(s => s.day == day);
  }

  GetScheduleDataWithoutRecurring(day: string): any {
    return this._su.ScheduleData.find(s => {
      return s.day == day;
    });
  }

  async GetAllScheduleDetailsGrid() {
    if (this.triggerGetTherapist) {
      if (this.utils.getDate(this.aWeekArr[0].row1) < this.propertyInfo.CurrentDate) {
        this.therapistListGetCall("GetAllTherapist");
      } else {
        this.therapistListGetCall("getActiveTherapist");
      }
    }
    await this.getTherapistScheduleDuplicate();
    this.triggerGetTherapist = true;
  }

  async getTherapistScheduleDuplicate() {
    [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
    this.fromDate = this.utils.formatDate(this.utils.getDate(this.aWeekArr[0].row1));
    this.toDate = this.utils.formatDate(
      this.utils.getDate(this.aWeekArr[this.aWeekArr.length - 1].row1)
    );
    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetAllTherapistSchedules",
      uriParams: { fromDate: this.fromDate, toDate: this.toDate },
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
    [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
  }

  LoadLocations() {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetAllLoc",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }
  LoadBreakType() {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetBreakTypes",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  successCallback<T>(
    result: BaseResponse<T>,
    callDesc: string,
    extraParams?: any[]
  ) {

    switch (callDesc) {
      case "getActiveTherapist":
      case "GetAllTherapist": {
        this.therapistlist = [];
        let allTherapists: any = [];
        let therapistIds: any = [];
        let responseResult: any = result.result;
        if (responseResult) {
          responseResult = responseResult.filter(t => t.isActive);
          allTherapists = responseResult.map(x => {
            return {
              Id: x.id,
              Type: `${x.firstName} ${x.lastName}`,
              Description: "",
              Active: x.isActive
            };
          });
          this.filterTherapists(allTherapists);
          this.therapistCreationlist = allTherapists;
          therapistIds = responseResult.map(x => x.id);
          [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
          [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
          this.imgService.GetAllImagesByReference(therapistIds, ImgRefType.therapist, true, this.successCallback.bind(this), this.errorCallback, [])
          if (this.allSchedules.length > 0) {
            this.displayFrontEndCellData();
          }
        }
      }
        break;

      case "GetAllTherapistSchedules": {
        [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
        this.allSchedules = <any>result.result;
        this.filterTherapists(this.therapistlist);
        if (this.therapistlist.length > 0) {
          this.displayFrontEndCellData();
        }
      }
        break;
      case "getTherapistSchedule": {
        this.therapistSchedule = <any>result.result ? <any>result.result : [];
        this._su.FormatScheduleTime(this.therapistSchedule, this.locationArr);
      }
        break;
      case "createTherapistSchedule": {
        if (result.successStatus) {
          {
           this.CreateTherapistBreak(extraParams[0], this.therapistId).then(x => {
            this.GetAllScheduleDetailsGrid();
            this.resetData();
            this.utils.ToggleLoader(false);
          });
          }
        }
      }
        break;
      case "GetAllLoc":
        [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
        this.locationArr = <any>result.result;
        break;
      case "GetBreakTypes":
        this.breakType = <any>result.result;
        break;
      case "CreateTherapistBreakList":
      case "UpdateTherapistBreak":
        this.UpdatedRecurringData = null;
        this.commonUtils.resetQuickIdDetails();
        //this.resetData();
        //this.utils.ToggleLoader(false);
        this.GetAllScheduleDetailsGrid();   // reset this variable to ensure to save edit schedule without any error;
        break;
      case "getAllImagesByReference":
        {
          [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
          const images = <any>result.result;
          this.therapistlist = this.therapistlist.map(x => {
            const therapistImg = images.find(img => img.referenceId == x.Id);
            x['imgObj'] = therapistImg;
            return x;
          });
        }
        break;
      case "GetAppointmentConfiguration": {
        [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
        const res = <any>result.result;
        this.appointmentConfiguration = res ? res : [];
        const CurrentDate = this.utils.formatDate(this.propertyInfo.CurrentDate);
        const highestAppointmentOperatingHours = this.utils.getDayTimeForTheDate(JSON.parse(this.appointmentConfiguration[0]["APPOINTMENT_OPERATING_HOURS"]));
        this.defaultStartTime = this.localization.LocalizeISOTime(highestAppointmentOperatingHours["StartTime"]);
        this.defaultEndtime = this.localization.LocalizeISOTime(highestAppointmentOperatingHours["EndTime"]);
        this.defaultBreakTimeCount = this.appointmentConfiguration[0]["STAFFSCHEDULE_MAXIMUMBREAKSDURINGSCHEDULE"] ? Number(this.appointmentConfiguration[0]["STAFFSCHEDULE_MAXIMUMBREAKSDURINGSCHEDULE"]) : this.defaultBreakTimeCount;
        let shiftDuration = this.appointmentConfiguration[0]["APPOINMENT_STAFFSCHEDULESHIFTDURATION"];
        this.defaultBreakDuration = this.appointmentConfiguration[0]["APPOINMENT_BREAKDURATION"];
        let breakStartAfter = this.appointmentConfiguration[0]["APPOINMENT_MINUTESAFTERSHIFTSTARTFORBREAK"];
        let startTime = this.utils.getDate(`${CurrentDate} ${this.defaultStartTime}`);
        let recEndTime = this.localization.dateAdd.AddMins(startTime, shiftDuration);
        let propEndTime = this.utils.getDate(`${CurrentDate} ${this.defaultEndtime}`);
        this.recurringEndTime = recEndTime.getTime() > propEndTime.getTime() || recEndTime.getTime() == startTime.getTime() ? this.defaultEndtime : this.localization.LocalizeISOTime(this.utils.getTime(recEndTime, 24));
        this.breakStartTime = this.localization.LocalizeISOTime(this.utils.getTime(this.localization.dateAdd.AddMins(startTime, breakStartAfter), 24));
        this.breakEndTime = this.localization.LocalizeISOTime(this.utils.getTime(this.localization.dateAdd.AddMins(this.utils.getDate(`${CurrentDate} ${this.breakStartTime}`), this.defaultBreakDuration), 24));
        this.Formgrp.controls.BreakStartTime.setValue(this.breakStartTime);
        this.Formgrp.controls.BreakEndTime.setValue(this.breakEndTime);
      }
        break;
      case "GetAppointmentByTherapistId": {
        console.log(result.result);
        if (result && result.result) {
          const appointments: any = result.result;
          const therapistAppointments = appointments.map(x => {
            x.appointmentTime = this.utils.FormatAppointmentDateTime(x.startTime, x.endTime);
            x.locationName = x.locationName ? x.locationName : this.captions.Offsite;
            return x;
          });

          this.therapistAppointments = _.sortBy(therapistAppointments, ['startTime']);
          this.subject.next(true);
        }
      } break;

      case "GetServiceTherapistsWithGroup": {
        const responseResult: any = result.result;
        if (responseResult) {
          this.therapistListWithGroup = responseResult.map(x => {
            return {
              ServiceGroupId: x.serviceGroupId,
              TherapistId: x.therapistId,
              FirstName: x.firstName,
              LastName: x.lastName
            };
          });
        }
      } break;
      case "copyTherapistSchedule": {
        this.utils.ToggleLoader(false);
        this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.captions.staffSchedule.TherapistScheduleCopiedSuccess, ButtonType.Ok, res => {
          this.GetAllScheduleDetailsGrid();
          this.resetData();
        })
      } break;
      case "GetAllServiceGrp": {
        this.TherSerGrp = <any>result.result;
      }
    }
  }

  errorCallback<T>(result: BaseResponse<T>, callDesc): void {
    switch (callDesc) {
      case "getActiveTherapist":
      case "GetAllTherapist":
      case "getAllImagesByReference":
      case "GetAppointmentConfiguration":
      case "GetAllLoc":
        {
          [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
        }
        break;
      case "GetBreakTypes":
      case "CreateTherapistBreakList":
        this.commonUtils.resetQuickIdDetails();
        break;
      case "copyTherapistSchedule": {
        let errorText = this.localization.getError(result.errorCode);
        if (result.errorCode == 100027) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Warning, errorText, ButtonType.YesNo, res => {
            if (res == "YES") {
              this.isOverWriteExitingCopied = true;
              this.utils.ToggleLoader(true);
              this.CopyTherapistSchedule();
            }
          })
        } else {
          this.utils.ShowErrorMessage(this.localization.captions.common.Warning, errorText, ButtonType.Ok)
        }
      } break;
    }
  }

  resetData() {
    this.InitializeFormData();
    this.Formgrp.controls.Actual.setValue(this.isActualFilter);
    this.Formgrp.controls.OnCall.setValue(this.isOnCallFilter);
    this.onChanges();
    this.newSchedule = false;
    this.recurring = false;
    this.editSchedule = false;
    this.oldStartTime = '';
    this.oldEndTime = '';
    this.therapistId = "";
    document.getElementsByClassName('mat-tab-header')[0].classList.remove("d-none");
    this.recurringData = {
      applyAllTime: false
    };
  }

  onChange(evnt, controlName) {
    let fromDate = this.Formgrp.controls["StartDate"].value;
    let toDate = this.Formgrp.controls["EndDate"].value;
    if (controlName == 'Actual') {
      this.isActualFilter = evnt.checked;
    } else if (controlName == 'OnCall') {
      this.isOnCallFilter = evnt.checked;
    }

    // r = this.Formgrp.controls['OnCall'].value;

    if (controlName == "Therapist") {
      this.therapistId = this.Formgrp.controls["StaffId"].value;
    } else if (controlName == "Actual" || controlName == "OnCall") {
      this.GetAllScheduleDetailsGrid();
    } else if (controlName == "FromDate") {
      fromDate = evnt.target.value;
      this.getDateWithoutTime(fromDate, toDate);
      if (fromDate && toDate) {

        const fromDay = fromDate.getDay() + 1;

        const endDay = toDate.getDay() + 1;

        const diffTime = Math.abs(fromDate - toDate);

        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays >= 6) {

          this._su.ScheduleData.forEach((x) => x.disabled = false);

        } else {

          if (fromDay === endDay) {

            this._su.ScheduleData.forEach(x => {

              x.disabled = (x.id !== fromDay);

            });

          } else if (fromDay < endDay) {

            this._su.ScheduleData.forEach((x) => {

              x.disabled = !(x.id >= fromDay && x.id <= endDay);

            });

          } else {

            this._su.ScheduleData.forEach((x) => {

              x.disabled = !(x.id >= fromDay || x.id <= endDay);

            });

          }
        }
      }
    }
    else if (controlName == "ToDate") {
      toDate = evnt.target.value;
      this.getDateWithoutTime(fromDate, toDate);
      if (fromDate && toDate) {

        const fromDay = fromDate.getDay() + 1;

        const endDay = toDate.getDay() + 1;

        const diffTime = Math.abs(fromDate - toDate);

        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays >= 6) {

          this._su.ScheduleData.forEach((x) => x.disabled = false);

        } else {

          if (fromDay === endDay) {

            this._su.ScheduleData.forEach(x => {

              x.disabled = (x.id !== fromDay);

            });

          } else if (fromDay < endDay) {

            this._su.ScheduleData.forEach((x) => {

              x.disabled = !(x.id >= fromDay && x.id <= endDay);

            });

          } else {

            this._su.ScheduleData.forEach((x) => {

              x.disabled = !(x.id >= fromDay || x.id <= endDay);

            });

          }

        }
      }
    }
    else if (controlName == "Recurring") {
      this.recurring = !this.recurring;
      if (this.recurring) {
        this.selectedOccuranceType = [];
        setTimeout(x => {
          this.InitializeRecurringForm();
          this.buildRecurringArray();
        }, 1);
        this.getDateWithoutTime(fromDate, toDate);
      } else {
        this.ApplyAllRecurringDays([false]);
      }
      setTimeout(() => {
        this.calculateWidth();
      }, 1);
    }
    if (typeof this.therapistId !== "undefined" && this.therapistId != "") {
      fromDate = this.localization.convertDateObjToAPIdate(fromDate);
      toDate = this.localization.convertDateObjToAPIdate(toDate);
      this.getTherapistSchedule(fromDate, toDate);
      this.GetTherapistAppointmentsByStaffId(fromDate, toDate, Number(this.therapistId));
    }
  }

  onRecurringChange(evt) {
    this.recurringPeriodType = evt.value;
  }

  onToDateChange(e) {
    this.toDate = e.date;
  }

  getTherapistSchedule(fromDate: string, toDate: string) {
    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "getTherapistSchedule",
      method: HttpMethod.Get,
      uriParams: {
        therapistId: this.therapistId,
        fromDate: fromDate,
        toDate: toDate
      },
      showError: true,
      extraParams: []
    });
  }

  //For Week calc Starts
  getCurrWeek = ($events, weekstart, weekend) => {
    // this.weekstart = moment(this.currentDate).startOf("week");
    // this.weekend = moment(this.currentDate).endOf("week");
    // this.aWeekArr = this._su.WeekArray(this.weekstart, this.weekend);
    this.weekstart = this.localization.getStartOfWeekTenant(this.localization.getDate(new Date()));
    this.weekend = this.localization.getLastOfWeekTenant(this.localization.getDate(new Date()));
    this.aWeekArr = this._su.WeekArray(this.weekstart, this.weekend);
    this.triggerGetTherapist = false;
    this.GetAllScheduleDetailsGrid();
  }
  getPrevWeek = (e, st, en) => {
    // this.weekstart = moment(st)
    //   .subtract(1, "weeks")
    //   .startOf("week");
    // this.weekend = moment(st)
    //   .subtract(1, "weeks")
    //   .endOf("week");
    // this.aWeekArr = this._su.WeekArray(this.weekstart, this.weekend);
    let start = this.localization.getDate(st);
    start.setDate(start.getDate() - 7);
    this.weekstart = this.localization.getStartOfWeekTenant(this.localization.getDate(start));
    this.weekend = this.localization.getLastOfWeekTenant(this.localization.getDate(start));
    this.aWeekArr = this._su.WeekArray(this.weekstart, this.weekend);
    this.triggerGetTherapist = false;
    this.GetAllScheduleDetailsGrid();
  }
  getNxtvWeek = (e, st, en) => {
    // this.weekstart = moment(st)
    //   .add(1, "weeks")
    //   .startOf("week");
    // this.weekend = moment(st)
    //   .add(1, "weeks")
    //   .endOf("week");
    // this.aWeekArr = this._su.WeekArray(this.weekstart, this.weekend);
    const start = this.localization.getDate(st);
    start.setDate(start.getDate() + 7);
    this.weekstart = this.localization.getStartOfWeekTenant(this.localization.getDate(start));
    this.weekend = this.localization.getLastOfWeekTenant(this.localization.getDate(start));
    this.aWeekArr = this._su.WeekArray(this.weekstart, this.weekend);
    this.triggerGetTherapist = false;

    this.GetAllScheduleDetailsGrid();
  }
  switchtoWeek = e => {
    const seldt = e.value;
    // this.weekstart = moment(seldt).startOf("week");
    // this.weekend = moment(seldt).endOf("week");
    // this.aWeekArr = this._su.WeekArray(this.weekstart, this.weekend);
    this.weekstart = this.localization.getStartOfWeekTenant(this.localization.getDate(seldt));
    this.weekend = this.localization.getLastOfWeekTenant(this.localization.getDate(seldt));
    this.aWeekArr = this._su.WeekArray(this.weekstart, this.weekend);
    this.triggerGetTherapist = false;
    this.GetAllScheduleDetailsGrid();
  }

  ButtonSelect(Arr, value) {
    Arr.splice(0, 1);
    Arr.push(value);
  }

  /**
   * array push
   * @param ga
   * @param gv
   */
  PushPopData(ga, gv) {
    if (ga.indexOf(gv) == -1) {
      ga.push(gv);
    } else {
      ga.splice(ga.indexOf(gv), 1);
    }
  }

  toggleonCallAll(res) {
    this.OCIcon = res;
    for (let i = 0; i < this.ScheduleData.length; i++) {
      this.ScheduleData[i].onCall = res;

      if (this.ScheduleData[i].onCall) {
        this.OffIcon = false;
        this.ScheduleData[i].Off = this.OffIcon;
      }
    }

    if (this.editSchedule) {
      this.Formgrp.markAsDirty();
      this.SetFormValidity();
    }
  }

  toggleoffAll(res) {
    this.validOnDirty = true;
    this.OffIcon = res;
    const start: any = this.Formgrp.controls["WorkStartTime"];
    const end: any = this.Formgrp.controls["WorkEndTime"];

    if (res) {
      this.startTimeTempHolder = start.value;
      this.endTimeTempHolder = end.value;
    }
    for (let i = 0; i < this.ScheduleData.length; i++) {
      this.ScheduleData[i].Off = res;

      if (this.ScheduleData[i].Off) {
        this.OCIcon = false; // Set other toggle as False by default
        this.ScheduleData[i].onCall = this.OCIcon;

        start.controls[i].controls["StartTime"].setValue("");
        end.controls[i].controls["EndTime"].setValue("");
      }
      else {
        start.controls[i].controls["StartTime"].setValue(this.startTimeTempHolder[i].StartTime);
        end.controls[i].controls["EndTime"].setValue(this.endTimeTempHolder[i].EndTime);
      }
    }

    if (this.editSchedule) {
      this.Formgrp.controls["OverwriteExisting"].setValue(true);
      this.Formgrp.markAsDirty();
      this.SetFormValidity();
    }
  }

  SetLocation(k) {
    this.ScheduleData[k].locationId = this.TherapistLocation.controls[k].controls["location"].value;

  }

  toggleschedule(k, frm) {

    this.validOnDirty = this.validOnDirty ? this.validOnDirty : frm == "Off";

    const toggleName: string = frm == "onCall" ? "Off" : "onCall"; // Used for automatic deselect of other toggle.
    if (this.ScheduleData[k][frm]) {
      this.ScheduleData[k][toggleName] = false;
      if (toggleName == "onCall") {
        this.OCIcon = false;
      } else {
        this.OffIcon = false;
      }
    } else {
      if (frm == "onCall") {
        this.OCIcon = false;
      } else {
        this.OffIcon = false;
      }
    }

    this.OCIcon = this.ScheduleData.every((x) => x.onCall);
    this.OffIcon = this.ScheduleData.every((x) => x.Off);

    const isValid = this.ScheduleData.filter(s => {
      return s.Off;
    })[0];

    const start: any = this.Formgrp.controls["WorkStartTime"];
    const end: any = this.Formgrp.controls["WorkEndTime"];

    // Clear start /End Time when Off toggle is selected
    if (frm == "Off") {
      this.Formgrp.controls["OverwriteExisting"].setValue(true);
      if (this.ScheduleData[k][frm]) {
        this.startTimeTempHolder = start.value;
        this.endTimeTempHolder = end.value;
        start.controls[k].controls["StartTime"].setValue("");
        end.controls[k].controls["EndTime"].setValue("");
      }
      else {
        start.controls[k].controls["StartTime"].setValue(this.startTimeTempHolder[k].StartTime);
        end.controls[k].controls["EndTime"].setValue(this.endTimeTempHolder[k].EndTime);
      }
    }

    this.validOnDirty = this.validOnDirty ? this.validOnDirty : isValid != null;

    if (this.editSchedule) {
      if (frm === "onCall") {
        this.validOnDirty = (start.value[0].StartTime && start.value[0].EndTime) ? true : false;
      }
      this.Formgrp.markAsDirty();
      this.SetFormValidity();
    }
  }
  addTherapistLocation(arr: number, _location?: any): UntypedFormGroup {
    return this.Form.group({
      location: _location
    });
  }
  addWorkStartTime(arr: number, _StartTime?: any): UntypedFormGroup {
    return this.Form.group({
      StartTime: _StartTime
    });
  }
  addWorkEndTime(arr: number, _EndTime?: any): UntypedFormGroup {
    return this.Form.group({
      EndTime: _EndTime
    });
  }
  save() {
    this.onSaved = true;
    this.utils.ToggleLoader(true);
    this.CreateTherapistSchedule();
    this.endTimeArray = this.startTimeArray = [];
    this.Formgrp.controls.Actual.setValue(this.isActualFilter);
    this.Formgrp.controls.OnCall.setValue(this.isOnCallFilter);
    this.Formgrp.updateValueAndValidity();
  }
  promptUserForUnsavedChangesCallback(resultFromPromptPopup: string) {
    if (resultFromPromptPopup.toLocaleLowerCase() == ButtonOptions.No) { //user wants to stay in the page
      return;
    } else {
      this.cancel();
    }
  }
  promptUserForUnsavedChanges() {
    const checkForFormDirty = this.Formgrp.dirty;
    if (checkForFormDirty) {
      this.userAlerts.showPrompt(PromptType.UnsavedChanges, this.promptUserForUnsavedChangesCallback.bind(this))
    } else {
      this.cancel();
    }
  }
  cancel() {
    this.selectedOccuranceType = [this.occurenceArray[0]];
    this.GetAllScheduleDetailsGrid();
    document.getElementsByClassName('mat-tab-header')[0].classList.remove("d-none");
    this.endTimeArray = this.startTimeArray = [];
    this.newSchedule = false;
    this.editSchedule = false;
    this.oldStartTime = '';
    this.oldEndTime = '';
    this.recurring = false;
    this.therapistSchedule = [];
    this.therapistAppointments = [];
    this.therapistId = "";
    this.InitializeFormData();
    this.recurringData = {
      applyAllTime: false
    };
    this.Formgrp.controls.Actual.setValue(this.isActualFilter);
    this.Formgrp.controls.OnCall.setValue(this.isOnCallFilter);
    this.RemoveBreakTimeForChange = false;
  }

  NewSchedule(from?, therapistId?, off?) {
    const breakpointNumber = [SPAManagementBreakPoint.StaffSchedule];
    const isUserAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
    this.fromGrid = false;
    this.fromGridTherapistId = null;
    if (!isUserAuthorized) {
      return;
    }
    if (from == 'fromGrid') {
      this.fromGrid = true;
      this.fromGridTherapistId = therapistId;
    }
    this.therapistListGetCall("getActiveTherapist");
    this.InitializeFormGroupData();
    if (!this.breakPoint.CheckForAtleastOneAccess([SPAManagementBreakPoint.AddTherapistBreak]).hasAccess) {
      this.Formgrp.controls['RemoveBreakTime'].setValue(true);
      this.Formgrp.controls['BreakStartTime'].setValue('');
      this.Formgrp.controls['BreakEndTime'].setValue('');
      this.Formgrp.controls['R_BreakType'].setValue('');
      this.RemoveBreakTimeForChange = true;
      this.ScheduleData = this._su.formScheduleData();
    }

    this.onChanges();
    document.getElementsByClassName('mat-tab-header')[0].classList.add("d-none");
    this.newSchedule = true;
    this.overAllEndTime = this.overAllStartTime = this.applyAllDays = this.validOnDirty = false;
    this.minToDate = this.Formgrp.controls["StartDate"].value;

    setTimeout(() => {
      this.calculateWidth();
    }, 1);
    if (off) {
      let fromDate = this.localization.convertDateObjToAPIdate(this.Formgrp.controls["StartDate"].value);
      let toDate = this.localization.convertDateObjToAPIdate(this.Formgrp.controls["EndDate"].value);
      this.getTherapistSchedule(fromDate, toDate);
      this.Formgrp.controls["OverwriteExisting"].setValue(true);
    }

  }
  SetStartTime(index) {
    const start: any = this.Formgrp.controls["WorkStartTime"];
    const dd = this.startTimeArray.filter(s => {
      return s == index;
    });
    if (
      dd == null ||
      dd.length == 0 ||
      this.Formgrp.controls["WorkStartTime"].value[index].StartTime == ""
    ) {
      start.controls[index].controls["StartTime"].setValue(
        this.defaultStartTime
      );
    }
    this.startTimeArray.push(index);
    start.controls[index].controls["StartTime"].setErrors(null);
    this.validOnDirty = true;
  }

  SetEndTime(index) {
    const end: any = this.Formgrp.controls["WorkEndTime"];
    const dd = this.endTimeArray.filter(s => {
      return s == index;
    });
    if (
      dd == null ||
      dd.length == 0 ||
      this.Formgrp.controls["WorkEndTime"].value[index].EndTime == ""
    ) {
      end.controls[index].controls["EndTime"].setValue(this.defaultEndtime);
    }
    this.endTimeArray.push(index);
    const start: any = this.Formgrp.controls["WorkStartTime"];
    start.controls[index].controls["StartTime"].setErrors(null);
    this.validOnDirty = true;
  }

  SetOverAllStartTime() {
    if (!this.overAllStartTime) {
      this.overAllStartTime = true;
      this.Formgrp.controls["OverallStartTime"].setValue(this.defaultStartTime);
    }
  }

  SetOverAllEndTime() {
    if (!this.overAllEndTime) {
      this.overAllEndTime = true;
      this.Formgrp.controls["OverallEndTime"].setValue(this.defaultEndtime);
    }
  }

  ApplyAllDays(event) {
    this.applyAllDays = !this.applyAllDays;
    if (this.applyAllDays) {
      const start: any = this.Formgrp.controls["WorkStartTime"];
      const end: any = this.Formgrp.controls["WorkEndTime"];
      for (let i = 0; i < this.ScheduleData.length; i++) {
        if (!this.ScheduleData[i].Off) {
          start.controls[i].controls["StartTime"].setValue(
            this.Formgrp.controls["OverallStartTime"].value
          );
          end.controls[i].controls["EndTime"].setValue(
            this.Formgrp.controls["OverallEndTime"].value
          );
        }
      }
      if (
        this.Formgrp.controls["OverallStartTime"].value != "" ||
        this.Formgrp.controls["OverallEndTime"].value != ""
      ) {
        this.validOnDirty = true;
      }
    }
    else {
      this.Formgrp.controls["WorkStartTime"].reset();
      this.Formgrp.controls["WorkEndTime"].reset();
    }
  }

  ClearTimeOnDelete(keyEvent, index, controlName) {
    if (keyEvent.keyCode == 8 || keyEvent.keyCode == 46) {
      if (controlName == "StartTime") {
        const start: any = this.Formgrp.controls["WorkStartTime"];
        start.controls[index].controls["StartTime"].setValue("");
      } else if (controlName == "EndTime") {
        const end: any = this.Formgrp.controls["WorkEndTime"];
        end.controls[index].controls["EndTime"].setValue("");
      }
    }
  }
  applyall(startTime, End) {
    const r: any = this.Formgrp.controls["WorkStartTime"];
    r.controls[0].controls["StartTime"].setValue("05:00");
  }

  monthlyCalendarChange($event: any) {
    this.monthSelectedDate = $event;
    this.Formgrp.controls.StartDate.setValue(this.monthSelectedDate);
    this.getDateWithoutTime(this.monthSelectedDate, this.monthDateMax)
  }

  InitializeRecurringForm() {

    this.recurringWeek = this.fb.group({
      everyValue: [''],
      availableDays: ['']
    });
    this.RegisterOnChange(this.recurringWeek, this.SetFormValidity.bind(this));

    this.recurringDay = this.fb.group({
      everyValue: ['']
    });
    this.RegisterOnChange(this.recurringDay, this.SetFormValidity.bind(this));

    this.recurringMonth = this.fb.group({
      everyValue: [''],
      dayOrDate: [''],
      day: this.fb.group({
        repeatedWeek: [''],
        repeatedDays: ['']
      }),
      date: this.propertyInfo.CurrentDate
    });
    this.RegisterOnChange(this.recurringMonth, this.SetFormValidity.bind(this));

    this.recurringYear = this.fb.group({
      everyValue: [''],
      repeatedWeek: [''],
      repeatedDays: ['']
    });
    this.RegisterOnChange(this.recurringYear, this.SetFormValidity.bind(this));

    this.recurringDetails = this.fb.group({
      location: [''],
      repeatTime: [''],
      startDate: this.propertyInfo.CurrentDate,
      startTime: Number[''],
      appointmentsNumber: [''],
      clientData: ['']
    });

    this.RegisterOnChange(this.recurringDetails, this.SetFormValidity.bind(this));

    this.recurringForm = this.fb.group({
      recurringDay: this.recurringDay,
      recurringWeek: this.recurringWeek,
      recurringMonth: this.recurringMonth,
      recurringYear: this.recurringYear,
      detail: this.recurringDetails
    });

    this.RegisterOnChange(this.recurringForm, this.SetFormValidity.bind(this));

    this.selectedOccuranceType.push(this.occurenceArray[0]);

    this.selectedOccurType = this.BookAptCaptions.Days;
    this.monthly = false;
    this.weekly = false;
    this.yearly = false;
    this.daily = true;
    this.recurringMonth.controls.dayOrDate.setValue('date');
    this.monthlyDay = false;
    this.monthlyDate = true;
  }

  async CreateTherapistBreak(scheduleDetails, tid) {
    let therapistBreak: boolean = false;
    if (scheduleDetails && scheduleDetails.length > 0 && this.Formgrp.controls.BreakTimeDuration.value && this.Formgrp.controls.BreakTime.value) {
      const breakArr = [];
      const recurringDateArray = this.createDateArray.filter(res => scheduleDetails.some(x => res.row1 === moment(x.scheduleDate).format("YYYY-MM-DD")));
      scheduleDetails.forEach((element, index) => {
        const scheduleDay = recurringDateArray[index].row2;
        const scheduleData = this.GetScheduleData(scheduleDay);
        const scheduleDatas = this.GetScheduleDataWithoutRecurring(scheduleDay);
        let currentBreakControls;
        let currentBreakEndControls;
        if (this.editSchedule) {
          currentBreakControls = (this.UpdatedRecurringData || this.Formgrp.controls.RecurringData)['controls'].WorkBreakTime.controls[0].controls;
          currentBreakEndControls = (this.UpdatedRecurringData || this.Formgrp.controls.RecurringData)['controls'].WorkBreakEndTime.controls[0].controls;
        }
        else {
          currentBreakControls = (this.UpdatedRecurringData || this.Formgrp.controls.RecurringData)['controls'].WorkBreakTime.controls[scheduleData.id - 1].controls;
          currentBreakEndControls = (this.UpdatedRecurringData || this.Formgrp.controls.RecurringData)['controls'].WorkBreakEndTime.controls[scheduleData.id - 1].controls;
        }
        if (Array.isArray(currentBreakControls) && currentBreakControls.length) {
          for (let i = 0; i < currentBreakControls.length; i++) {
            if (currentBreakControls[i].controls.BreakStartTime.value && currentBreakEndControls[i].controls.BreakEndTime.value) {
              const dtString = `${element.scheduleDate}T${this.localization.DeLocalizeTime(currentBreakControls[i].controls.BreakStartTime.value)}`;
              const dt = this.utils.getDate(dtString);
              const fromTime = this.utils.convertDateFormat(dt);
              const enddtString = `${element.scheduleDate}T${this.localization.DeLocalizeTime(currentBreakEndControls[i].controls.BreakEndTime.value)}`;
              const enddt = this.utils.getDate(enddtString);
              let toTime = this.utils.convertDateFormat(enddt);
              let id = currentBreakControls[i].controls.Id.value;
              const bodyObj: any = {
                id: id,
                therapistScheduleId: element.id,
                breakType: scheduleDatas.breakTypeId == '' ? 0 : scheduleDatas.breakTypeId,
                comments: this.Formgrp.controls.Comments.value,
                fromTime: fromTime,
                toTime: toTime,
                paid: scheduleDatas.paid,
                unPaid: scheduleDatas.unPaid
              };
              breakArr.push(bodyObj);
            }
          }
        }
      });

      if (breakArr.length > 0) {
        therapistBreak = true;
        const isOverwrite = this.Formgrp.controls["OverwriteExisting"].value;
        const quickIdConfig = this._retailPropertyInfo.getQuickIdConfig;
        let hasQuickDeleteAccess = false;
        if (isOverwrite && quickIdConfig && quickIdConfig.QUICKID_THERAPISTBREAKDELETE) {
          hasQuickDeleteAccess = quickIdConfig.QUICKID_THERAPISTBREAKDELETE;
        }
        if (quickIdConfig && (quickIdConfig.QUICKID_THERAPISTBREAKCREATE || (isOverwrite && hasQuickDeleteAccess))) {
          const quickLoginDialogRef = this.commonUtils.QuickLogin();
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn) {
              if (this.editSchedule)
                await this.UpdateBreakTime(breakArr, tid, isOverwrite, scheduleDetails[0].scheduleDate);
              else
                await this.modifyBreakList(breakArr, tid, isOverwrite);
            }
          });
        } else {
          if (this.editSchedule)
            await this.UpdateBreakTime(breakArr, tid, isOverwrite, scheduleDetails[0].scheduleDate);
          else
            await this.modifyBreakList(breakArr, tid, isOverwrite);
        }
      }

    }
    if (!therapistBreak) {
      this.GetAllScheduleDetailsGrid();
    }

  }

  async modifyBreakList(breakArr, tid, isOverwrite) {
    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "CreateTherapistBreakList",
      method: HttpMethod.Post,
      body: breakArr,
      uriParams: {
        therapistId: tid, isOverWrite: isOverwrite
      },
      showError: true,
      extraParams: []
    });
  }

  async UpdateBreakTime(breakArr, tid, isOverwrite, scheduleDate) {
    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "UpdateTherapistBreak",
      method: HttpMethod.Put,
      body: breakArr,
      uriParams: {
        therapistId: tid, overwrite: isOverwrite, scheduleDate: scheduleDate
      },
      showError: true,
      extraParams: []
    });
  }

  LoadSystemConfigurations() {
    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetAppointmentConfiguration",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }
  validateSave(): boolean {
    this.therapistId = this.Formgrp.controls["StaffId"].value;
    // const isValid = this.ValidateStartAndEndTime();
    // Start and end date validation
    if (this.showCopySection) {
      return this.therapistId && this.Formgrp.controls["noOfWeeks"].value && this.Formgrp.valid;
    }
    if (this.utils.GetDateWithoutTime(this.localization.getDate(this.Formgrp.controls.StartDate.value)).getTime() > 
        this.utils.GetDateWithoutTime(this.localization.getDate(this.Formgrp.controls.EndDate.value)).getTime()) {
      return false;
    }
    else if (this.recurring) {
      try {
        let res = this.buildRecurringSchedule();
        res = res ? res : [];
        if (res.length == 0) return false;
      } catch (e) { }
      const IsValidTime = this._su.IsValidTime(this.Formgrp.controls.R_StartTime.value, this.Formgrp.controls.R_EndTime.value);
      const dateValidity = (this.Formgrp.controls.R_StartTime.value && this.Formgrp.controls.R_EndTime.value) && IsValidTime;

      if (!IsValidTime) {
        this.Formgrp.controls.R_StartTime.setErrors({ incorrect: true });
        this.Formgrp.controls.R_EndTime.setErrors({ incorrect: true });
        return false;
      } else {
        this.Formgrp.controls.R_StartTime.setErrors(null);
        this.Formgrp.controls.R_EndTime.setErrors(null);
      }

      const breakStartTime = this.Formgrp.controls["BreakTime"];
      const breakTimeDuration = this.Formgrp.controls["BreakTimeDuration"];
      if (breakTimeDuration.value > 0) {
        breakStartTime.markAsDirty();
      }

      if (breakStartTime.dirty && breakStartTime.value) {
        const CurrentDate = this.utils.formatDate(this.propertyInfo.CurrentDate);
        const breakStartDt = this.utils.getDate(`${CurrentDate} ${breakStartTime.value}`)
        const endBreakTime = this.utils.getTime(this.localization.dateAdd.AddMins(breakStartDt, breakTimeDuration.value), 24);
        this.utils.getDate(`${CurrentDate} ${this.Formgrp.controls.R_EndTime.value}`);
        this.utils.getDate(`${CurrentDate} ${this.Formgrp.controls.R_StartTime.value}`);
        this.utils.getDate(`${CurrentDate} ${endBreakTime}`);
      }
      if (this.daily) {
        return this.therapistId && this.recurringDay.controls.everyValue.value && dateValidity
      } else if (this.weekly) {
        return this.therapistId && this.recurringWeek.controls.everyValue.value && dateValidity
      } else if (this.monthly) {
        if (this.recurringMonth.controls.dayOrDate.value == "date") {
          return this.therapistId && this.recurringMonth.controls.everyValue.value && dateValidity
            && this.monthSelectedDate != undefined && this.monthSelectedDate != null
            && (this.Formgrp.controls.R_StartTime.value && this.Formgrp.controls.R_EndTime.value) && this._su.IsValidTime(this.Formgrp.controls.R_StartTime.value, this.Formgrp.controls.R_EndTime.value)
        } else {
          return this.therapistId && this.recurringMonth.controls.everyValue.value
            && this.recurringMonth.controls.day.get('repeatedWeek').value !== "" && (this.recurringMonth.controls.day.get('repeatedWeek').value || this.recurringMonth.controls.day.get('repeatedWeek').value == 0)
            && (this.Formgrp.controls.R_StartTime.value && this.Formgrp.controls.R_EndTime.value) && this._su.IsValidTime(this.Formgrp.controls.R_StartTime.value, this.Formgrp.controls.R_EndTime.value)
        }
      } else if (this.yearly) {
        return this.therapistId && this.recurringYear.controls.everyValue.value !== "" && (this.recurringYear.controls.everyValue.value || this.recurringYear.controls.everyValue.value == 0)
          && this.recurringYear.controls.repeatedWeek.value !== "" && (this.recurringYear.controls.repeatedWeek.value || this.recurringYear.controls.repeatedWeek.value == 0)
          && dateValidity
      }
    } else {
      try {
        let res = this.buildSchedule();
        res = res ? res : [];
        if (res.length == 0) return false;
      } catch (e) { }
      const IsValidTime = this._su.IsValidTime(this.Formgrp.controls.R_StartTime.value, this.Formgrp.controls.R_EndTime.value);
      const dateValidity = (this.Formgrp.controls.R_StartTime.value && this.Formgrp.controls.R_EndTime.value) && IsValidTime;

      if (!IsValidTime) {
        this.Formgrp.controls.R_StartTime.setErrors({ incorrect: true });
        this.Formgrp.controls.R_EndTime.setErrors({ incorrect: true });
        return false;
      } else {
        this.Formgrp.controls.R_StartTime.setErrors(null);
        this.Formgrp.controls.R_EndTime.setErrors(null);
      }

      const breakStartTime = this.Formgrp.controls["BreakTime"];
      const breakTimeDuration = this.Formgrp.controls["BreakTimeDuration"];
      if (breakTimeDuration.value > 0) {
        breakStartTime.markAsDirty();
      }

      if (breakStartTime.dirty && breakStartTime.value) {
        const CurrentDate = this.utils.formatDate(this.propertyInfo.CurrentDate);
        const breakStartDt = this.utils.getDate(`${CurrentDate} ${breakStartTime.value}`)
        const endBreakTime = this.utils.getTime(this.localization.dateAdd.AddMins(breakStartDt, breakTimeDuration.value), 24);
        this.utils.getDate(`${CurrentDate} ${this.Formgrp.controls.R_EndTime.value}`);
        this.utils.getDate(`${CurrentDate} ${this.Formgrp.controls.R_StartTime.value}`);
        this.utils.getDate(`${CurrentDate} ${endBreakTime}`);
      }
      if (this.daily) {
        return this.therapistId && dateValidity
      }
    }
  }

  ValidateStartAndEndTime(): boolean {
    this.createDateArray = this._su.CreateDateArray(
      _.cloneDeep(this.Formgrp.controls["StartDate"].value),
      _.cloneDeep(this.Formgrp.controls["EndDate"].value)
    );
    let _startTimeControl: UntypedFormArray;
    let _entTimeControl: UntypedFormArray;
    if (!this.recurring) {
      _startTimeControl = this.Formgrp.get('WorkStartTime') as UntypedFormArray;
      _entTimeControl = this.Formgrp.get('WorkEndTime') as UntypedFormArray;
    } else if (this.recurring) {
      _startTimeControl = this.Formgrp.get('R_StartTime') as UntypedFormArray;
      _entTimeControl = this.Formgrp.get('R_EndTime') as UntypedFormArray;
    }
    if (!_startTimeControl || !_entTimeControl) {
      return false;
    }
    // Get days which has valid time
    let DayWithValidTime: any = [];

    DayWithValidTime = this.createDateArray.slice(0, 7).map((x, idx) => {
      let data = this.GetScheduleData(x.row2);
      let fromTime
      let toTime
      if (this.newSchedule) {
        fromTime = this.Formgrp.controls["WorkStartTime"].value[data.id - 1].StartTime;
        toTime = this.Formgrp.controls["WorkEndTime"].value[data.id - 1].EndTime;
      }
      else if (this.editSchedule) {
        fromTime = this.Formgrp.controls["WorkStartTime"].value[0].StartTime;
        toTime = this.Formgrp.controls["WorkEndTime"].value[0].EndTime;
      }
      return (fromTime && toTime && fromTime !== "" && toTime !== "") || this.ScheduleData[idx].Off;
    })
    // return if it does not have valid time
    if (DayWithValidTime && !DayWithValidTime.some(x => x)) {
      return false;
    }
    for (let i = 0; i < this.ScheduleData.length; i++) {
      if (!this._su.IsValidTime(_startTimeControl.at(i).value["StartTime"], _entTimeControl.at(i).value["EndTime"]) && !this.ScheduleData[i].Off) {
        return false;
      }
      if ((!_startTimeControl.at(i).touched && !_entTimeControl.at(i).touched) ||
        (_startTimeControl.at(i).value["StartTime"] != _entTimeControl.at(i).value["EndTime"] &&
          _startTimeControl.at(i).value["StartTime"] != "" && _entTimeControl.at(i).value["EndTime"] != "") ||
        this.ScheduleData[i].Off) {
        continue;
      }
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    this.newSchedule = false;
    this.editSchedule = false;
    this.oldStartTime = '';
    this.oldEndTime = '';
    if (this.startDateSubscription) {
      this.startDateSubscription.unsubscribe();
    }
    if (this.endDateSubscription) {
      this.endDateSubscription.unsubscribe();
    }
    if (this.StaffScheduleSubscriptions) {
      this.StaffScheduleSubscriptions.unsubscribe();
    }
    if (this.recurringSubscription) {
      this.recurringSubscription.unsubscribe();
    }
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
    this.UpdatedRecurringData = '';
    this.promptUserForUnsavedChanges();
  }
  selectedCollection(event, type, orgcollection) {
    const serviceVaraiable = [];
    const selectedArray = event[0];
    orgcollection.forEach(x => {
      if (selectedArray.indexOf(x.id) != -1) {
        serviceVaraiable.push(x.name);
        x.setSelected = true;
      } else {
        x.setSelected = false;
      }
    });
    if (type == 'type1') {
      this.type1Array = serviceVaraiable;
    }
    if (type == 'type2') {
      this.type2Array = serviceVaraiable;
    }
    this.getDateWithoutTime(this.monthSelectedDate, this.monthDateMax);
    this.isFormValid = this.validateSave();
  }

  displayFrontEndCellData() {
    let schedules: any = null;
    let resultData: any = {};
    const contentArr = this.allSchedules;
    this.StaffScheduleArr = this.therapistlist.map(TL => {

      const weeklist = this.aWeekArr.map(WA => {
        const time = WA;
        if (this.isActualFilter == this.isOnCallFilter) {
          resultData = contentArr.find(result => {
            return TL.Id == result.therapistId && this.utils.getDate(time.row1).toString() == this.utils.getDate(result.scheduleDate).toString();
          });
        } else if (this.isActualFilter) {
          resultData = contentArr.find(result => {
            return TL.Id == result.therapistId && !result.availableTime.some(x => x.availableOnCall) && this.utils.getDate(time.row1).toString() == this.utils.getDate(result.scheduleDate).toString();
          });
        } else if (this.isOnCallFilter) {
          resultData = contentArr.find(result => {
            return TL.Id == result.therapistId && result.availableTime.some(x => x.availableOnCall) && this.utils.getDate(time.row1).toString() == this.utils.getDate(result.scheduleDate).toString();
          });
        }
        schedules = null;
        if (resultData) {
          let availableTimeString, breakTimeString, fromTime, toTime;

          // Available Time Calculations
          availableTimeString = resultData.availableTime.map(x => {
            fromTime = this.utils.getDate(x.fromTime);
            toTime = this.utils.getDate(x.toTime);
            return `${this.localization.LocalizeTime(fromTime)}-${this.localization.LocalizeTime(toTime)}`;
          }).join('|');

          let scheduleTime = resultData.availableTime.map(x => {
            return {
              availableOnCall: x.availableOnCall,
              createdAt: x.createdAt,
              id: resultData.id,
              locationId: x.locationId,
              lunchId: x.lunchId,
              therapistScheduleId: x.therapistScheduleId,
              fromTime: x.fromTime,
              toTime: x.toTime
            }
          });
          // Break Time calculation
          breakTimeString = resultData.breakTime.map(x => {
            fromTime = this.utils.getDate(x.fromTime);
            toTime = this.utils.getDate(x.toTime);
            return `${this.localization.LocalizeTime(fromTime)}-${this.localization.LocalizeTime(toTime)}`;
          }).join('|');

          schedules = {
            'availableTimeString': availableTimeString,
            'breakTimeString': breakTimeString,
            'onCall': resultData.availableTime.some(x => x.availableOnCall),
            'off': resultData.isOff,
            'scheduleTime': scheduleTime,
            'breakTime': resultData.breakTime,
            'modifyAvailable': this.localization.getDate(this.localization.getDate(fromTime).setHours(0, 0, 0, 0)) >= this.propertyInfo.CurrentDate,
            'comments': resultData.comments
          };

        }
        return schedules;
      });
      if ((this.isActualFilter && !this.isOnCallFilter) || (!this.isActualFilter && this.isOnCallFilter) || (this.isActualFilter && this.isOnCallFilter)) {
        for (let i = 0; i <= weeklist.length; i++) {
          if (weeklist[i] != null) {
            return {
              'therapistDetail': TL,
              'RowData': weeklist
            };
          }
        }
        return {
          'therapistDetail': [],
          'RowData': []
        };

      }
      else {
        return {
          'therapistDetail': TL,
          'RowData': weeklist
        };
      }
    });
    let staffs = this.StaffScheduleArr.map(s => s.therapistDetail);
    let therapists = [];
    staffs.forEach(s => {
      if (s.Id) {
        therapists.push(s);
      }
    });

    this.therapistlist = therapists;
    if (this.therapistlist.length == 0) {
      this.StaffScheduleArr = [];
    }
  }

  calculateWidth() {
    Array.from(document.querySelectorAll('#tableWrapper>ng-scrollbar>.ng-scrollbar-container>.ng-scrollbar-view>table')).forEach((table, index) => {
      if (table) {
        let tableHeight = table['offsetHeight'];
        table.closest("#time-overlay")['offsetHeight'];
        table.closest("#tableWrapper")['style']['height'] = tableHeight + 2 + 'px';
      }
    });
  }

  ngAfterViewChecked() {
    if (!this.viewCheckedFlag) {
      this.viewCheckedFlag = true;
      setTimeout(() => {
        this.calculateWidth();
      }, 1);

    }
  }

  OpenEditStaffScheduleDialog(data, therapistId: number) {
    const breakpointNumber = [SPAManagementBreakPoint.EditStaffSchedule];
    const isUserAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
    if (!isUserAuthorized) {
      return;
    }

    if (!data || !data.scheduleTime || data.scheduleTime.length === 0) { // return when data is empty
      return;
    }

    const fromTime = this.localization.getDate(data.scheduleTime[0].fromTime);
    if (this.localization.getDate(fromTime.setHours(0, 0, 0, 0)) < this.propertyInfo.CurrentDate) { // return when staff schedule date is before property date
      return;
    }

    if (data.scheduleTime.length > 1) {
      let inputData = [];
      for (var i = 0; i < data.scheduleTime.length; i++) {
        inputData.push({
          availableOnCall: data.scheduleTime[i].availableOnCall,
          createdAt: data.scheduleTime[i].createdAt,
          id: data.scheduleTime[i].id,
          locationId: data.scheduleTime[i].locationId,
          lunchId: data.scheduleTime[i].lunchId,
          therapistScheduleId: data.scheduleTime[i].therapistScheduleId,
          fromTime: data.scheduleTime[i].fromTime,
          toTime: data.scheduleTime[i].toTime,
          time: `${this.localization.LocalizeTime(this.utils.getDate(data.scheduleTime[i].fromTime))}-${this.localization.LocalizeTime(this.utils.getDate(data.scheduleTime[i].toTime))}`,
          checked: i === 0 ? true : false,
          scheduleTime: [data.scheduleTime[i]],
          breakTime: data.breakTime
        });
      }

      let dialogRef = this.dialog.open(SettingDialogPopupComponent, {
        height: '35%',
        width: '450px',
        data: { headername: this.captions.EditSchedule, closebool: true, templatename: 'SSE', datarecord: { data: inputData } },
        panelClass: 'small-popup',
        disableClose: true,
        hasBackdrop: true
      });

      this.dialogSubscription = dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.EditStaffSchedule(res, therapistId, data.off);
        }
      });

    } else {
      this.EditStaffSchedule(data, therapistId, data.off);
    }

  }

  addWorkBreakTime(arr: number, _BreakTime?: any): UntypedFormArray {
    return this.fb.array([
      this.fb.group({
        BreakStartTime: _BreakTime
      })
    ]);
  }

  addWorkEndBreakTime(arr: number, _BreakTime?: any): UntypedFormArray {
    return this.fb.array([
      this.fb.group({
        BreakEndTime: _BreakTime
      })
    ]);
  }

  async EditStaffSchedule(therapistScheduleBreaks: any, therapistId: number, isOff: boolean) {

    this.therapistId = therapistId;
    this.therapistScheduleId = therapistScheduleBreaks.scheduleTime[0].id;
    this.scheduleId = therapistScheduleBreaks.scheduleTime[0].therapistScheduleId;
    let scheduleTime = therapistScheduleBreaks.scheduleTime[0];

    const fromDate = this.localization.getDate(scheduleTime.fromTime);
    this.oldStartTime = this.localization.ConvertDateToISODateTime(fromDate);
    const toDate = this.localization.getDate(scheduleTime.toTime);
    this.oldEndTime = this.localization.ConvertDateToISODateTime(toDate);

    this.Formgrp = this.Form.group({
      Actual: "",
      OnCall: "",
      StaffId: [therapistId, Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      RecurringSchedule: [false, Validators.required],
      AllDays: false,
      OverwriteExisting: false,
      OverallStartTime: this.defaultStartTime,
      OverallEndTime: this.defaultEndtime,
      TherapistLocation: this.Form.array([this.addTherapistLocation(0, scheduleTime.locationId)]),
      WorkStartTime: this.Form.array([this.addWorkStartTime(0, moment(fromDate).format('HH:mm'))]),
      WorkEndTime: this.Form.array([this.addWorkEndTime(0, moment(toDate).format('HH:mm'))]),
      StartTimelabel: "",
      EndTimelabel: "",
      BreakTime: this.breakStartTime,
      Comments: "",
      R_Location: "",
      R_BreakType: "",
      R_StartTime: this.defaultStartTime,
      R_EndTime: this.defaultEndtime,
      RecurringWeeksNo: 0,
      BreakTimeDuration: this.defaultBreakDuration,
      RecurringPeriodTypeSelect: "weekly"
    });
    this.onChanges();
    this.editSchedule = true;
    this.ScheduleData = this.GetScheduleDataByCollection(moment(fromDate).format("dddd"));
    this.ScheduleData[0].onCall = scheduleTime.availableOnCall;
    this.ScheduleData[0].Off = isOff;
    this.ScheduleData[0].locationId = scheduleTime.locationId;
    this.OCIcon = scheduleTime.availableOnCall ? true : false;
    this.OffIcon = isOff ? true : false;
    this.TherapistLocation = this.Formgrp.get("TherapistLocation") as UntypedFormArray;
    this.WorkStartTime = this.Formgrp.get("WorkStartTime") as UntypedFormArray;
    this.WorkEndTime = this.Formgrp.get("WorkEndTime") as UntypedFormArray;
    this.overAllEndTime = this.overAllStartTime = this.applyAllDays = this.validOnDirty = false;

    document.getElementsByClassName('mat-tab-header')[0].classList.add("d-none");
    this.Formgrp.controls["StartDate"].setValue(fromDate);
    this.Formgrp.controls["EndDate"].setValue(toDate);
    this.minToDate = this.Formgrp.controls["StartDate"].value;

    this.getTherapistSchedule(this.oldStartTime, this.oldEndTime);

    this.GetTherapistAppointmentsByStaffId(fromDate, toDate, therapistId);

    let breakStartTime = [];
    let breakEndTime = [];

    for(let i=0; i < therapistScheduleBreaks.breakTime.length; i++) {
      let breakFromDate:any = this.localization.getDate(therapistScheduleBreaks.breakTime[i].fromTime);
      let breakToTime:any =  this.localization.getDate(therapistScheduleBreaks.breakTime[i].toTime);
      breakFromDate = this.localization.LocalizeISOTime(this.utils.getTime(breakFromDate, 24));
      breakToTime = this.localization.LocalizeISOTime(this.utils.getTime(breakToTime, 24));
      let breakStartTimeId = {id: therapistScheduleBreaks.breakTime[i].id, breakStartTime: breakFromDate.replace('am', 'AM').replace('pm', 'PM')};
      let breakEndTimeId = {id: therapistScheduleBreaks.breakTime[i].id, breakEndTime: breakToTime.replace('am', 'AM').replace('pm', 'PM')};
      breakStartTime.push(breakStartTimeId);
      breakEndTime.push(breakEndTimeId);
      //breakStartTime.push(breakFromDate.replace('am', 'AM').replace('pm', 'PM'));
      //breakEndTime.push(breakToTime.replace('am', 'AM').replace('pm', 'PM'));
    }

    this.recurringData = {
      isEdit: true,
      applyAllTime: true,
      breakEndTime: breakEndTime,//"12:30 PM",//["12:30 PM", "08:30 AM"],
      breakTime:breakStartTime,//"12:00 PM",//["12:00 PM", "08:00 AM"],
      breakType: therapistScheduleBreaks.breakTime[0]?.breakType,
      endTime: moment(toDate).format('HH:mm'),//"08:59 pm",
      location:scheduleTime.locationId,
      startTime: moment(fromDate).format('HH:mm'),//"08:00 am"
      onCall: therapistScheduleBreaks.scheduleTime[0].availableOnCall,
      offDuty: therapistScheduleBreaks.off,
      paid: therapistScheduleBreaks.breakTime[0]?.paid,
      unPaid: therapistScheduleBreaks.breakTime[0]?.unPaid
    }
    setTimeout(() => {
      this.calculateWidth();
    }, 1);
  }

  async DeleteStaffSchedule(data, therapistId: number) {
    this._su.DeleteStaffSchedule(data, therapistId);
  }

  async DeleteBulkSchedule(){
    this._su.DeleteBulkSchedule();
  }

  private async editTherapistSchedule(body: TherapistScheduleEditModel, extraParamasTherapistSchedule) {
    if(body.isOff || this.Formgrp.controls["OverwriteExisting"].value==true)
      {
        body.scheduleId = 0;
      }
    let apiResponse = await this.ss.InvokeServiceCallAsync<number>('EditTherapistSchedule', Host.schedule, HttpMethod.Put, { isOverwiteExisting: body.isOff ? true : this.Formgrp.controls["OverwriteExisting"].value }, body);
    if (apiResponse > 0) {
      let errorText = this.localization.getError(apiResponse);
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorText);
    } else {
      await this.CreateTherapistBreak(extraParamasTherapistSchedule, this.therapistId);
      //this.GetAllScheduleDetailsGrid();
      this.InitializeFormData();
      this.onChanges();
      this.newSchedule = false;
      this.recurring = false;
      this.editSchedule = false;
      this.oldStartTime = '';
      this.oldEndTime = '';
      this.therapistId = "";
      document.getElementsByClassName('mat-tab-header')[0].classList.remove("d-none");
      this.recurringData = {
        applyAllTime: false
      };
    }
  }

  ApplyAllRecurringDays(event) {
    //this.utils.ToggleLoader(true);
    if (!event[0]) {
      this.Formgrp.controls.AllRecurringDays.setValue(false);
      this.Formgrp.controls.RecurringData.reset();
      this.Formgrp.controls.BreakStartTime.enable();
      this.Formgrp.controls.BreakEndTime.enable();
    }
    this.validateSave();
    let breakStartTime={id: 0, breakStartTime: this.Formgrp.value.BreakStartTime.replace('am','AM').replace('pm', 'PM')};
    let breakEndTime  = {id: 0, breakEndTime: this.Formgrp.value.BreakEndTime.replace('am','AM').replace('pm','PM')};
    this.recurringData = {
      applyAllTime: event[0],
      location: this.Formgrp.value.R_Location,
      breakType: this.Formgrp.value.R_BreakType,
      startTime: this.Formgrp.value.R_StartTime,
      endTime: this.Formgrp.value.R_EndTime,
      breakTime: [breakStartTime],
      breakEndTime: [breakEndTime]
    };
    //this.utils.ToggleLoader(false);
  }

  WeeklyEmittedValue(event) {
    this.Formgrp.removeControl('RecurringData');
    this.Formgrp.addControl('RecurringData', event);
    this.Formgrp.get('WorkStartTime').setValue(event.get('WorkStartTime').value);
    this.Formgrp.get('WorkEndTime').setValue(event.get('WorkEndTime').value);
  }

  isWeeklyFormValid(event) {
    this.isFormValid = event;
  }

  protected async GetTherapistAppointmentsByStaffId(fromDate: Date, ToDate: Date, staffId: number) {
    let params = {
      FromDate: this.localization.convertDateObjToAPIdate(fromDate),
      ToDate: this.localization.convertDateObjToAPIdate(ToDate),
      therapistId: staffId
    };
    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetAppointmentByTherapistId",
      method: HttpMethod.Get,
      uriParams : params,
      showError: true,
      extraParams: [false]
    });
  }

  private MapToApiModel(scheduleInfo: TherapistSchedule, oldStartTime: string, oldEndTime: string): TherapistScheduleEditModel {
    return {
      therapistId: scheduleInfo.therapistId,
      scheduleDate: scheduleInfo.scheduleDate,
      isOff: scheduleInfo.isOff,
      scheduleId: scheduleInfo.id,
      oldTime: { FromTime: oldStartTime, ToTime: oldEndTime },
      availableTime: scheduleInfo.availableTime,
      comments: scheduleInfo.comments
    } as TherapistScheduleEditModel;
  }

  addBreakForSelectedDay(event) {
    this.selectedRecurringData = null;
    this.selectedRecurringData = {
      recurringData: event[0],
      selectedDay: event[1],
      selectedIndex: event[2]
    };
    this._su.showBreakTime = true;
  }

  breakTimeEmit(event) {
    this.UpdatedRecurringData = event;
  }

  buttonSelectionChange(buttonData: any) {
    if (buttonData && buttonData.value) {
      const data = this.TherSerGrp.filter(r => !buttonData.value.map(res => res.id).includes(r.id));
      this.TherSerGrp = _.union(buttonData.value, data);
    }
    this.selectedSerGrp = buttonData && buttonData.value && buttonData.value.length ? buttonData.value.map(res => res.id) : this.TherSerGrp.map(res => res.id);
    this.selectedServiceGroup = buttonData;
    if (buttonData.dirty) { // do not filter therapists on multi-select-spa-button's ngoninit
      //this.filterTherapists([]);
      this.getTherapistScheduleDuplicate();
    }
  }

  getMinTime(i) {
    this.minDate = this.Formgrp.controls.WorkStartTime['controls'][i].controls['StartTime'].value;
  }

  RemoveBreakTimeEvent(event) {
    if (event && event[0]) {
      this.Formgrp.controls['BreakStartTime'].setValue('');
      this.Formgrp.controls['BreakEndTime'].setValue('');
      this.Formgrp.controls['R_BreakType'].setValue('');
      this.RemoveBreakTimeForChange = true;
    } else if (event && !event[0]) {
      if (!this.breakPoint.CheckForAtleastOneAccess([SPAManagementBreakPoint.AddTherapistBreak]).hasAccess) {
        let breakpointText = this.localization.captions.breakpoint[SPAManagementBreakPoint.AddTherapistBreak];
        let message = `${this.localization.captions.common.BreakPointAccessDeniedMsg}
        <br><br>${this.localization.captions.common.Breakpoint}: ${breakpointText}`;
        this.utils.ShowErrorMessage(this.localization.captions.alertPopup.accessDenined, message, ButtonType.Ok, () => {
          this.Formgrp.controls['RemoveBreakTime'].setValue(true);
          this.Formgrp.controls['BreakStartTime'].setValue('');
          this.Formgrp.controls['BreakEndTime'].setValue('');
          this.Formgrp.controls['R_BreakType'].setValue('');
          this.RemoveBreakTimeForChange = true;
        });
        return;
      }
      this.Formgrp.controls['BreakStartTime'].setValue(this.breakStartTime);
      this.Formgrp.controls['BreakEndTime'].setValue(this.breakEndTime);
      if (this.Formgrp.controls['AllRecurringDays'].value) {
        this.Formgrp.controls.BreakStartTime.disable();
        this.Formgrp.controls.BreakEndTime.disable();
      }
      this.RemoveBreakTimeForChange = false;
    }
  }
  CopyTherapistEvent(event) {
    if (event[0]) {
      this.showCopySection = true;
      this.Formgrp.controls.EndDate.disable();
      this.Formgrp.controls.RecurringSchedule.disable();
      this.Formgrp.controls.OverwriteExisting.disable();
      this.Formgrp.controls.RemoveBreakTime.disable();
      this.recurringWeek.controls.everyValue.disable();
      this.Formgrp.controls.R_Location.disable();
      this.Formgrp.controls.R_StartTime.disable();
      this.Formgrp.controls.R_EndTime.disable();
      this.Formgrp.controls.BreakStartTime.disable();
      this.Formgrp.controls.BreakEndTime.disable();
      this.Formgrp.controls.R_BreakType.disable();
      this.Formgrp.controls.AllRecurringDays.disable();
      this.Formgrp.controls["noOfInstance"].setValue('1');

      // this.Formgrp.controls.TherapistLocation.disable();
      // this.Formgrp.controls.WorkStartTime.disable();
      // this.Formgrp.controls.WorkEndTime.disable();
    } else {
      this.showCopySection = false;
      this.Formgrp.controls.EndDate.enable();
      this.Formgrp.controls.RecurringSchedule.enable();
      this.Formgrp.controls.OverwriteExisting.enable();
      this.Formgrp.controls.RemoveBreakTime.enable();
      this.recurringWeek.controls.everyValue.enable();
      this.Formgrp.controls.R_Location.enable();
      this.Formgrp.controls.R_StartTime.enable();
      this.Formgrp.controls.R_EndTime.enable();
      this.Formgrp.controls.BreakStartTime.enable();
      this.Formgrp.controls.BreakEndTime.enable();
      this.Formgrp.controls.R_BreakType.enable();
      this.Formgrp.controls.AllRecurringDays.enable();
      this.Formgrp.controls.Comments.enable();
      this.Formgrp.controls["noOfWeeks"].setValue('');
      this.Formgrp.controls["noOfInstance"].setValue('');
    }
  }
  onHover(idx) {
      this.isToday = false;
      let date = this.aWeekArr[idx];
      this.selectedDate=date;
      this.isToday = this.localization.getDate(this.localization.getDate(date.row1).setHours(0, 0, 0, 0)) >= this.propertyInfo.CurrentDate;
  }
}

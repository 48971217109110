import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as myGlobals from "../../shared/globalsContant"; //CONSTANT FILE ADD ANY CONSTANT VALUE
import * as _ from "lodash";
import { Observable, ReplaySubject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { RetailPropertyInformation } from "../../common/services/retail-property-information.service";
import { RetailPopupComponent } from "../../retail-popup/retail-popup.component";
import { RetailSetupService } from "../../retail-setup/retail-setup.service";
import {
  GeneralLedgerLinking,
  RevenuePostingPrimaryType,
  RevenuePostTypes,
  SubPropertyModel,
  MappingScreen,
} from "../../retail.modals";
import { RetailFunctionalityBusiness } from "../../shared/business/retail-functionality.business";
import {
  ButtonType,
  FeatureValue,
  GridType,
} from "../../shared/globalsContant";
import { AlertAction, AlertType } from "../../shared/shared.modal";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { GeneralLedgerMappingBusiness } from "./general-ledger-mapping.bussiness";
import { PropertyInfo } from "../../shared/business/shared.modals";
import { PropertyFeaturesConfigurationService } from "../../sytem-config/payment-features-config/property-feature-config.service";

@Component({
  selector: "app-general-ledger-mapping",
  templateUrl: "./general-ledger-mapping.component.html",
  styleUrls: ["./general-ledger-mapping.component.scss"],
  providers: [GeneralLedgerMappingBusiness],
})
export class GeneralLedgerMappingComponent implements OnInit, OnDestroy {
  useRetailInterface: boolean;
  captions: any;
  IsViewOnly = false;
  tableoptions: any[];
  dataSource = [];
  isValid = false;
  isDisabled = true;
  postTypeValues = [];
  selectedPostTypeValue = RevenuePostTypes.Discount; // Default selected value on load as per alphabet order
  searchText: string;
  searchPlaceholderValue: any;
  maxInputLength = 100;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  tableHeader: {
    title: any;
    jsonkey: string;
    sortable: boolean;
    sortcolumn?: string;
    sortcolumndatatype?: string;
    highlightbold?: boolean;
    type?: string;
    alignType?: string;
  }[];
  commonCaptions: any;
  outlets: SubPropertyModel[];
  category: RevenuePostingPrimaryType[];
  generalLedgerMappingData: GeneralLedgerLinking[] = [];
  previousSelectedData: any;
  updatedSelectedData: any;
  selectedColumnName: any;
  OriginalgeneralLedgerMappingData: GeneralLedgerLinking[];
  functionalities: { [key: string]: boolean } = {};
  defaultOutlet: number;
  floatLabel: string;
  floatLabelNever: string;
  pmsPropCode: string;
  propertyList: PropertyInfo[] = [];
  propertyDropDownList: PropertyInfo[] = [];
  selectedProperty: PropertyInfo = { PropertyName: "", PropCode: "" };
  currentSelectedPostType;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private localization: RetailLocalization,
    private GeneralLedgerMappingBusiness: GeneralLedgerMappingBusiness,
    private _rs: RetailSetupService,
    private utilities: RetailUtilities,
    private func: RetailFunctionalityBusiness,
    public propertyInfo: RetailPropertyInformation,
    private propertyFeatureservice: PropertyFeaturesConfigurationService
  ) {
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }
  ngOnInit() {
    this.InitializeComponent();
  }

  async InitializeComponent() {
    this.captions = this.localization.captions.retailsetup;
    this.commonCaptions = this.localization.captions.common;
    this.postTypeValues = [
      { id: 2, description: this.captions.Discount },
      { id: 6, description: this.captions.Gratuity },
      { id: 3, description: this.captions.PaymentMethod },
      { id: 5, description: this.captions.ServiceCharge },
      { id: 1, description: this.captions.Settlement },
      { id: 4, description: this.captions.taxes },
      { id: 8, description: this.captions.Surcharges },
    ];
    this.searchPlaceholderValue = this.GetSearchPlaceHolderByPostType(
      RevenuePostTypes.Discount
    );
    this.defaultOutlet = this.propertyInfo.GetDefaultOutlet();
    this.func.getRetailFunctionality().then((res) => {
      this.functionalities = res;
      this.getData();
    });
    this.IsViewOnly = this._rs.retailSetupBreakPoints.find(
      (rb) =>
        rb.breakPointNumber == myGlobals.RetailBreakPoint.GeneralLedgerMapping
    ).view;
    //this.IsViewOnly = this._rs.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.PostTypeMapping).view;
  }

  async getData() {
    let [outlets, category, generalLedgerMappings] = await Promise.all([
      this.GeneralLedgerMappingBusiness.GetActiveOutlets(),
      this.GeneralLedgerMappingBusiness.GetPrimaryTypeMapping(
        RevenuePostTypes.Discount,
        MappingScreen.GeneralLedgerMapping
      ),
      this.GeneralLedgerMappingBusiness.GetGeneralLedgerMapping(),
    ]);
    this.generalLedgerMappingData = generalLedgerMappings;

    this.outlets = outlets;
    this.category = category;
    this.OriginalgeneralLedgerMappingData = this.generalLedgerMappingData;
    this.generalLedgerMappingData = this.formTableDataTemp(
      generalLedgerMappings
    );
    this.BindToGrid();
  }

  formTableDataTemp(generalLedgerMappingData: GeneralLedgerLinking[] = []) {
    const OriginalTableData: GeneralLedgerLinking[] = _.cloneDeep(
      generalLedgerMappingData
    );
    this.category.forEach((cat) => {
      this.outlets.forEach((out) => {
        if (Array.isArray(generalLedgerMappingData)) {
          let isPostTypeAvailable;
          if (this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod) {
            isPostTypeAvailable = generalLedgerMappingData.find(
              (o) =>
                o.outletId === out.subPropertyID &&
                o.linkId === cat.id &&
                o.linkType == cat.linkType
            );
          } else {
            isPostTypeAvailable = generalLedgerMappingData.find(
              (o) =>
                o.outletId === out.subPropertyID &&
                o.linkId === cat.id &&
                o.linkType === this.selectedPostTypeValue
            );
          }

          if (!isPostTypeAvailable) {
            OriginalTableData.push({
              id: 0,
              outletId: out.subPropertyID,
              linkId: cat.id,
              linkType: cat.linkType,
              glAccountId: "—",
              departmentId: "",
              businessUnit: "",
              operatingUnit: "",
              isActive: true,
              isUpdated: false,
            });
          }
        }
      });
    });
    return OriginalTableData;
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  BindToGrid() {
    const data = this.formTableData();
    this.tableoptions = [
      {
        TableHdrData: data[0],
        TablebodyData: data[1],
        pagination: false,
        sortable: true,
        CustomColumn: false,
        PlaceHoldertext: this.captions.Search,
        EnableActions: false,
        SelectRows: false,
        IsCommission: false,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: GridType.generalLedgerMapping,
        Sortable: "typecategory",
        TableId: GridType.generalLedgerMapping,
        disableDelete: false,
        customHeader: false,
        pageTitle: "generalLedgerMapping",
        ServiceId: "generalLedgerMapping",
        IsViewOnly: this.IsViewOnly,
        IsReadOnly: this.IsViewOnly,
        TableDraggable: false,
        bufferCount: 20,
      },
    ];
  }

  formTableData() {
    this.tableHeader = [
      {
        title: this.GetTextByPostType(),
        jsonkey: "typecategory",
        sortable: true,
        highlightbold: true,
      },
    ];
    this.dataSource = [];
    this.outlets.forEach((res) => {
      this.tableHeader.push({
        title: res.subPropertyName,
        jsonkey: res.subPropertyName,
        sortcolumn: res.subPropertyName + "number",
        sortcolumndatatype: "number",
        type: "showOnHover",
        sortable: true,
        alignType: "right",
      });
      return res;
    });

    this.category.forEach((res) => {
      let resultData: any = {};
      if (Array.isArray(this.generalLedgerMappingData)) {
        this.generalLedgerMappingData.forEach((data) => {
          if (res.id === data.linkId && res.linkType == data.linkType) {
            this.outlets.forEach((o) => {
              if (
                this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod &&
                o.subPropertyID === data.outletId &&
                (data.linkType === this.selectedPostTypeValue ||
                  data.linkType === RevenuePostTypes.Card)
              ) {
                resultData.typecategory = res.name;
                resultData[o.subPropertyName] = data.glAccountId;
                resultData[o.subPropertyName + " GlAccountId"] =
                  data.glAccountId ? data.glAccountId : "—";
                resultData[o.subPropertyName + " DepartmentId"] =
                  data.departmentId ? data.departmentId : "";
                resultData[o.subPropertyName + " BusinessUnit"] =
                  data.businessUnit ? data.businessUnit : "";
                  resultData[o.subPropertyName + " OperatingUnit"] =
                  data.operatingUnit ? data.operatingUnit : "";
                resultData[o.subPropertyName + " IsActive"] = data.isActive
                  ? data.isActive
                  : false;
                resultData.id = data.id ? data.id : 0;
                resultData.category = res.name ? res.name : "";
                resultData.categoryId = data.linkId ? data.linkId : 0;
                resultData.outletId = data.outletId ? data.outletId : 0;
              } else if (
                o.subPropertyID === data.outletId &&
                data.linkType === this.selectedPostTypeValue
              ) {
                resultData.typecategory = res.name;
                if (!this.propertyInfo.IsVATEnabled) {
                  resultData[o.subPropertyName] =
                    this.selectedPostTypeValue === RevenuePostTypes.Tax &&
                    res.outletId != o.subPropertyID
                      ? ""
                      : data.glAccountId;
                } else {
                  resultData[o.subPropertyName] = data.glAccountId;
                }
                resultData[o.subPropertyName + " GlAccountId"] = data.glAccountId ? data.glAccountId : "—";
                resultData[o.subPropertyName + " DepartmentId"] = data.departmentId ? data.departmentId : "";
                resultData[o.subPropertyName + " BusinessUnit"] = data.businessUnit ? data.businessUnit : "";
                resultData[o.subPropertyName + " OperatingUnit"] = data.operatingUnit ? data.operatingUnit : "";
                resultData[o.subPropertyName + " IsActive"] = data.isActive ? data.isActive : false;
                resultData.id = data.id ? data.id : 0;
                resultData.category = res.name ? res.name : "";
                resultData.categoryId = data.linkId ? data.linkId : 0;
                resultData.outletId = data.outletId ? data.outletId : 0;
              }
            });
          }
        });
      }
      if (Object.keys(resultData).length) this.dataSource.push(resultData);
      resultData = {};
    });
    return [this.tableHeader, this.dataSource];
  }

  rowDataClicked(event) {
    this.openDialog(this.captions.SelectGeneralLedgerDetails, "GLM", event);
    const outlet = this.outlets.filter((o) => o.subPropertyName === event[1]);
    const oldData = this.generalLedgerMappingData.filter(
      (res) =>
        res.glAccountId === event[0][event[1]] &&
        res.linkId === event[0].categoryId &&
        res.outletId === outlet[0].subPropertyID
    );
    this.previousSelectedData =
      oldData && oldData.length ? oldData : [event[0]];
  }

  resetValue() {
    this.searchText = "";
  }

  searchValue(event) {
    this.searchText = event.target.value;
  }

  openDialog(title, templateName, data) {
    const dialogRef = this.dialog.open(RetailPopupComponent, {
      width: "45%",
      height: "85%",
      disableClose: true,
      data: {
        headername: title,
        closebool: true,
        templatename: templateName,
        datarecord: data,
        LinkType: this.selectedPostTypeValue,
        pmsPropCode: this.pmsPropCode,
      },
      hasBackdrop: true,
      panelClass: "",
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (result) {
          this.updatedSelectedData = result;
          const filteredData = [];
          let postTypeIndex = null;
          if (!result.isAllCategory) {
            if (this.selectedPostTypeValue === RevenuePostTypes.PaymentMethod) {
              postTypeIndex = this.generalLedgerMappingData.findIndex(
                (m) =>
                  (m.linkType == RevenuePostTypes.PaymentMethod ||
                    m.linkType == RevenuePostTypes.Card) &&
                  m.linkId === this.previousSelectedData[0].linkId &&
                  m.outletId === this.previousSelectedData[0].outletId
              );
            } else {
              postTypeIndex = this.generalLedgerMappingData.findIndex(
                (m) =>
                  m.linkType === this.selectedPostTypeValue &&
                  m.linkId === this.previousSelectedData[0].linkId &&
                  m.outletId === this.previousSelectedData[0].outletId
              );
            }
            if (postTypeIndex !== -1) {
              this.generalLedgerMappingData[postTypeIndex].glAccountId = result.glAccountId ? result.glAccountId : "—";
              this.generalLedgerMappingData[postTypeIndex].departmentId = result.departmentId ? result.departmentId : "";
              this.generalLedgerMappingData[postTypeIndex].businessUnit = result.businessUnit ? result.businessUnit : "";
              this.generalLedgerMappingData[postTypeIndex].operatingUnit = result.operatingUnit ? result.operatingUnit : "";
              this.generalLedgerMappingData[postTypeIndex].isActive = result.isActive ? result.isActive : false;
              this.generalLedgerMappingData[postTypeIndex].isUpdated = true;
              this.isDisabled = false;
              this.isValid = true;
            }
          } else {
            if (this.selectedPostTypeValue == RevenuePostTypes.PaymentMethod) {
              for (let i = 0; i < this.generalLedgerMappingData.length; i++) {
                if (
                  (this.generalLedgerMappingData[i].linkType ==
                    RevenuePostTypes.PaymentMethod ||
                    this.generalLedgerMappingData[i].linkType ==
                      RevenuePostTypes.Card) &&
                  this.generalLedgerMappingData[i].outletId ==
                    this.previousSelectedData[0].outletId
                ) {
                  this.generalLedgerMappingData[i].glAccountId = result.glAccountId ? result.glAccountId : "—";
                  this.generalLedgerMappingData[i].departmentId = result.departmentId ? result.departmentId : "";
                  this.generalLedgerMappingData[i].businessUnit = result.businessUnit ? result.businessUnit : "";
                  this.generalLedgerMappingData[i].operatingUnit = result.operatingUnit ? result.operatingUnit : "";
                  this.generalLedgerMappingData[i].isActive = result.isActive ? result.isActive : false;
                  this.generalLedgerMappingData[i].isUpdated = true;
                }
              }
            } else {
              for (let i = 0; i < this.generalLedgerMappingData.length; i++) {
                if (
                  this.generalLedgerMappingData[i].linkType ==
                    this.selectedPostTypeValue &&
                  this.generalLedgerMappingData[i].outletId ==
                    this.previousSelectedData[0].outletId
                ) {
                  this.generalLedgerMappingData[i].glAccountId = result.glAccountId ? result.glAccountId : "—";
                  this.generalLedgerMappingData[i].departmentId = result.departmentId ? result.departmentId : "";
                  this.generalLedgerMappingData[i].businessUnit = result.businessUnit ? result.businessUnit : "";
                  this.generalLedgerMappingData[i].operatingUnit = result.operatingUnit ? result.operatingUnit : "";
                  this.generalLedgerMappingData[i].isActive = result.isActive ? result.isActive : false;
                  this.generalLedgerMappingData[i].isUpdated = true;
                }
              }
            }
            this.isDisabled = false;
            this.isValid = true;
          }
          this.BindToGrid();
        }
      });
  }

  postTypeChange(event) {
    this.utilities.ToggleLoader(true);
    this.searchPlaceholderValue = this.GetSearchPlaceHolderByPostType(
      event.value
    );
    this.GeneralLedgerMappingBusiness.GetPrimaryTypeMapping(
      event.value,
      MappingScreen.GeneralLedgerMapping
    ).then((res) => {
      this.category = res;
      this.generalLedgerMappingData = this.formTableDataTemp(
        this.generalLedgerMappingData
      );
      this.BindToGrid();
      this.currentSelectedPostType = event.value;
      this.utilities.ToggleLoader(false);
    });
  }

  GetTextByPostType(value: number = this.selectedPostTypeValue) {
    switch (value) {
      case RevenuePostTypes.Settlement:
      case RevenuePostTypes.Discount:
      case RevenuePostTypes.ServiceCharge:
      case RevenuePostTypes.Gratuity:
        return this.captions.Category;
      case RevenuePostTypes.PaymentMethod:
        return this.captions.PaymentMethod;
      case RevenuePostTypes.Tax:
        return this.captions.Tax;
      case RevenuePostTypes.Surcharge:
        return this.captions.CardTypes;
    }
  }

  GetSearchPlaceHolderByPostType(value: number = this.selectedPostTypeValue) {
    switch (value) {
      case RevenuePostTypes.Settlement:
      case RevenuePostTypes.Discount:
      case RevenuePostTypes.ServiceCharge:
      case RevenuePostTypes.Gratuity:
        return this.captions.SearchByCategories;
      case RevenuePostTypes.PaymentMethod:
        return this.captions.SearchByPaymentMethod;
      case RevenuePostTypes.Tax:
        return this.captions.SearchByTax;
      case RevenuePostTypes.Surcharge:
        return this.captions.CardTypes;
    }
  }

  async saveGeneralLedgerMappings(event) {
    // Filter updated and delete post types
    let updatedPostTypes = this.generalLedgerMappingData.filter(
      (s) => s.isUpdated && s.glAccountId !== "—"
    );
    
    let deletePostTypes = this.generalLedgerMappingData
      .filter((s) => s.isUpdated && s.id && s.glAccountId === "—")
      .map((t) => t.id);
  
    // If there are posts to delete, handle them
    if (deletePostTypes.length > 0) {
      // Create a map of the generalLedgerMappingData for fast lookup
      const mappingDataMap = new Map();
      this.generalLedgerMappingData.forEach((post) => {
        const key = `${post.outletId}-${post.linkId}-${post.linkType}`;
        mappingDataMap.set(key, post);
      });
  
      let deletedPostTypes = [];
      
      // Find posts for deletion and update the values
      deletePostTypes.forEach((del) => {
        const postToDelete = this.generalLedgerMappingData.find((post) => post.id === del);
        if (postToDelete) {
          deletedPostTypes.push(postToDelete);
        }
      });
  
      // Update the relevant generalLedgerMappingData entries based on the deleted posts
      deletedPostTypes.forEach((dPost) => {
        this.outlets.forEach((x) => {
          const key = `${x.subPropertyID}-${dPost.linkId}-${dPost.linkType}`;
          const post = mappingDataMap.get(key);
          if (post) {
            post.isUpdated = dPost.isUpdated;
            post.glAccountId = dPost.glAccountId;
            post.departmentId = dPost.departmentId;
            post.businessUnit = dPost.businessUnit;
            post.operatingUnit = dPost.operatingUnit;
            post.isActive = dPost.isActive;
          }
        });
      });
  
      // Deletion call
      if(deletePostTypes && deletePostTypes.length){
        await this.GeneralLedgerMappingBusiness.DeleteGeneralLedgerMapping(deletePostTypes);
      }
    }
  
    // If there are updated post types, create/update them
    if (updatedPostTypes.length) {
      await this.GeneralLedgerMappingBusiness.CreateUpdateGeneralLedgerMapping(updatedPostTypes);
    }
  
    // Fetch updated generalLedgerMappings and bind them to the grid
    let generalLedgerMappings = await this.GeneralLedgerMappingBusiness.GetGeneralLedgerMapping();
    this.generalLedgerMappingData = generalLedgerMappings;
    this.OriginalgeneralLedgerMappingData = this.generalLedgerMappingData;
    this.generalLedgerMappingData = this.formTableDataTemp(generalLedgerMappings);
    
    // Bind data to the grid
    this.BindToGrid();
    this.isValid = false;
  }


  cancelGeneralLedgerMappings() {
    if (this.isValid) {
      this.utilities.showAlert(
        this.commonCaptions.saveChangesMessage,
        AlertType.Warning,
        ButtonType.YesNo,
        (res) => {
          if (res === AlertAction.YES) {
            this.generalLedgerMappingData = this.formTableDataTemp(
              this.OriginalgeneralLedgerMappingData
            );
            this.BindToGrid();
            this.isValid = false;
          }
        }
      );
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.isValid) {
      return this.confirm();
    }
    return true;
  }

  confirm(): Observable<boolean> {
    const confirmation = this.utilities.showAlert(
      this.commonCaptions.saveChangesMessage,
      AlertType.Warning,
      ButtonType.YesNo
    );
    return confirmation.afterClosed().pipe(
      map((a) => {
        if (a === AlertAction.YES) {
          return true;
        } else {
          this.isValid = true;
          return false;
        }
      })
    );
  }
}

<div class="ag-custom-header" style="width:100%;height: 100%;">
    <div class="grid-table-header" (click)="sortOrder()"
         [ngClass]="{'align-right' : params.column.colDef.type == 'numericColumn', 'flex-dir-column': userDefCols.headerWithCustomCheckbox }">
        <mat-checkbox *ngIf="userDefCols.headerWithCustomCheckbox" [checked]="params.column.colDef.headerChecked" (change)="headerCheckBoxChange($event)"></mat-checkbox>
        <span class="ag-header-cell-text" *ngIf="params.displayName" title="{{params.displayName}}"
            role="columnheader">{{params.displayName}}</span>
        <span *ngIf="userDefCols.customSort && showSort && userDefCols.sortDirection == 'asc' && params.displayName"
            class="ag-header-icon icon-up"></span>
        <span *ngIf="userDefCols.customSort && showSort && userDefCols.sortDirection == 'desc' && params.displayName"
            class="ag-header-icon icon-down"></span>
    </div>
</div>
import { GridHeaderComponent } from './grid-header/grid-header.component';
import { GridTableComponent } from './grid-table/grid-table.component';
import { ReorderPanelComponent } from './reorder-panel/reorder-panel.component';
import { NoItemsFoundComponent } from './no-items-found/no-items-found.component';
import { GridFilterComponent } from './grid-filter/grid-filter.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GridActionsComponent } from './grid-actions/grid-actions.component';
import { RowActionsComponent } from './row-actions/row-actions.component';
import { GridHeaderService } from './grid-header/grid-header.service';
import { HEADER_COLUMN_DATA } from './grid-header/header-token';
import { ResizeRowsComponent } from './resize-rows/resize-rows.component';
import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { MaterialModule } from './material-module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconRendererComponent } from './icon-renderer/icon-renderer.component';
import { DropdownEditorComponent } from './cell-editors/select-editor/select-editor.component';
import { TextEditorComponent } from './cell-editors/text-editor/text-editor.component';
import { NumberEditorComponent } from './cell-editors/number-editor/number-editor.component';
import { NumberCalcRendererComponent } from './cell-renderer/number-calc-renderer/number-calc-renderer.component';
import { NumberCellEditorComponent } from './cell-editors/number-cell-editor/number-cell-editor.component';
import { NameWithIconRendererComponent } from './cell-renderer/name-with-icon-renderer/name-with-icon-renderer.component';
import { TextboxWithIconComponent } from './cell-editors/textbox-with-icon/textbox-with-icon.component';
import { CurrencyDropdownComponent } from './cell-editors/currency-dropdown/currency-dropdown.component';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { DatePickerComponent } from './cell-editors/date-picker/date-picker.component';
import { GridDropDownEditorComponent } from './cell-editors/dropdown/dropdown.component';
import { TemperatureRendererComponent } from './cell-renderer/temperature-renderer/temperature-renderer.component';
import { UnitsDropdownComponent } from './cell-editors/units-dropdown/units-dropdown.component';
import { MultiSelectAutoCompleteComponent } from './cell-editors/multi-select-auto-complete/multi-select-auto-complete.component';
import { DynamicDropDownComponent } from './cell-editors/dynamic-dropdown/dynamic-dropdown';
import { ApprovalActionsComponent } from './cell-renderer/approval-actions/approval-actions.component';
import { TransactionTypeRendererComponent } from './cell-renderer/transaction-type-renderer/transaction-type-renderer.component';
import { LinkActionsComponent } from './cell-renderer/link-actions/link-actions.component';
import { DropdownSelectorComponent } from './cell-editors/dropdown-selector/dropdown-selector.component';

@NgModule({
  declarations: [
    GridHeaderComponent,
    GridTableComponent,
    ResizeRowsComponent,
    GridFilterComponent,
    ReorderPanelComponent,
    NoItemsFoundComponent,
    GridActionsComponent,
    RowActionsComponent,
    IconRendererComponent,
    DropdownEditorComponent,
    TextEditorComponent,
    NumberEditorComponent,
    NumberCalcRendererComponent,
    NumberCellEditorComponent,
    NameWithIconRendererComponent,
    TextboxWithIconComponent,
    CurrencyDropdownComponent,
    AutoCompleteComponent,
    DatePickerComponent,
    GridDropDownEditorComponent,
    TemperatureRendererComponent,
    UnitsDropdownComponent,
    MultiSelectAutoCompleteComponent,
    DynamicDropDownComponent,
    ApprovalActionsComponent,
    TransactionTypeRendererComponent,
    LinkActionsComponent,
    DropdownSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DragDropModule,
    AgGridModule.withComponents([
      GridHeaderComponent,
      ReorderPanelComponent,
      ResizeRowsComponent,
      NoItemsFoundComponent,
      IconRendererComponent,
      DropdownEditorComponent,
      TextEditorComponent,
      TextboxWithIconComponent,
      NumberCalcRendererComponent,
      NameWithIconRendererComponent,
      CurrencyDropdownComponent,
      AutoCompleteComponent,
      DatePickerComponent,
      GridDropDownEditorComponent,
      TemperatureRendererComponent,
      UnitsDropdownComponent,
      MultiSelectAutoCompleteComponent,
      DynamicDropDownComponent,
      ApprovalActionsComponent,
      TransactionTypeRendererComponent,
      LinkActionsComponent,
      DropdownSelectorComponent
    ]),
    OverlayModule
  ],
  exports: [
    GridTableComponent,
    DragDropModule,
    OverlayModule,
    DropdownEditorComponent,
    TextEditorComponent,
    TextboxWithIconComponent,
    CurrencyDropdownComponent,
    AutoCompleteComponent,
    DatePickerComponent,
    GridDropDownEditorComponent,
    UnitsDropdownComponent,
    MultiSelectAutoCompleteComponent,
    DynamicDropDownComponent,
    DropdownSelectorComponent
  ],
  providers: [
    {
      provide: HEADER_COLUMN_DATA,  // That's the token we defined previously
      useClass: GridHeaderService,  // That's the actual service itself
    },
    {provide: OverlayContainer, useClass: FullscreenOverlayContainer}
  ]
})
export class AgilysysDynamicGridModule { }

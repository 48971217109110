<section class="approval-actions-section" (click)="$event.stopPropagation()">
    <div *ngFor="let icons of iconLists;" (click)="approvalActionClick(icons, $event)">
        <span class="icon-class {{icons.IconClass}}" title="{{icons.TooltipValue}}" *ngIf="icons.IconName === 'remarks'"  #clickRemarkMenuTrigger="matMenuTrigger" 
        [matMenuTriggerFor]="clickRemarkMenu"></span>
        <span class="icon-class {{icons.IconClass}}" title="{{icons.TooltipValue}}" *ngIf="icons.IconName !== 'remarks'"></span>
        <span *ngIf="icons.HighlightClass" class="{{icons.HighlightClass}}"></span>
    </div>
    <!-- (closed)="menuCloses($event)" -->
    <mat-menu (click)="$event.stopPropagation()" class="approval-remarks" #clickRemarkMenu="matMenu" xPosition="before" yPosition="below" >
        <div style="width: 100%;" (click)="$event.stopPropagation()">
            <div class="remarks-header" (click)="$event.stopPropagation()">
                <label class="subheading-medium">Add Remarks</label>
                <span class="cancel-icon icon-requisition-denied" (click)="closeMenu()"></span>
            </div>
            <div class="remarks-content" (click)="$event.stopPropagation()">
                <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
                    <mat-label>Remarks</mat-label>
                    <input matInput placeholder="Remarks" [(ngModel)]="remarkValue">
                </mat-form-field>
            </div>
            <div class="remarks-footer" (click)="$event.stopPropagation()">
                <label *ngIf="remarkValue" class="clearall-label" (click)="clearAllRemarks()">Clear All</label>
                <label class="post-label" (click)="postRemarks()">Post</label>
            </div>
        </div>
    </mat-menu>
</section>

import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FormIconsComponent } from './actions/form-icons/form-icons.component';
import { AgilysysDynamicformComponent } from './agilysys-dynamicform.component';
import { CheckBoxSingleComponent } from './atoms/check-box-single/check-box-single.component';
import { CheckBoxComponent } from './atoms/checkbox/check-box.component';
import { CustomRowComponent } from './atoms/custom-row/custom-row.component';
import { DatePickerComponent } from './atoms/date-picker/date-picker.component';
import { DropDownComponent } from './atoms/dropdown/dropdown.component';
import { DragDropComponent } from './atoms/files/files.component';
import { GroupedCheckboxComponent } from './atoms/grouped-checkbox/grouped-checkbox.component';
import { GroupedItemsProductComponent } from './atoms/grouped-items/grouped-items.component';
import { HeaderLabelComponent } from './atoms/header-label/header-label.component';
import { MultiSelectActionComponent } from './atoms/multi-select-action/multi-select-action.component';
import { MultipleFieldComponent } from './atoms/multiple-field/multiple-field.component';
import { MultiselectDropdownComponent } from './atoms/multiselect-dropdown/multiselect-dropdown.component';
import { RadioComponent } from './atoms/radio/radio.component';
import { SearchAutoCompleteComponent } from './atoms/search-autocomplete/search-autocomplete.component';
import { SearchBoxComponent } from './atoms/search-box/search-box.component';
import { SwitchComponent } from './atoms/switch/switch.component';
import { TextAreaComponent } from './atoms/text-area/text-area.component';
import { TextBoxComponent } from './atoms/textbox/textbox.component';
import { TimePickerComponent } from './atoms/time-picker/time-picker.component';
import { ToggleGroupComponent } from './atoms/toggle-group/toggle-group.component';
import { AdDirective } from './directives/agi-ad.directive';
import { DragDropDirective } from './directives/drag-drop.directive';
import { FieldBuilderComponent } from './field-builder/field-builder.component';
import { MaterialModule } from './material-module';
import { ConvertBaseSixtyFour } from './pipe/convert-base64.pipe';
import { ErrorValidation } from './pipe/error-validation.pipe';
import { GroupedIteampipe } from './pipe/grouped-class.pipe';
import { SearchMultiAutocompleteComponent } from './atoms/search-multi-autocomplete/search-multi-autocomplete.component';
import { ButtonComponent } from './atoms/button/button.component';
import { ToastrModule } from 'ngx-toastr';
import { ChipListComponent } from './atoms/chip-list/chip-list.component';
import { DecimalConveterDirective } from './directives/decimal-conveter.directive';
import { LabelIconComponent } from './atoms/label-icon/label-icon.component';
import { AutocompleteBoxComponent } from './atoms/autocomplete-box/autocomplete-box.component';
import { DaterangePickerComponent } from './atoms/daterange-picker/daterange-picker.component';
import { LabelTextComponent } from './atoms/label-text/label-text.component';
import { WeekListComponent } from './atoms/week-list/week-list.component';

// tslint:disable-next-line: typedef
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AgilysysDynamicformComponent,
    FieldBuilderComponent,
    TextBoxComponent,
    DropDownComponent,
    CheckBoxComponent,
    DragDropComponent,
    RadioComponent,
    TextAreaComponent,
    MultipleFieldComponent,
    FormIconsComponent,
    DatePickerComponent,
    ToggleGroupComponent,
    SwitchComponent,
    FormIconsComponent,
    GroupedItemsProductComponent,
    TimePickerComponent,
    SearchBoxComponent,
    GroupedIteampipe,
    ConvertBaseSixtyFour,
    CustomRowComponent,
    AdDirective,
    ErrorValidation,
    MultiselectDropdownComponent,
    DragDropDirective,
    MultiSelectActionComponent,
    HeaderLabelComponent,
    GroupedCheckboxComponent,
    CheckBoxSingleComponent,
    SearchAutoCompleteComponent,
    SearchMultiAutocompleteComponent,
    ButtonComponent,
    ChipListComponent,
    DecimalConveterDirective,
    LabelIconComponent,
    AutocompleteBoxComponent,
    DaterangePickerComponent,
    LabelTextComponent,
    WeekListComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ToastrModule.forRoot(),
    NgxMaterialTimepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate : false
    }),
  ],
  providers: [DecimalPipe],
  exports: [
    AgilysysDynamicformComponent,
    FieldBuilderComponent,
    TextBoxComponent,
    DropDownComponent,
    CheckBoxComponent,
    DragDropComponent,
    RadioComponent,
    TextAreaComponent,
    MultipleFieldComponent,
    FormIconsComponent,
    DatePickerComponent,
    ToggleGroupComponent,
    SwitchComponent,
    FormIconsComponent,
    // GroupedItemsProductComponent,
    TimePickerComponent,
    SearchBoxComponent,
    GroupedIteampipe,
    ConvertBaseSixtyFour,
    // CustomRowComponent,
    AdDirective,
    ErrorValidation,
    MultiselectDropdownComponent,
    DragDropDirective,
    MultiSelectActionComponent,
    HeaderLabelComponent,
    GroupedCheckboxComponent,
    CheckBoxSingleComponent,
    SearchAutoCompleteComponent,
    SearchMultiAutocompleteComponent,
    ButtonComponent,
    ChipListComponent,
    DecimalConveterDirective,
    AutocompleteBoxComponent
  ]
})
export class AgilysysDynamicformModule { }

import { Component, OnDestroy, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { PMSRequestInfo, TokenLoginModel } from 'src/app/common/external-request/pms-request.model';
import { SPAConfig } from '../../core/config/SPA-config';
import { UserMachineConfigurationService } from '../../core/services/user-machine-configuration.service';
import { RetailSharedVariableService } from '../../retail/shared/retail.shared.variable.service';
import { PropertySettingDataService } from '../../settings/system-config/property-setting.data.service';
import { BaseResponse } from '../../shared/business/shared.modals';
import * as GlobalConst from '../../shared/globalsContant';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { IdleTimeoutService } from '../../shared/service/session-expired.service';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';
import * as CONSTANTS from 'src/app/common/constants';
import { SpaLocalization } from '../localization/spa-localization';
import { LoginPopupComponent } from '../login-popup/login-popup.component';
import { PMSEventService } from '../pms-event-service';
import { AuthenticationService } from '../services/authentication.service';
import { SpaPropertyInformation } from '../services/spa-property-information.service';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ApmService } from '@elastic/apm-rum-angular';
import { UserMachineInfo } from 'src/app/common/shared/shared.modal';
import { SELECTION_ON_LOGIN, TRANSACTION_BY_MACHINENAME } from 'src/app/common/shared/shared/globalsContant';
import { PropertySettingDataService as RetailPropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import { PayAgentService } from 'src/app/retail/shared/service/payagent.service';
import { ManageSessionService } from 'src/app/login/manage-session.service';
import { Localization } from 'src/app/common/localization/localization';
import { ConfigKeys, RetailConstants } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { PropertyFeaturesConfigurationService } from 'src/app/retail/sytem-config/payment-features-config/property-feature-config.service';
import { FeatureName, RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { PropertyService } from 'src/app/common/services/property.service';
import { FULL_STORY_ORG_ID, JWT_TOKEN, NO_OF_DECIMAL_DIGITS,SPA_PRODUCT_ID,SUPPORT_TENANT, SUPPORT_USERNAME } from 'src/app/app-constants';
import * as FullStory from '@fullstory/browser';
import { Product } from 'src/app/shared/enums/shared-enums';
import { DefaultBucketName } from 'src/app/retail/shared/globalsContant';
import { CryptoUtility } from '../utilities/crypto.utility';
import { ADB2CAuthConfiguration } from 'src/app/login/auth.config';
import { NullValidationHandler, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { AlertType, ButtonType } from '../../shared/globalsContant';
import { AuthorizeConfigurationDataService } from 'src/app/common/dataservices/authorizeIntegrationConfiguration.data.service';
import { Product as AllProducts } from 'src/app/common/Models/common.models';
import { PropertySettingDataService as CommonPropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { SPAApiHosts, SPARoutes } from '../extensions/spa-route';
import { cloneDeep } from 'lodash';
import { GuestDataPolicyDataService } from 'src/app/common/dataservices/guest-datapolicy.data.service';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { SubPropertyDataService } from 'src/app/retail/retail-code-setup/retail-outlets/subproperty-data.service';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { UTempDataUtilities } from 'src/app/common/shared/shared/utilities/utempdata-utilities';
import { TenantConfigurationDataService } from 'src/app/retail/shared/service/data- services/tenantConfiguration.data.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [CryptoUtility, PropertySettingDataService]
})
export class LoginComponent implements OnInit, OnDestroy {
  login: any;
  LoginFormGrp: UntypedFormGroup;
  selectedLocation: any;
  loginSuccess: boolean = false;
  buttonValidate = "LOGIN";
  multipleLocations: any = [{
    id: 1, name: "Alpha Spa"
  }];
  propertyValues: any[];
  userInfo: any;
  propertyKey: string = "propertyInfo";
  userKey: string = "_userInfo";
  DisableLoginBtn: boolean = false;
  propertyDateKey: string = "propertyDate";
  userName: string;
  password: string;
  customerId: number;
  private _autoLogOff: any = false;
  private _logOffAfter: number = 1;
  subscription: ISubscription;
  propertyInformation: any;
  captions: any;
  cusId: any;
  maxLength: number = CONSTANTS.CUSTOMERID_LENGTH;
  showCustomerID: boolean = false;
  useridText: string;
  uname: string;
  muname: string;
  tenantCode: string = "";
  useridSubscribe: any;
  captionsResolutionErrorMsg: any;
  skipLoginFormLoad: boolean = false;
  currYear: number = 2024;
  preYear: number = 2019;
  enablePropertySelection: boolean = false;
  apmElasticService: any;
  userDetail;

  //Machine Name
  isMachineNameEnabled: boolean;
  isPromptOnLoginEnabled: boolean;
  defaultMachineId: number = 0;
  machineNames = [];
  userMachineInfo: UserMachineInfo;

  isPMSEventTriggered = false;
  uTempDataPrimary: string;
  uTempDataSecondary: string;
  floatLabel: string;
  labelCaption: any;
  hideLoginForm = false;
  ADB2CAuthenticationEnabled: boolean = false;
  enableLocation: boolean = false;
  isButtonClick: boolean = false;
  @ViewChild('userid_one') userid_one: ElementRef;
  @ViewChild('userId_two') userId_two: ElementRef;
  @ViewChild('custid') custid: ElementRef;
  errorvalue : string;
  errordescription : string;
  isMultiPropEnabled: boolean = true;
  isSupportUser : boolean = false;
  allTenantDetails: any[] = [];
  tenantIdList: any[] = [];
  propertyIdListForATenant: any[] = [];
  allPropertyDetails: any[] = [];
  tenantData: any=[];
  propertyData: any=[];
  initialTenantIdList: any[] = [];
  @ViewChild('myInput') myInput: ElementRef;
  inputSearch;
  showLoginloader:boolean=false;
  private intervalId: any; // Type 'any' can be replaced with 'number'
  private elapsedTime: number = 0;


  constructor(private authentication: AuthenticationService, private router: Router,
    public localization: SpaLocalization, private Form: UntypedFormBuilder,
    public http: HttpServiceCall, private utils: SpaUtilities, private spaconfig: SPAConfig, private dialog: MatDialog, private propertyInfo: SpaPropertyInformation, public _ids: IdleTimeoutService,
    private PropertySettingService: PropertySettingDataService, private formBuilder: UntypedFormBuilder,
    private retailPropertySettingDataService: RetailPropertySettingDataService,
    private userSessionConfig: UserMachineConfigurationService, private propertyFeatureService: PropertyFeaturesConfigurationService,
    private propertyServices: PropertyService, private crypto: CryptoUtility,
    private retailpropertyInfo: RetailPropertyInformation, private retailSharedService: RetailSharedVariableService,
    private route: ActivatedRoute, private sessionManageService: ManageSessionService, private pmsEventService: PMSEventService, public retailLocalization: RetailLocalization,
    private payAgentService: PayAgentService, @Inject(ApmService) apmService: ApmService, private commonLocalization: Localization,
    private oauthService: OAuthService,
    private authIntegrationService: AuthorizeConfigurationDataService,
    private adb2cAuthConfiguration: ADB2CAuthConfiguration,
    private guestDataPolicyDataService: GuestDataPolicyDataService,
    private commonPropertySettingService: CommonPropertySettingDataService,
    private dmConfigDataService: DMConfigDataService,
    private _subPropertyDataService: SubPropertyDataService,
    private loginCommunication:TenantManagementCommunication,
    private configuration: TenantConfigurationDataService,
    private utempdatautils: UTempDataUtilities) {

    this.floatLabel = this.localization.setFloatLabel;
    let loginUrlList: string[] = ["/login", "/", ""];
    if (loginUrlList.includes(this.router.url) && sessionStorage.getItem('a5smm_utoken') != null && sessionStorage.getItem(this.propertyDateKey) != null) {
      if(sessionStorage.getItem('UserTherapist'))
        this.router.navigateByUrl("/appointment");
      else
        this.router.navigateByUrl("/home/dashboard");
    }
    let allcaptions = this.localization.getCaptions();
    this.captions = allcaptions.login;
    this.labelCaption = this.localization.captions;
    this.captionsResolutionErrorMsg = allcaptions.resolutionNotFound.msg;
    this.apmElasticService = apmService;
  }
  hidePassword: boolean = false;

  async ngOnInit() {
    await this.initializeForm();
    document.querySelectorAll('body')[0].setAttribute('id',"bodyId");
    this.enableLoginloader(false);
    this.route.queryParams.subscribe(params => {
      this.errorvalue = params.error;
      this.errordescription = params.error_description;
      if(this.errorvalue != undefined && this.errordescription != undefined && this.errorvalue != '' && this.errordescription != '' )
      {
        this.utils.showAlert(this.errorvalue +"<br>"+ this.errordescription, AlertType.Info, GlobalConst.ButtonType.Ok,(res=>{
          window.location.href = window.location.origin + '/Spa/login';
        }));
        return false;
      }
    });
    if (this.router.url && this.router.url === '/supportlogin') {
      this.isSupportUser = true;
      this.LoginFormGrp.controls['customerId'].setValue(SUPPORT_TENANT);
      this.LoginFormGrp?.controls["customerId"].disable();
      this.ValidateCredentials('');
    }
  }

  private async initializeForm() {
    this.hideLoginForm = true;
    this.enablePropertySelection = false;
    this.LoginFormGrp = this.formBuilder.group({
      userid: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
      customerId: new UntypedFormControl('',[Validators.required]),
      machineName: ['0', Validators.required],
      location:'',
      tenantId:[''],
      propertyId:['']
    })

    let resolvedRouteData: TokenLoginModel = this.route.snapshot.data.loginData;
    if (resolvedRouteData) {
      this.resolvePMSLogin(resolvedRouteData)
    }else{
      await this.getCustomerID();
    }

    if (!this.ADB2CAuthenticationEnabled) {
      const getrememberresult = this.sessionManageService.GetRememberedUsers();
      const rememberedUser = (getrememberresult.length > 0) ? getrememberresult[0].name : '';
      this.LoginFormGrp.controls['userid'].setValue(rememberedUser ? rememberedUser : '');
      // this.LoginFormGrp.controls['rememberme'].setValue(rememberedUser ? true : false);
      this.LoginFormGrp.controls['password'].setValue('');
    }
    let custId= this.localization.getLocalCookie('appSPACustID');
    if(custId!=''){
      this.LoginFormGrp.controls['customerId'].setValue(custId);
      if (!this.ADB2CAuthenticationEnabled) {
        this.ValidateCredentials("",false,'');
        this.LoginFormGrp?.controls["customerId"].disable();
      }

    }
  }

  async getCustomerID() {
    await this.PropertySettingService.GetTenantbyEnvironmentConfig().then((res) => {
      this.cusId = res;
      this.setValues();
      if (this.cusId == "0" || this.ADB2CAuthenticationEnabled) {

        this.showCustomerID = true;
        this.setVal();
        this.OnFormValueChanges();

      }
      else {
        this.enableLoginloader(false);
        this.LoginFormGrp?.controls["customerId"].disable();
        this.showCustomerID = false;
        this.removeVal();
      }
    });

    let tenantId = localStorage.getItem('TenantId');
    let adb2cEnabled = localStorage.getItem('ADB2CAuthenticationEnabled');
    if (adb2cEnabled != null && adb2cEnabled.toLowerCase() == "true") {
      await this.configureAuth(tenantId);
    }
    //To load form for general authentication
    if (adb2cEnabled == null || (adb2cEnabled != null && adb2cEnabled.toLowerCase() == "false")) {
      this.hideLoginForm = false;
    }
  }

  async setGdprConfiguredData() {
    let userInfoTenantId = this.userInfo.tenantId ? this.userInfo.tenantId : this.propertyInfo.GetPropertyInfoByKey('TenantId');
    if(userInfoTenantId){
      var isGdprEnabled = await this.guestDataPolicyDataService.GetDataRetentionPolicyConfiguredFlag(Number(userInfoTenantId));
      sessionStorage.setItem("isGdprEnabled", isGdprEnabled.toString())
    }
  }

  private async configureAuth(tenantId: string) {
    await this.GetADB2CAuthConfig(tenantId);
    this.ADB2CAuthenticationEnabled = this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled;
    if (this.ADB2CAuthenticationEnabled) {
      this.hideLoginForm = true;
      this.oauthService.configure(this.adb2cAuthConfiguration.authConfig);
      this.oauthService.customQueryParams = {
        'customerId': tenantId,
        'productId': GlobalConst.Product.SPA
      };
      this.oauthService.tokenValidationHandler = new NullValidationHandler();
      this.oauthService.setStorage(localStorage);
      //Event Subscription
      this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
        switch (type) {
          case 'token_received':
            console.log([this.oauthService.state]);
            break;
          default:
            console.log([this.oauthService.state]);
            break;
        }
      });


      this.oauthService.loadDiscoveryDocument(this.adb2cAuthConfiguration.DiscoveryDocumentConfigUrl)
        .then(async doc => {
          console.log(doc);
          this.oauthService.tryLogin().then(async res => {
            if (this.oauthService.hasValidIdToken()) {
              this.hideLoginForm = true;
              await this.adb2cAuthLogin();
              //this.setUserSessionInfo(this.claims,this.oauthService.getIdToken());
            }
            else {
              this.hideLoginForm = false;
            }
            let _token = this.oauthService.getIdToken()
            //let _token = sessionStorage.getItem(JWT_TOKEN);
            console.log(_token);
          });
        });
    }
    else {
      this.hideLoginForm = false;
    }
  }

  async adb2cAuthLogin() {
    let tenantId = localStorage.getItem('TenantId');
    this.LoginFormGrp.controls.customerId.setValue(tenantId);
    let claims = this.adb2cClaims;
    const credentials = {
      email: await this.getADB2CEmailClaim(claims,tenantId),
      tenantId: tenantId,
      ProductId: GlobalConst.Product.SPA
    };
    if(!credentials.email)
    {
      this.utils.showAlert(this.captions.lbl_AzureTokenErrorMessage, AlertType.Error, ButtonType.Ok,(res=>{     
        this.adb2cLogout();
      }));  
      return false;
    }
    if(Number(tenantId) == SUPPORT_TENANT) {
      await this.ProcessSupportUserLogin(credentials.email);
    } else {
      await this.validateAdb2cCredentials(credentials, claims, tenantId);
    }
  }

  async getADB2CEmailClaim(claims,tenantId)
  {    
    let email = "";
    let user;
    let userName = claims['name'];

    if(claims != null && claims != undefined)
    {
      if(claims['emails'] != null && claims['emails'].length > 0)
      {
        email = claims['emails'][0];
      }
      else if (claims['email'] != null)
      {
        email = claims['email'];
      }
    }
    else if (userName != null) {
      const serviceParams = {
        route: SPARoutes.GetUserByTenantId,
        uriParams: { UserName : userName, tenantId : tenantId},
        header: '',
        showError: true,
        baseResponse: true
      };
      let token = localStorage.getItem('id_token');
      sessionStorage.setItem('_jwt', token);
      user = await this.authentication.InvokeServiceCallAsync(SPARoutes.GetUserByTenantId,GlobalConst.Host.CommonGateway,HttpMethod.Get,serviceParams.uriParams);
      email = user.result.email;
    }
    else{
      this.utils.showAlert(this.captions.lbl_UserTokenErrorMessage, AlertType.Error, ButtonType.Ok, (res => {
        this.adb2cLogout();
      }));
    }   
    
    return email;
  }

  public get adb2cClaims() {
    let claims = this.oauthService.getIdentityClaims();
    return claims;
  }

  async validateAdb2cCredentials(credentials, claims: any, strTenantId: string) {
    if (!this.loginSuccess && !this.enableLocation) {
      // Validate credentials
      let token = this.oauthService.getIdToken();
      const tenantId = Number(strTenantId);
      const loginDetails = await this.PropertySettingService.GetADB2CLogin(credentials);

      if (loginDetails.successStatus) {
        loginDetails.result.token = token;
        this.userName = loginDetails.result.userLoginInfo.userName;
        const loginResponse: any = loginDetails;
        const jwtToken = JSON.parse(atob(token.split('.')[1]));
        const jwtExpiryTime = new Date(jwtToken.exp * 1000);
        const timeout = jwtExpiryTime.getTime() - Date.now();
        const loginDuration = Math.round(timeout/1000);
        if (loginResponse.result.loginDuration) {
          loginResponse.result.loginDuration = loginDuration;
          sessionStorage.setItem('loginDuration', loginResponse.result.loginDuration);
          localStorage.setItem('loginDuration', loginResponse.result.loginDuration);
          const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
          this.sessionManageService.startTimer(0, tokenDuration);
          sessionStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
          localStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
        }
        await this.successCallback(loginDetails, 'Login', null);
      } else {
        // if (loginDetails.errorCode == 5001) {
        //   this.loginError = true;
        //   this.loginButton.disabledproperty = false;
        //   this.errResponse = loginDetails.errorDescription;
        this.hideLoginForm = false;

        this.utils.showAlert(loginDetails.errorDescription, AlertType.Error, ButtonType.Ok, async (res) => {
          this.adb2cLogout();
        });
      }
    }
  }

  public adb2cLogout() {
    this.oauthService.logOut();
  }

  async GetADB2CAuthConfig(tenantId: string) {
    const iTenantId: number = Number(tenantId);
    let uriParams = { "tenantId": iTenantId, "productId": GlobalConst.Product.SPA }
    let adb2cConfig: any;
    adb2cConfig = await this.PropertySettingService.GetADB2CConfig(uriParams);
    this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled = adb2cConfig.adB2CAuthenticationEnabled;
    this.adb2cAuthConfiguration.DiscoveryDocumentConfigUrl = adb2cConfig.discoveryDocumentUrl;
    let adb2cUrl = this.commonLocalization.getLocalCookie('supportUserMailId') || sessionStorage.getItem('supportUserMailId') || this.isSupportUser ? '/Spa/supportlogin' : '/Spa/login';
    this.adb2cAuthConfiguration.authConfig = {
      redirectUri: window.location.origin + adb2cUrl,
      postLogoutRedirectUri: window.location.origin + adb2cUrl,
      responseType: 'code',
      issuer: adb2cConfig.issuer,
      strictDiscoveryDocumentValidation: false,
      tokenEndpoint: adb2cConfig.tokenEndPoint,
      loginUrl: adb2cConfig.loginUrl,
      clientId: adb2cConfig.clientId,
      scope: 'openid',
      skipIssuerCheck: true,
      clearHashAfterLogin: true,
      oidc: true
    };
  }

  async resolvePMSLogin(data: TokenLoginModel) {
    if (!data.loginStatus) {
      this.handleFailedLoginFromPMS(data.errorCode);
      return;
    }
    this.skipLoginFormLoad = true;
    data.userLoginInfo.isNewUser = false;
    data.userLoginInfo.isPasswordExpired = false;
    await this.setUserData(data, true);
    let requestInfo = this.utils.getSessionValue<PMSRequestInfo>('PMSRequestInfo');
    if (requestInfo && requestInfo.propertyCode && data.userProperties.length > 0) {
      let userLocation = data.userProperties.filter(x => x.propertyCode == requestInfo.propertyCode)[0];
      let credentials = {
        userid: data.userLoginInfo.userName,
        password: data.token,
        location: {
          id: userLocation.propertyCode,
          name: userLocation.propertyName
        }
      }
      let tokenDuration = sessionStorage.getItem('loginDuration') ? parseInt(sessionStorage.getItem('loginDuration')) : 0;
      if (tokenDuration <= 0) {
        tokenDuration = 36000;
      }
      this.setJwtTimer(tokenDuration);
      this.setMachineInfo(userLocation.propertyId, true);
      this.ValidateCredentials(credentials, true);
    }

  }


  OnFormValueChanges(): any {
    // this.useridSubscribe = this.LoginFormGrp.get('userid').valueChanges.subscribe(r => {


    //   this.useridText = r;
    //   if (this.useridText.includes("@")) {
    //     this.showCustomerID = false;
    //     this.removeVal();
    //   }
    //   else {
    //     this.showCustomerID = true;
    //     this.setVal();
    //   }


    // });
  }

  setVal() {
    this.LoginFormGrp.controls['customerId'].setValidators([Validators.required]);
    this.LoginFormGrp.controls['customerId'].updateValueAndValidity();
    this.LoginFormGrp.controls['customerId'].markAsDirty();
  }
  removeVal() {
    this.LoginFormGrp.controls['customerId'].clearValidators();
    this.LoginFormGrp.controls['customerId'].updateValueAndValidity();
    this.LoginFormGrp.controls['customerId'].markAsDirty();
  }
  ngOnDestroy(): void {
    this.stopInterval();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.useridSubscribe) {
      this.useridSubscribe.unsubscribe();
    }
  }

  loadLocations() {
    ////this.multipleLocations = this.multipleLocations;
  }

  onPropertyChange(eve) {
    const propertyInfo = this.propertyValues.find(item => item.propertyCode === eve.value.id);
    this.setMachineInfo(propertyInfo.propertyId);
    this.LoginFormGrp.controls["location"].setValue(eve.value);
  }

  loadMachineNames() {
    this.machineNames = this.machineNames;
  }

  setGeneralLoginVal() {
    const userIdCtrl = this.LoginFormGrp.get('userid');
    userIdCtrl.setValidators([Validators.required]);
    userIdCtrl.updateValueAndValidity();
    userIdCtrl.markAsDirty();

    const passwordCtrl = this.LoginFormGrp.get('password');
    passwordCtrl.setValidators([Validators.required]);
    passwordCtrl.updateValueAndValidity();
    passwordCtrl.markAsDirty();
  }

  removeGeneralLoginVal() {
    const userIdCtrl = this.LoginFormGrp.get('userid');
    userIdCtrl.clearValidators();
    userIdCtrl.updateValueAndValidity();
    userIdCtrl.markAsDirty();

    const passwordCtrl = this.LoginFormGrp.get('password');
    passwordCtrl.clearValidators();
    passwordCtrl.updateValueAndValidity();
    passwordCtrl.markAsDirty();
  }

  async getbuttonEmitvalue(e): Promise<void> {
    this.enableLoginloader(true);
    if(!this.showLoginloader){
    if (this.showCustomerID) {
      localStorage.setItem('TenantId', this.LoginFormGrp.get('customerId').value);
      let tenantId = localStorage.getItem('TenantId');
      await this.configureAuth(tenantId);
      localStorage.setItem('ADB2CAuthenticationEnabled', this.ADB2CAuthenticationEnabled.toString());
      this.LoginFormGrp.get('customerId').markAsTouched();
      this.removeVal();
      if (this.ADB2CAuthenticationEnabled) {
        this.removeGeneralLoginVal();
        this.adb2cAuthValidation();
      }
      else {
        this.enableLoginloader(false);
        this.LoginFormGrp?.controls["customerId"].disable();
        this.showCustomerID = false;
       }
    }
    else if(this.ADB2CAuthenticationEnabled && this.isSupportUser)
    {
        this.removeGeneralLoginVal();
        this.validateAdb2cCredentialsForSupportUser();
    }
    else if (this.ADB2CAuthenticationEnabled) {
      this.removeGeneralLoginVal();
      this.adb2cAuthValidation();
    }
    else {
      this.LoginFormGrp.controls['userId'].markAsTouched();
      this.LoginFormGrp.controls['password'].markAsTouched();
      this.setGeneralLoginVal();
      const credentials = { UserName: this.muname, Password: this.password, TenantId: this.customerId != null ? this.customerId : 0, location: this.multipleLocations[0], ProductId: GlobalConst.Product.SPA, TenantCode: this.tenantCode };
      this.ValidateCredentials(credentials, this.isPMSEventTriggered);
    }
  }
  }

  adb2cAuthValidation() {
    if (this.LoginFormGrp.valid) {
      if (!this.loginSuccess && !this.enableLocation) {
        localStorage.setItem('TenantId', this.LoginFormGrp.get('customerId').value);
        this.adb2cLogin();
      }
      else {
        let claims = this.adb2cClaims;
        let property=this.LoginFormGrp.controls['location']?.value;
        let selectedPropertyCode=this.LoginFormGrp.controls['location']?.value?.id;
        if (!(typeof selectedPropertyCode!='undefined' && selectedPropertyCode)){
          selectedPropertyCode=this.multipleLocations[0].id;
          property=this.multipleLocations[0];
        }
        let result = this.propertyValues.find(item => item.propertyCode === selectedPropertyCode);
        this.propertyInformation = result;
        const credentials = {
          Property: property,
          ProductId: GlobalConst.Product.SPA,
          userId: this.propertyInformation["userId"],
          propertyId: this.propertyInformation["propertyId"],
          productId: this.propertyInformation["productId"],
        };
        this.CreateUserSession(credentials, this.isPMSEventTriggered);
      }
    }
  }

  public adb2cLogin() {
    this.oauthService.initCodeFlow();
  }

  async ValidateCredentials(credentials, isFromPMS: boolean = false, e: any="") {
    if(!this.showLoginloader){
    window.onbeforeunload = null;
    this.localization.setLocalCookie('appSPACustID',this.LoginFormGrp.get('customerId').value);
    if(e){
      this.enableLoginloader(true);
      e.preventDefault();
    }
    if (this.showCustomerID) {
      localStorage.setItem('TenantId', this.LoginFormGrp.get('customerId').value);
      let tenantId = localStorage.getItem('TenantId');
      await this.configureAuth(tenantId);
      localStorage.setItem('ADB2CAuthenticationEnabled', this.ADB2CAuthenticationEnabled.toString());
      this.LoginFormGrp.get('customerId').markAsTouched();
      this.removeVal();
      if (this.ADB2CAuthenticationEnabled) {
        this.removeGeneralLoginVal();
        await this.adb2cAuthValidation();
      }
      else {
        this.enableLoginloader(false);
        this.showCustomerID = false;
        this.LoginFormGrp?.controls["customerId"].disable();
        setTimeout(() => {
          this.userid_one.nativeElement.focus();
        }, 0);
      }
    }
    else if(this.ADB2CAuthenticationEnabled && this.isSupportUser)
    {
        this.removeGeneralLoginVal();
        this.validateAdb2cCredentialsForSupportUser();
    }
    else if (this.ADB2CAuthenticationEnabled) {
      this.removeGeneralLoginVal();
      await this.adb2cAuthValidation();
    }
    else {
      this.LoginFormGrp.controls['userid'].markAsTouched();
      this.LoginFormGrp.controls['password'].markAsTouched();
      this.setGeneralLoginVal();
      this.generalAuthValidation(credentials, isFromPMS);
    }
  }
  }

  async generalAuthValidation(credentials, isFromPMS: boolean = false) {
    this.tenantCode = "";
    this.userName = this.LoginFormGrp.controls['userid'].value;
    this.password = this.LoginFormGrp.controls['password'].value;
    this.uname = this.userName;
    if (this.uname.includes('@')) {
      this.muname = this.uname.substring(0, this.uname.indexOf('@'));
      this.tenantCode = this.uname.substring(this.uname.indexOf('@') + 1);

    } else {
      this.muname = this.uname;
    }
    let id: any = this.LoginFormGrp.controls['customerId'].value != "" ? this.LoginFormGrp.controls['customerId'].value : this.cusId;
    this.customerId = parseInt(id);
    if (credentials.userid != "" && !isNaN(this.customerId) && credentials.password != "" && !this.loginSuccess && !this.enablePropertySelection) {
      this.DisableLoginBtn = true;
      if (this.uTempDataPrimary && this.uTempDataSecondary) {
        let password = this.crypto.EncryptString(this.password, this.uTempDataPrimary, this.uTempDataSecondary);
       await this.authentication.loginEnc(this.muname, password, this.customerId, this.tenantCode, this);
      }
      else {
       await this.authentication.login(this.muname, this.password, this.customerId, this.tenantCode, this);
      }
    } else if (credentials.userid != "" && credentials.password != "" && credentials.location != "" && credentials.machineName != "" && this.loginSuccess) {
      if (isFromPMS) {
        const pmsData = this.route.snapshot.data.loginData as TokenLoginModel;
        this.userName = pmsData.userLoginInfo.userName;
      }
      let result = this.propertyValues.find(item => item.propertyCode === credentials.location.id);
      this.propertyInformation = result;
      await this.CreateUserSession(result, isFromPMS,);
    }
    else if (credentials.userid != "" && credentials.password != "" && credentials.location != "" && credentials.machineName != "" && this.loginSuccess && this.enablePropertySelection) {
      let result = this.propertyValues.find(item => item.propertyCode === credentials.location.id);
      this.propertyInformation = result;
      await this.CreateUserSession(result, isFromPMS,);
    }
    if(this.isPromptOnLoginEnabled && this.machineNames.length > 0){
      this.LoginFormGrp.controls['machineName'].markAsTouched();
    }
  }

  async CreateUserSession(sessionInfo, isFromPMS) {
    const utcDate: Date = this.localization.getUTCDateTimeNow();
    const token = sessionStorage.getItem('_jwt');
    let sessionData = {
      id: 0,
      userId: Number(sessionInfo["userId"]),
      startTime: this.localization.ConvertDateToISODateTime(utcDate),
      propertyId: Number(sessionInfo['propertyId']),
      productId: Number(sessionInfo['productId']),
      timeZone: this.utils.GetClientTimeZone(),
      token: token
    };
    await this.authentication.CreateSession(sessionData, this, isFromPMS);

  }

  async setpropertyvalues() {
    const Selectedproperty = this.propertyInformation;
    let userLanguageCode = this.userInfo != null && this.userInfo.languageCode != "" ? this.userInfo.languageCode : Selectedproperty["languageCode"];
    let maxDecimalPlace = Selectedproperty["maximumDecimalPlaces"] >=0 ? Selectedproperty["maximumDecimalPlaces"] : 2;
    let PropertyValues = "Language=" + Selectedproperty["languageCode"] + "; PropertyCode=" + Selectedproperty["propertyCode"] +
      "; SubPropertyCode=" + Selectedproperty["subPropertyCode"] + "; Currency=" + Selectedproperty["currencyCode"] + "; TenantId=" + Selectedproperty["tenantId"] + "; userName=" + this.userName
      + "; UserId=" + Selectedproperty["userId"] + "; RoleId=" + Selectedproperty["roleId"] + "; PropertyId=" + Selectedproperty['propertyId'] + "; SubPropertyId=" + Selectedproperty['subpropertyId'] + "; PlatformTenantId=" + Selectedproperty['platformTenantId'] + "; PropertyDate=" + Selectedproperty['propertyDate']
      + "; TimeZone=" + Selectedproperty['timeZone'] + "; LogOffAfter=" + Selectedproperty['logOffAfter'] + "; AutoLogOff=" + Selectedproperty['autoLogOff'] + "; PropertyName=" + Selectedproperty['propertyName']
      + "; UserLanguage=" + userLanguageCode + "; StartOfWeek=" + Selectedproperty['startOfWeek'] + "; ProductId=" + Selectedproperty['productId'] + "; MaxDecimalPlaces=" + maxDecimalPlace + "; PropTimeFormat=" + Selectedproperty['propTimeFormat'] + "; PlatFormExtendedSearchRequired=" + Selectedproperty['platFormExtendedSearchRequired'];

    sessionStorage.setItem(this.propertyKey, PropertyValues);
    sessionStorage.setItem(this.propertyDateKey, Selectedproperty['propertyDate']);
    await this.SetPropertyInfo(Selectedproperty);
    this.localization.SetLocaleBasedProperties();
    this.commonLocalization.SetLocaleBasedProperties();
    this.retailLocalization.SetLocaleBasedProperties();
    this.spaconfig.load();
    this.setEatecConfig();
    await this.setAcesToken();
    this.setRequireSettlement();

      await this.dmConfigDataService.SetDataMagineConfig();    
  }
  async setRequireSettlement() {
    let isPreSettlementEnabled: boolean = false;
    this.retailPropertySettingDataService.GetMiscConfigurationSetting().then(
      miscSettings => {
        if (miscSettings.filter(x => x.switch === "REQUEST_PRESETTLEMENT_RECEIPT_ON_PENDING_SETTLEMENT").length > 0) {
          isPreSettlementEnabled = miscSettings.filter(x => x.switch === "REQUEST_PRESETTLEMENT_RECEIPT_ON_PENDING_SETTLEMENT")[0].value === "true";
        }
        sessionStorage.setItem("RequirePreSettlement", isPreSettlementEnabled.toString());
      }
    )
  }
  async setEatecConfig() {
    this.propertyFeatureService.getPropertyFeatures().then(async (feature) => {
      const eatecFeature = feature && feature.find(x => x.featureName === FeatureName.EnhancedInventory && x.isActive);
      const memberFeature = feature && feature.find(x => x.featureName === FeatureName.ACES_Membership && x.isActive);
      const pmsRevenuePosting = feature && feature.find(x => x.featureName === FeatureName.PMS_RevenuePosting && x.isActive);
      const propIds = [];

      if (eatecFeature) {
        let isEatecAsMaster = false;
        const configuration = await this.configuration.GetTenantConfiguration();
        if(configuration?.configValue) {
          isEatecAsMaster =  configuration.configValue?.IsEatecMaster ? configuration.configValue.IsEatecMaster.toLowerCase() == 'true' : false ;
          sessionStorage.setItem("isEatecAsMaster" , isEatecAsMaster.toString());
        }
        if(!isEatecAsMaster) {
          sessionStorage.setItem('isEatecEnabled', 'true');
          propIds.push(eatecFeature.id);
          await this.setEatecToken();
          const propConfig :{} = JSON.parse(sessionStorage.getItem('propConfig'));
          const enableRetailIC = propConfig? (propConfig['EnableRetailIC'] == 'true'? true: false): false;
          if(enableRetailIC) {
            const siteId = await this.retailPropertySettingDataService.GetSiteIdForIC();
            sessionStorage.setItem('LoggedInSiteId', JSON.stringify(siteId));
            let outlets = await this._subPropertyDataService.getOutlets();
            let OutletIdlist:any = outlets.map(x=>x.id);
            sessionStorage.setItem('FromLocId', JSON.stringify((OutletIdlist ? OutletIdlist : '')));
            sessionStorage.setItem('IniDateFieldFormat', this.localization.inputDateFormat);
            sessionStorage.setItem('LocalCurrencyCode', this.localization.currencyCode);
          } 
          
        } 
      } 
      else {
        sessionStorage.setItem('isEatecEnabled', 'false');
        this.retailpropertyInfo.SetEatecRI('');
      }

      if (memberFeature) {
        propIds.push(memberFeature.id);
      } else {
        this.retailpropertyInfo.SetMemberConfiguration('');
      }
      if (pmsRevenuePosting) {
        propIds.push(pmsRevenuePosting.id);
      } else{
        sessionStorage.setItem('pmsSystem', '');
      }

      if (propIds.length > 0) {
        this.propertyFeatureService.GetFeatureConfigurationsById(propIds).then(featureconfigurations => {
          // Eatec
          if (eatecFeature && featureconfigurations != null && featureconfigurations.length > 0) {
            const eatecUser = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EatecTenantUser);
            const uri = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EatecURI);
            const EIFeature = {
              EISSOWaitPeriodInSecs: featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EISSOWaitPeriodInSecs)?.configurationValue,
              EISSOTriggerAlways: featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EISSOTriggerAlways)?.configurationValue
            };
            this.retailpropertyInfo.SetEatecConfiguration(EIFeature);
            if (eatecUser && eatecUser.configurationValue && uri && uri.configurationValue) {
              this.retailpropertyInfo.SetEatecRI(uri.configurationValue);
            } else {
              this.retailpropertyInfo.SetEatecRI('');
            }
          } else {
            this.retailpropertyInfo.SetEatecRI('');
          }

          // ACES
          if (memberFeature && featureconfigurations != null && featureconfigurations.length > 0) {
            const uri = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.LoyaltyURI);
            const loyaltyServiceURI = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.LoyaltyServiceURI);
            const loyaltyTokenRequestInfo = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.LoyaltyTokenRequestInfo);
            const enforceMemberPayment = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.EnforceMemberPayment);
            const displayCreditBookBalance = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.DisplayCreditBookBalance);
            const allowTenderAmountOverrideForMember = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.AllowTenderAmountOverrideForMember);
            const memberBucketName = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.MemberBucketName);
            const creditBookBucketName = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.CreditBookBucketName);
            const requirePin = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.RequirePin);
            const loyalty = {
              loyaltyURI: uri && uri.configurationValue ? uri.configurationValue : '',
              loyaltyServiceURI: loyaltyServiceURI && loyaltyServiceURI.configurationValue ? loyaltyServiceURI.configurationValue : '',
              loyaltyTokenRequestInfo: loyaltyTokenRequestInfo && loyaltyTokenRequestInfo.configurationValue ?
                loyaltyTokenRequestInfo.configurationValue : '',
              enforceMemberPayment: (enforceMemberPayment && enforceMemberPayment.configurationValue) || "false",
              displayCreditBookBalance: (displayCreditBookBalance && displayCreditBookBalance.configurationValue) || "false",
              allowTenderAmountOverrideForMember: (allowTenderAmountOverrideForMember?.configurationValue),
              memberBucketName: (memberBucketName?.configurationValue?.trim()) || DefaultBucketName.memberBucketName,
              creditBookBucketName: (creditBookBucketName?.configurationValue?.trim()) || DefaultBucketName.creditBookBucketName,
              requirePin: (requirePin?.configurationValue?.trim()) || "false",
              showCurrentRoundsOnReprint : featureconfigurations.find(f => f.configurationKey ===
                ConfigKeys.Member.ShowCurrentRoundsOnReprint)?.configurationValue || 'false',
              allowMemberPartialPayment : featureconfigurations.find(f => f.configurationKey ===
                  ConfigKeys.Member.AllowMemberPartialPayment)?.configurationValue || 'false'
            };
            this.retailpropertyInfo.SetMemberConfiguration(loyalty);
          } else {
            this.retailpropertyInfo.SetMemberConfiguration('');
          }
          if (pmsRevenuePosting && featureconfigurations != null && featureconfigurations.length > 0) {
            const pmsSystem = featureconfigurations.find(f => f.configurationKey === ConfigKeys.PMSRevenuePosting.PMSSystem)?.configurationValue;
            if (pmsSystem && pmsSystem != null) {
              sessionStorage.setItem('pmsSystem', pmsSystem);
            }
          } else{
            sessionStorage.setItem('pmsSystem', '');
          }
        });
      }
    });
  }
  async setEatecToken() {
    try {
      const token = await this.authentication.getEatecToken(this);
      sessionStorage.setItem('eatecJwt', token.result);
    } catch (ex) {

    }
  }

  async setAcesToken() {
    try {
      const token = await this.authentication.getEngageToken(this);
      sessionStorage.setItem('acesJwt', token.result);
    } catch (ex) {

    }
  }

  async setAccountingPropertyDate(){
    const enableResortFinance = sessionStorage.getItem(RetailConstants.EnableResortFinance);
    if (enableResortFinance?.toLowerCase() == 'true')
    {
      let propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
      if(propConfig && propConfig.AccountingPropertyId)
      {
          this.commonPropertySettingService.UpdatePropertySettingDateByProductId(this.localization.convertDateToAPIdate(this.localization.getCurrentDate()), propConfig.AccountingPropertyId, AllProducts.ACCOUNTING);
      }
    }
  }

  async SetPropertyInfo(result: any) {
    let propertyId: number = Number(result['propertyId']);
    this.propertyInfo.SetPropertyDate(result['propertyDate']);
    this.retailSharedService.propertyDate = this.propertyInfo.CurrentDate;
    this.propertyInfo.SetPropertyId(propertyId);
    await this.SetPropertyConfiguration();
    this.setGdprConfiguredData();
    this.SetAuthorizeIntegrationConfiguration();
    if (!this.propertyInfo.UseRetailInterface) {
      await this.SetPaymentConfiguration(propertyId);
    }
  }

  SetElasticApm(propertyConfig: any) {
    if (propertyConfig.configValue != undefined && propertyConfig.configValue[GlobalConst.ElasticApm.UseApmServer] == 'true') {
      if (propertyConfig.configValue[GlobalConst.ElasticApm.ApmServerUrl] != undefined && propertyConfig.configValue[GlobalConst.ElasticApm.DistributedTracingOrigins] != undefined) {
        const apm = this.apmElasticService.init({
          serviceName: GlobalConst.ElasticApm.ServiceName,
          serverUrl: propertyConfig.configValue[GlobalConst.ElasticApm.ApmServerUrl],
          distributedTracingOrigins: propertyConfig.configValue[GlobalConst.ElasticApm.DistributedTracingOrigins].split(',')
        })
        apm.setUserContext({
          'username': this.userInfo.userName,
          'id': this.propertyInfo.PropertyId //PropertyId is set in user Id field of Kibana for Filtering
        });
      }
    }
  }
  SetNoOfDecimalDigits(propertyConfig: any) {
    var noOfDecimalDigits:string='2';
    if (propertyConfig.configValue != undefined && propertyConfig.configValue[NO_OF_DECIMAL_DIGITS] != undefined){
      noOfDecimalDigits=propertyConfig.configValue[NO_OF_DECIMAL_DIGITS];
    }
    sessionStorage.setItem('noOfDecimalDigits',noOfDecimalDigits);
  }
  async SetPropertyConfiguration() {
    let propertityConfig = await this.PropertySettingService.getAllPropertySetting();
    this.propertyInfo.SetPropertySetting(propertityConfig);
    this.retailSharedService.useRetailInterface = this.propertyInfo.UseRetailInterface;
    let propertyConfig = await this.PropertySettingService.getPropConfig();
    this.SetNoOfDecimalDigits(propertyConfig);
    this.SetElasticApm(propertyConfig);
    this.SetFullStory(propertyConfig);
    this.propertyInfo.SetPropertyConfiguration(propertyConfig);
  }

  async SetAuthorizeIntegrationConfiguration() {
    let authorizeIntegrationconfig = await this.authIntegrationService.GetAuthorizeConfig();
    this.propertyInfo.SetAuthorizeConfiguration(authorizeIntegrationconfig);
  }

  async SetPaymentConfiguration(propertyId: number) {
    let propertyPaymentConfig = await this.PropertySettingService.GetPaymentConfigurationByProperty(propertyId);
    this.propertyInfo.SetPaymentConfiguration(propertyPaymentConfig);
    this.GetSupportedPMAgentVersionByPropertyID(propertyId);
    this.GetWebCommunicationProxyVersion();
  }

  async GetSupportedPMAgentVersionByPropertyID(propertyId: number) {
    if (this.propertyInfo.SkipPMAgent) return; //No need to Validate the PMAgent version if SkipPMAgent is enabled
    const supportedPmAgentVersion = await this.PropertySettingService.GetSupportedPMAgentVersionByPropertyID(propertyId);
    this.propertyInfo.SetSupportedPMAgentVersion(supportedPmAgentVersion);
    this.payAgentService.ValidatePayAgentVersion();
  }

  async GetWebCommunicationProxyVersion(){
    const WebProxyCheck = await this.retailPropertySettingDataService.GetWebCommunicationProxyVersion();
    this.retailpropertyInfo.SetWebCommunicationProxyVersionCheck(WebProxyCheck)
  }

  async SetUserSessionConfiguration(userId: number) {
    let userSessionConfig = await this.userSessionConfig.getUserSessionConfiguration(userId);

    if (userSessionConfig) {
      let userSessionConfigValues =
        ` Id=${userSessionConfig.id};
          UserId=${userSessionConfig.userId};
          DefaultOutletId=${userSessionConfig.defaultOutletId};
          DefaultPaymentId=${userSessionConfig.defaultPaymentId};
          DefaultTerminalId=${userSessionConfig.defaultTerminalId};
          DefaultCourseId=${userSessionConfig.defaultCourseId};
          DefaultPaymentDevice=${userSessionConfig.defaultPaymentDevice};
          DefaultDeviceName=${userSessionConfig.defaultDeviceName};
          IsIdtechSred=${userSessionConfig.isIdtechSred};
          HangingTicketsPrinter=${userSessionConfig.hangingTicketsPrinter};
          SmallStickersPrinter=${userSessionConfig.smallStickersPrinter};
    `;

      sessionStorage.setItem(this.userSessionConfig.userSessionConfigKey, userSessionConfigValues);


      let defaultsSetting = await this.userSessionConfig.getDefaultSettings();
      sessionStorage.setItem('defaultSettings', JSON.stringify(defaultsSetting));

      // Set Retail Shop service - outlet dropdown value
      this.retailSharedService.SelectedOutletId = userSessionConfig.defaultOutletId;
      this.retailSharedService.SelectedTerminalId = userSessionConfig.defaultTerminalId;
    }
  }


  setAutoLogOff() {

    this._autoLogOff = this.utils.GetPropertyInfo('AutoLogOff');
    const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
    if (this._autoLogOff == "true") {
      this._ids.resetOnTrigger = true;
      this._logOffAfter = +this.utils.GetPropertyInfo('LogOffAfter');
      this._ids.startTimer(this._logOffAfter, tokenDuration);
    } else {
      this._ids.resetOnTrigger = false;
    }
  }

  setUserInformation() {
    let result = this.userInfo;
    let UserValues = "userId=" + result["userId"] + "; userName=" + result["userName"] + "; firstName=" + result["firstName"] + "; lastName=" + result["lastName"] + `;roleId=${this.propertyInformation['roleId']}` + `;
    roleName=${this.propertyInformation['roleName']}; changePropertyEnabled=${this.userInfo.isPropertyChangeAllow};`;
    sessionStorage.setItem(this.userKey, UserValues);
  }

  setJwtTimer(tokenDuration: number) {
    this._ids.startTimer(0, tokenDuration);
    let currentDateTime = new Date();
    let jwtExpiryTime = new Date(currentDateTime.getTime() + tokenDuration * 1000);
    sessionStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
  }


  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]) {
    if (callDesc == "Login" || callDesc == "LoginEncrypted") {
      let data = <any>result.result;
      this.userDetail = data;
      this.sessionManageService.UpdateUserSessionsInfo(this.userDetail);
      if(this.userDetail.userProperties && this.userDetail.userProperties.length > 1){
        this.isMultiPropEnabled = true;
      } else if(this.userDetail.userProperties && this.userDetail.userProperties.length == 1){
        this.isMultiPropEnabled = false;
      }
      // this.loginSuccess = true;
      if (data.loginDuration) {
        sessionStorage.setItem('loginDuration', data.loginDuration);
        const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
        this.setJwtTimer(tokenDuration);
      }
      await this.setUserData(data);
    }
    else if (callDesc == "CreateSession") {
      var response = <any>result.result;
      sessionStorage.setItem(this.authentication.userSessionId, response);
      await this.setpropertyvalues();
      this.setUserInformation();
      await this.propertyServices.setJasperAttributes(this.propertyInformation["roleId"]);
      sessionStorage.setItem(RetailConstants.EnableResortFinance, this.userInfo.enableResortFinance.toString());
      this.setAutoLogOff();
      await this.SetUserSessionConfiguration(this.userInfo.userId);
      this.setMachineDetails();
      await this.setAccountingPropertyDate();
      await this.propertyServices.setAuthorizeTokenBySession();
      const isFromPMS = extraParams[0];
      if (isFromPMS) {
        if (!this.isPMSEventTriggered) {
          this.isPMSEventTriggered = true;
          this.pmsEventService.handlePMSEvent();
        }
      } else {
        this.GetServiceCall(GlobalConst.Host.spaManagement, 'GetUserTherapist', [], {id : this.userInfo.userId})
        
      }
      try{
        if(this.localization.GetSupportUserMailId())
        {
          let data = {
            userEmail : this.localization.GetSupportUserMailId(),
            sessionId : response
          };
      
          const auditParams = {
            route: SPARoutes.AuditSupportUser,
            uriParams: '',
            header: '',
            body: data,
            showError: true,
            baseResponse: true
          };
      
          await this.loginCommunication.putPromise(auditParams, false,false);
        }
      }
      catch{
        console.log("Error in auditing support user")
      }
    }
    else if(callDesc == 'GetUserTherapist'){
      var response = <any>result.result;
      if(response){
        sessionStorage.setItem('UserTherapist', JSON.stringify(response));
        this.router.navigate(['/appointment']);
      }
      else {
        this.router.navigate(['/home']);
      }
      this.propertyServices.Checkfordeployment();
    }
    this.localization.SetLocaleBasedProperties();
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string): void {
    if (callDesc == "Login" || callDesc == "LoginEncrypted") {
      this.loginSuccess = false;
      let data = <any>error.result;
      this.SetUserLanguageToProperties(data)
      this.DisableLoginBtn = false;
      let loginErrorMessage: any = this.localization.getError(error.errorCode);
      this.utils.ShowErrorMessage(this.captions.LoginFailure, loginErrorMessage, GlobalConst.ButtonType.Ok);
      this.router.navigate(['/login']);
    }
  }

  SetUserLanguageToProperties(resultData: any) {
    let userLanguageCode = resultData != null && resultData.userLoginInfo != null && resultData.userLoginInfo.languageCode != "" ? resultData.userLoginInfo.languageCode : 'en-US';
    let PropertyValues = "UserLanguage=" + userLanguageCode;
    sessionStorage.setItem(this.propertyKey, PropertyValues);
    this.localization.SetLocaleBasedProperties();
    this.captions = this.localization.captions.login
    this.spaconfig.load();
  }

  openDialog(type: string) {
    // document.body.classList.add("blur-background");
    const dialogRef = this.dialog.open(LoginPopupComponent, {
      width: '800px',
      height: 'auto',
      data: {
        closebool: true, data: "", headername: this.captions.SetPassword, type: type,
        userId: this.userInfo.userId, userName: this.userInfo.userName, userInfo: this.userInfo,
        FnValidateCredentialslogin: this.ValidateCredentials.bind(this), paswordctrl: this.LoginFormGrp.controls['password'], passwordSetting: this.userDetail.passwordSetting,
        uTempData: { uTempPri: this.uTempDataPrimary, uTempSec: this.uTempDataSecondary }
      },
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'action-dialog-overlay'
    })
    this.subscription = dialogRef.afterClosed().subscribe(() => {
      // document.body.classList.remove("blur-background");
      this.enableLoginloader(false);
      this.DisableLoginBtn = false;
    });
  }

  async setUserData(data, fromPms = false) {
    this.userInfo = data.userLoginInfo;
    sessionStorage.setItem('_jwt', data.token);
    this.SetUserLanguageToProperties(data)

    if (!this.ADB2CAuthenticationEnabled && this.userInfo.isNewUser === true) {
      this.openDialog("NP");
    } else if (!this.ADB2CAuthenticationEnabled && this.userInfo.isPasswordExpired === true) {
      this.openDialog("CP");
    } else {
      this.loginSuccess = true;
      this.authentication.setToken();
      this.propertyValues = data.userProperties;
      this.multipleLocations = this.propertyValues.map(x => { return { id: x.propertyCode, name: x.propertyName } });
      this.userMachineInfo = await this.retailPropertySettingDataService.GetMachineNamesAndConfigurationSetting(this.userInfo.userId, GlobalConst.Product.SPA,
        this.propertyValues.map(x => x.propertyId));
      this.DisableLoginBtn = false;
      if (this.propertyValues.length > 1 || this.propertyValues.length == 0) {
        this.loginSuccess = true;
        this.buttonValidate = this.captions.ENTER;
      }
      this.enableLoginloader(false);
      if (this.propertyValues.length == 1) {
        this.loginSuccess = true;
        this.enablePropertySelection = true;
        const credentials = { UserName: this.muname, Password: this.password, TenantId: this.customerId != null ? this.customerId : 0, location: this.multipleLocations[0], ProductId: GlobalConst.Product.SPA, TenantCode: this.tenantCode };
        if (!fromPms) {
          await this.setMachineInfo(this.propertyValues[0].propertyId);
        }
        if (this.isMachineNameEnabled) {
          if (this.isPromptOnLoginEnabled && this.machineNames.length > 0) {
            this.enableLoginloader(false);
            this.loginSuccess = true;
            this.hideLoginForm = false;
            this.LoginFormGrp.addControl("location", new UntypedFormControl({}));
            this.LoginFormGrp.controls["location"].setValue(this.multipleLocations[0]);
          } else {
            let resp = this.ValidateCredentials(credentials, fromPms);
          }
        } else {
          let resp = this.ValidateCredentials(credentials, fromPms);
        }
      } else {
        this.hideLoginForm = false;
        this.LoginFormGrp.addControl("location", new UntypedFormControl({}));
      }
    }
  }

  private handleFailedLoginFromPMS(errorCode: number) {
    const error: string = (errorCode == 401) ? "You don’t have access to this application" : this.localization.getError(errorCode);
    this.utils.ShowErrorMessage('Error', error);
    sessionStorage.clear();
    this.loginSuccess = true;
  }

  private async setMachineInfo(propertyId: number, fromPms = false) {
    this.resetMachineNameInfo();
    const userMachinePropertyInfo = this.userMachineInfo.userPropertiesMachineInfo.find(x => x.propertyId == propertyId);
    const miscConfiguration = userMachinePropertyInfo.miscConfiguration;
    // TRANSACTION_BY_MACHINENAME
    this.isMachineNameEnabled = miscConfiguration.enableTransactionByMachineName;
    // SELECTION_ON_LOGIN
    this.isPromptOnLoginEnabled = miscConfiguration.promptOnLogin;
    if (miscConfiguration.printerManagerURI) {
      this.localization.SetPrinterManagerURI(miscConfiguration.printerManagerURI);
    }
    if (this.isMachineNameEnabled) {
      this.defaultMachineId = userMachinePropertyInfo.userDefault.defaultMachineId;
      this.machineNames = userMachinePropertyInfo.machineNames.map(x => {
        return {
          id: x.id,
          name: x.name
        }
      });
      if (this.isPromptOnLoginEnabled && this.machineNames.length > 0 && !fromPms) {
        this.LoginFormGrp.controls['machineName'].setValue('');
      }
    }
    const machineName = this.machineNames.find(x => x.id == this.defaultMachineId);
    this.defaultMachineId = machineName ? machineName.id : 0;
    if (this.defaultMachineId) {
      this.LoginFormGrp.controls['machineName'].setValue(machineName);
    } else if (fromPms && this.machineNames.length > 0) {
      this.LoginFormGrp.controls['machineName'].setValue(this.machineNames[0]);
    }
  }

  compareSelect = (val1, val2) => {
    return val1 && val2 && val1.id === val2.id;
  }

  private resetMachineNameInfo() {
    this.isMachineNameEnabled = false;
    this.isPromptOnLoginEnabled = false;
    this.defaultMachineId = 0;
    this.machineNames = [];
    this.LoginFormGrp.controls['machineName'].setValue('0');
  }

  private setMachineDetails() {
    const userMachine = this.LoginFormGrp.value.machineName;
    if (typeof (userMachine) == 'object') {
      this.localization.SetMachineId(userMachine.id);
      this.localization.SetMachineName(userMachine.name);
      this.propertyServices.SetMachinePrinterConfigForMachine(userMachine.id);
    } else {
      this.localization.SetMachineId(0);
      this.localization.SetMachineName('');
    }
  }

  SetFullStory(propertyConfig: any) {
    if (propertyConfig.configValue != undefined && propertyConfig.configValue[FULL_STORY_ORG_ID] != undefined) {
      FullStory.init({ orgId: propertyConfig.configValue[FULL_STORY_ORG_ID] });
      FullStory.identify('SPA-' + this.userInfo.userName, {
        "displayName": 'SPA-' + this.userInfo.userName,
        "productId": Product.SPA.toString(),
        "productName": "SPA",
        "tenantId": this.userInfo.tenantId?.toString() ?? "",
        "tenantCode": this.userInfo.tenantCode?.toString() ?? "",
        "propertyId": propertyConfig.propertyId?.toString() ?? "",
        "propertyName": this.propertyInfo.GetPropertyInfoByKey('PropertyName')
      });
    }
  }
  setValues() {
    this.uTempDataPrimary = this.utempdatautils.GetUTempData(3);
    this.uTempDataSecondary = this.utempdatautils.GetUTempData(1);
  }
  clearLclCookie(idname){
    this.LoginFormGrp?.markAsUntouched();
    this.localization.clearLocalCookie(idname);
    this.LoginFormGrp.controls['customerId'].setValue('');
    this.LoginFormGrp?.controls["customerId"].enable();
    this.showCustomerID = true;
    setTimeout(() => {
      this.custid.nativeElement.focus();
      }, 0);
    this.setVal();
  }
  GetServiceCall(host, callDesc, extraParams, uri?) {
    this.http.CallApiWithCallback<any>({
      host: host,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: callDesc,
      method: HttpMethod.Get,
      body: '',
      showError: true,
      extraParams: extraParams,
      uriParams: uri
    });
  }
  async validateAdb2cCredentialsForSupportUser(){
    var credentials = {
      productId: SPA_PRODUCT_ID,
      tenantId: this.LoginFormGrp.controls['tenantId'].value,
      supportTenantId: SUPPORT_TENANT
    };
    const serviceParams = {
      route: SPARoutes.SupportUserLogin,
      uriParams: '',
      header: '',
      body: credentials,
      showError: true,
      baseResponse: true
    };
    let loginDetails : any= await this.authentication.InvokeServiceCallAsync(SPARoutes.SupportUserLogin,GlobalConst.Host.CommonGateway,HttpMethod.Put,"",credentials)
    // const loginDetails : any = this.GetServiceCall(GlobalConst.Host.CommonGateway, 'CredentialsForSupportUser', serviceParams);
      if (loginDetails.successStatus) {
        let token = this.oauthService.getIdToken();
        loginDetails.result.token = token;
        const jwtToken = JSON.parse(atob(token.split('.')[1]));
        const jwtExpiryTime = new Date(jwtToken.exp * 1000);
        const timeout = jwtExpiryTime.getTime() - Date.now();
        const loginDuration = Math.round(timeout/1000);
        const loginResponse: any = loginDetails;
        if (loginResponse.result.loginDuration) {
          loginResponse.result.loginDuration = loginDuration;
          sessionStorage.setItem('loginDuration', loginResponse.result.loginDuration);
          localStorage.setItem('loginDuration', loginResponse.result.loginDuration);
          const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
          this.sessionManageService.startTimer(0, tokenDuration);
          sessionStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
          localStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
        }
        await this.successCallback(loginDetails, 'Login', null);
        await this.setPropertyForSupportUser(loginDetails);
      } else {
        if (loginDetails.errorCode == 5001) {
          // this.loginError = true;
          // this.loginButton.disabledproperty = false;
          // this.errResponse = loginDetails.errorDescription;
          this.enableLoginloader(false);
          this.hideLoginForm = false;
        }
        this.utils.showAlert(loginDetails.errorDescription, AlertType.Error, ButtonType.Ok, async (res) => {
          this.adb2cLogout();
        });
      }
    }

  async setPropertyForSupportUser(loginDetails){
    // Need to remove this hardcoded value
    loginDetails.result.userLoginInfo.isPropertyChangeAllow = true;
    this.userName = loginDetails.result.userLoginInfo.userName;
    this.userInfo = loginDetails.result.userLoginInfo;
    this.propertyServices.SetUserInfo(loginDetails.result);
    // this.setUserInfo(loginDetails);
    this.userDetail = loginDetails;
    let selectedProperty = this.allPropertyDetails.filter(x => x.id == this.LoginFormGrp.controls['propertyId'].value);
    if(!selectedProperty || !selectedProperty[0])
    {
      console.log('Error in property selected data');
      return;
    }
    let locationData = {
      id: selectedProperty[0].propertyCode,
      name: selectedProperty[0].propertyName

    };
    this.captionGenerator();
    this.LoginFormGrp.controls.location.setValue(locationData);
    this.enableLocation = true;
    this.propertyInformation = loginDetails.result.userProperties.find(item => item.propertyCode === locationData.id);
    let result = loginDetails.result.userProperties.find(item => item.propertyCode === locationData.id);
    const credentials = {
      propertyId: result.propertyId,
      productId: 1,
      userId: loginDetails.result.userLoginInfo.userId
    };
    this.CreateUserSession(credentials, this.isPMSEventTriggered);
    // this.setSessionandProperty(credentials);
  }

  async onTenantIdChange(eve){
    this.propertyIdListForATenant = this.filterPropertyByTenant(this.allPropertyDetails, eve.value);
    await this.updateSupportUserInfoOnTenantSelection(eve.value);
    if(!this.propertyIdListForATenant || this.propertyIdListForATenant.length <= 0){
      return;
    }
    this.LoginFormGrp.controls['propertyId'].setValue(this.propertyIdListForATenant[0].id);
    this.onPropertyIdChange({value: this.propertyIdListForATenant[0].id});
  }

  async onPropertyIdChange(eve){
    if(!this.userInfo || !eve){
      return;
    }
    this.userMachineInfo = await this.retailPropertySettingDataService.GetMachineNamesAndConfigurationSetting(this.userInfo.userId, GlobalConst.Product.SPA, [eve.value]);
    this.setMachineInfo(eve.value);
  }

  filterPropertyByTenant(data: any[], tenantId: number)
  {
    var tenantProperities =  cloneDeep(data.filter(x => x.tenantId == tenantId));
    return tenantProperities.map(x => {
      return{
        id: x.id,
        value: x.id,
        viewValue: x.propertyName
      };
    });
  }

  getTenantIdList(data: any[]){
    return data.map(x => {
      return{
        id: x.tenantId,
        value: x.tenantId,
        viewValue: x.contextName
      };
    });
  }

  async updateSupportUserInfoOnTenantSelection(tenantId: number)
  {
    const userParams = {
      route: SPARoutes.GetUserByTenantId,
      uriParams: { UserName : SUPPORT_USERNAME, tenantId : tenantId},
      header: '',
      showError: true,
      baseResponse: true
    };
    let userData : any= await this.authentication.InvokeServiceCallAsync(SPARoutes.GetUserByTenantId,GlobalConst.Host.CommonGateway,HttpMethod.Get,userParams.uriParams)

    this.userInfo = userData.result;
  }

  async ProcessSupportUserLogin(email: string)
  {
    let token = this.oauthService.getIdToken();
    sessionStorage.setItem(JWT_TOKEN, token);
    localStorage.setItem(JWT_TOKEN, token);
    this.commonLocalization.SetSupportUserMailId(email);
    this.commonLocalization.setLocalCookie('supportUserMailId',email);
    const mailValidationParams = {
      route: SPARoutes.ValidateUserByProductTenantAndEmail,
      uriParams: { productId: Product.SPA,tenantId : SUPPORT_TENANT, emailId: email},
      header: '',
      showError: true,
      baseResponse: true
    };
    let isUserExistsInSupportTenant : any = await this.authentication.InvokeServiceCallAsync(SPARoutes.ValidateUserByProductTenantAndEmail,GlobalConst.Host.CommonGateway,HttpMethod.Put,mailValidationParams.uriParams)

    if(!isUserExistsInSupportTenant?.result){
      await this.utils.showAlert(this.captions.err_userAccess_Denied_message,AlertType.Error, ButtonType.Ok).afterClosed().toPromise();
      this.sessionManageService.logout();
      return;
    }
    this.enableSupportUserInputElementsRequiredField(true);
    this.captionGenerator();
    // this.loginSuccessCaption = this.captions.SelectYourLoginDetails;
    const tenantParams = {
      route: SPARoutes.GetTenantGroupDetailByProductId,
      uriParams: { productId : GlobalConst.Product.SPA},
      header: '',
      showError: true,
      baseResponse: true
    };
    const propertyParams = {
      route: SPARoutes.GetPropertyDetailsByProductId,
      uriParams: { productId : GlobalConst.Product.SPA},
      header: '',
      showError: true,
      baseResponse: true
    };
    this.tenantData = await this.authentication.InvokeServiceCallAsync(SPARoutes.GetTenantGroupDetailByProductId,GlobalConst.Host.CommonGateway,HttpMethod.Get,tenantParams.uriParams)
    this.propertyData = await this.authentication.InvokeServiceCallAsync(SPARoutes.GetPropertyDetailsByProductId,GlobalConst.Host.CommonGateway,HttpMethod.Get,propertyParams.uriParams)
    let responses = await Promise.all([this.tenantData,this.propertyData]);
    this.allTenantDetails = responses[0].result;
    this.allPropertyDetails = responses[1].result;
    this.tenantIdList = this.getTenantIdList(this.allTenantDetails);
    this.tenantIdList.sort((a, b) => a.viewValue.localeCompare(b.viewValue))
    this.initialTenantIdList = [...this.tenantIdList]
    if(!this.tenantIdList || this.tenantIdList.length <= 0){
      console.log('Empty tenant List to display');
      return;
    }
    this.propertyIdListForATenant = this.filterPropertyByTenant(this.allPropertyDetails,this.tenantIdList[0].id);
    if(!this.propertyIdListForATenant || this.propertyIdListForATenant.length <= 0){
      console.log('Empty property List to display');
      return;
    }
    this.LoginFormGrp?.controls['tenantId']?.setValue(this.tenantIdList[0].id);
    this.LoginFormGrp?.controls['propertyId']?.setValue(this.propertyIdListForATenant[0].id);
    this.showCustomerID = false;
    this.hideLoginForm = false;
    this.enableLoginloader(false);
    this.loginSuccess = true;
    this.isSupportUser = true;
    await this.updateSupportUserInfoOnTenantSelection(this.tenantIdList[0].id);
    this.userMachineInfo = await this.retailPropertySettingDataService.GetMachineNamesAndConfigurationSetting(this.userInfo.userId, GlobalConst.Product.SPA, [this.propertyIdListForATenant[0].id]);
    this.setMachineInfo(this.propertyIdListForATenant[0].id);
  }

  enableSupportUserInputElementsRequiredField(isEnableRequiredField: boolean){
  if(!this.LoginFormGrp || !this.LoginFormGrp.controls['tenantId'] || !this.LoginFormGrp.controls['propertyId'] || !this.LoginFormGrp.controls['roleId']){
      return;
    }
    if(isEnableRequiredField)
    {
      this.LoginFormGrp.controls['tenantId'].addValidators(Validators.required);
      this.LoginFormGrp.controls['propertyId'].addValidators(Validators.required);
    }
    else{
      this.LoginFormGrp.controls['tenantId'].removeValidators(Validators.required);
      this.LoginFormGrp.controls['propertyId'].removeValidators(Validators.required);
    }
  }
  captionGenerator() {
    let allcaptions = this.localization.getCaptions();
    this.captions = allcaptions.login;
  }

  private _filter(value: string) {
    if(value){
      const filterValue = value.toLowerCase();
      return this.tenantIdList = this.initialTenantIdList.filter(x => (x.viewValue.toLowerCase().includes(filterValue)) || (x.id.toString().toLowerCase().includes(filterValue)))
    } else {
      return this.tenantIdList = this.initialTenantIdList;
    }

  }

  filterOptions(event) {
    this._filter(event.target.value);
  }

  openedChange(opened: boolean) {
    this.myInput.nativeElement.focus()
      if (!opened) {
      this.inputSearch = ''
      this._filter("");

    }
  }
  enableLoginloader(val:boolean)
  {
    if(this.LoginFormGrp?.valid && val)
    {
      this.showLoginloader=val?val:false;
      this.startInterval();
    }else{
      this.showLoginloader=false;
    }
  }
  startInterval() {
      // Set up the interval to execute a function every 1000 milliseconds (1 second)
      this.elapsedTime=0;
      this.intervalId = setInterval(() => {
      this.elapsedTime += 1000; // Increment elapsed time by 1 second
      // Check if 30 seconds have passed
      if ((document.getElementById("bodyId")?.getElementsByClassName("Errorpop-container-Golf").length > 0) ||
      (document.getElementById("bodyId")?.getElementsByClassName("errorpop-container").length > 0) || (document.getElementById("bodyId")?.getElementsByClassName("Errorpop-container").length > 0) ) {
      this.stopInterval(); // Clear the interval if the condition is met
      }
    if (this.elapsedTime >= 50000) {
     this.stopInterval(); // Clear the interval if 50 seconds have passed
    }}, 1000);
  }
  stopInterval() {
    // Clear the interval when called
    this.enableLoginloader(false);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}

export enum CommonControllersRoutes {
    GetTemplateDetailsByEventId = 'Template/GetTemplateDetailsByEventId/{eventId}',
    GetAllEventsByProductId = 'Event/productId/{productId}',
    GetEventNotificationGroupByProduct = 'Event/notificationGroup/productId/{productId}',
    GetEventNotificationGroupByEventId = 'Event/notificationGroup/eventId/{eventId}',
    SaveEventConfiguration = 'Event/configuration',
    GetAllTemplates = "Template/GetAllNotificationTemplatesByType/{typeId}/{showInActive}",
    GetTemplateKeywords = "Template/GetNotificationKeywordsByEvents/productId/{productId}",
    GetTemplatByNotificationTemplateId = "Template/GetTemplateDetails/{notificationTemplateId}/{languageId}",
    CreateorUpdateTemplate = "Template/CreateorUpdateTemplate",
    UpdateTemplate = "Template/UpdateTemplate/{id}",
    DeleteTemplate = "Template/{id}",
    DeleteNotificationTemplateContent ="Template/DeleteNotificationTemplateContent/{notificationTemplateId}/{languageId}",
    GetMaxListOrder = "Template/getmaxlistorder/typeId/{typeId}",
    GetDistributionList = 'distributionlist/productId/{productId}',
    GetGuestCategory = 'GuestCategory/productId/{productId}',
    GetAllEventActors = "event/actors/productId/{productId}",
    CreateDistributionList = "distributionlist",
    UpdateDistributionList = "distributionlist/id/{id}",
    DeleteDistributionList = 'distributionlist/id/{id}',
    UpdateListOrder = "Template/listorder/{fromOrder}/{toOrder}/typeId/{typeId}",
    DefaultTemplateExists = "Template/DefaultTemplateExists/id/{id}/typeId/{typeId}",
    GetMenus = 'EntityElement/GetEntityElements',
    GetUserClaimsAsync = 'user/GetUserClaimsAsync/{roleID}?{bkpn:QueryString}',
    LogUserMenuAccess = 'user/LogUserMenuAccess',
    ValidateLoginByToken = 'Property/validateLogin',
    QuickLoginByUserId = 'User/Login',
    QuickLoginByQuickId = 'Login/LoginByQuickId',
    CreateSession = 'User/session',
    GetUserClaimsForRoleAsync = 'user/GetUserClaimsForRoleAsync/{RoleId}',
    //Literals
    CreateLiterals = 'Literal/AddLiterals',
    GetAllLiteralByLiteralId = 'Literal/GetAllLiterlByLiteralId/{LiteralId}',
    UpdateLiterals = 'Literal/UpdateLiterals',
    GetAllLanguages = 'Language/GetAllLanguages',
    GetEntityElementsByLiteralId = 'EntityElement/GetEntityElements/{literalId}',
    UpdateEntityElements = 'EntityElement/UpdateEntityElements',
    UserInfoById = 'User/GetUserById/{userId}',
    //Components Linking
    GetComponentAccessTypeMaster = 'ComponentAccessTypeMaster/accessTypeMaster',
    GetComponentAccessTypeMasterById = 'ComponentAccessTypeMaster/accessTypeMaster/{id}',
    CreateComponentAccessTypeMaster = 'ComponentAccessTypeMaster/accessTypeMaster/create',
    UpdateComponentAccessTypeMaster ='ComponentAccessTypeMaster/accessTypeMaster/update/{id}',
    DeleteComponentAccessTypeMaster = 'ComponentAccessTypeMaster/accessTypeMaster/delete/{id}',
    GetComponentAccessTypeMasterByTypeId='ComponentAccessTypeMaster/accessTypeMaster/typeId/{id}',

    CreateComponentAccessTypeLink = "ComponentAccessTypeLink/accessTypeLink/multiple/create",
    GetComponentAccessTypeLink="ComponentAccessTypeLink/accessTypeLink",
    GetSFTPConfigLink = "NotificationService/GetSFTPSetup",
    GetSFTPSetupByProtocol = "NotificationService/GetSFTPSetupByProtocol/{protocol}",
    CreateSFTPConfigLink = "NotificationService/CreateSFTPSetup",
    UpdateSFTPConfigLink = "NotificationService/UpdateSFTPSetup",
    DeleteSFTPConfigLink = "NotificationService/DeleteSFTPSetup/{id}",
    CheckSFTPConnectionLink = "NotificationService/CheckSFTPConnection",
    GetSFTPConfigLinkById = "NotificationService/GetSFTPSetupById/{id}",
    CheckConnectionName = "NotificationService/CheckConnectionName?connectionName={connectionName}",
    IsSftpLinkedToJobCustomConfigurationAsync = "NotificationService/IsSftpLinkedToJobCustomConfigurationAsync/{id}",

    //JobScheduler
    IsServiceAccountExist = 'NotificationService/IsServiceAccountExist',
    GetAllScheduleJob = 'NotificationService/GetAllScheduleJob?includeInactive={includeInactive}',
    GetAllScheduleJobDefaultConfiguration = 'NotificationService/GetAllScheduleJobDefaultConfiguration',
    GetAllCustomUrlConfiguration = 'NotificationService/GetAllCustomUrlConfiguration',
    GetCustomUrlConfigurationById = 'NotificationService/GetCustomUrlConfigurationById/{id}',
    GetAllSchedule = 'NotificationService/GetAllSchedule?includeRelatedData={includeRelatedData}',
    GetScheduleById = 'NotificationService/GetScheduleById/{id}?includeRelatedData={includeRelatedData}',
    GetAllScheduleJobWildCard = 'NotificationService/GetAllScheduleJobWildCard?includeInactive={includeInactive}',
    GetAllJobHistory = 'NotificationService/GetAllJobHistory',
    RetryJobManually = 'NotificationService/RetryJobManually/{id}',
    ExportJobOutput = 'NotificationService/ExportJobOutput',
    GetAllScheduleOperations = 'NotificationService/GetAllScheduleOperations',
    CreateSchedule = 'NotificationService/CreateSchedule',
    UpdateSchedule = 'NotificationService/UpdateSchedule',
    DeleteSchedule = 'NotificationService/DeleteSchedule/{id}',
    PauseSchedule = 'NotificationService/PauseSchedule/{id}',
    ResumeSchedule = 'NotificationService/ResumeSchedule/{id}',
    RefreshDistributionList = 'NotificationService/RefreshDistributionList/{id}',

    //KakaoTemplateMapping
    GetAllKakaoTemplateMApping = 'NotificationService/GetAllKakaoTemplates?includeInactive={includeInactive}',
    GetKakaoTemplateCodes = 'NotificationService/GetKakaoTemplateCodes',
    CreateKakaoTemplateMapping = 'NotificationService/CreateKakaoTemplate',
    UpdateKakaoTemplateMapping = 'NotificationService/UpdateKakaoTemplateMapping',
    DeleteKakaoTemplateMapping = 'NotificationService/DeleteKakaoTemplateMapping/{id}',
     //UICache
    GetUICacheData = "UICache/GetUICacheData",
    //JobSchedulerConfiguration
    GetDefaultUrlConfiguration = 'NotificationService/GetDefaultJobConfigurationAsync',
    GetDefaultConfiguration = 'NotificationService/GetCustomJobConfigurationAsync',
    CreateJobSchedulerConfiguration = 'NotificationService/CreateCustomJobConfigurationAsync',
    UpdateJobSchedulerConfiguration = 'NotificationService/UpdateCustomJobConfigurationAsync',
    //ChatBot
    ChatBot="Bot"
}



export enum rGuesPayRoutes {
    GetDeviceInfo = "device/{deviceGuid}",
    GetDeviceList = "device/list",
    StandBy = "device/{deviceGuid}/standby",
    CardCapture = "ondemand/cardcapture/device/{deviceGuid}",
    GetCapturedCardInfo = "ondemand/cardcapture/device/{deviceGuid}"
}

export enum ReportRoutes {
    GetReport = 'ReportingService/report',
    GetSavedReport = 'ReportingService/Report/GetSavedReport/{documentId}',
    GenerateAndSaveReport = 'ReportingService/report/SaveReport',
    GetFinancialReport = 'ReportingService/report/GetFinancialReport',
    GenerateAndSaveReportForRF = 'AccountingService/Report/SaveReport'
}

export enum AccountingReportRoutes {
    GetReport = 'AccountingReports/report',
    GetSavedReport = 'AccountingReports/Report/GetSavedReport/{documentId}',
    GenerateAndSaveReport = 'AccountingReports/report/SaveReport',
    GetFinancialReport = 'AccountingReports/report/GetFinancialReport',
    GenerateAndSaveReportForRF = 'AccountingService/Report/SaveReport',
    GetZipFileByDocumentIds = 'AccountingReports/Report/GetZipFileByDocumentIds'
}
import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { PropertySettingDataService } from "../../../sytem-config/property-setting.data.service";
import { MiscellaneousSwitch } from "../../../shared/globalsContant";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { AlertAction, AlertType } from "src/app/common/shared/shared.modal";
import { ButtonType } from "src/app/retail/shared/globalsContant";
import { takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";

@Component({
  selector: "app-general-ledger-mapping-crud",
  templateUrl: "./general-ledger-mapping-crud.component.html",
  styleUrls: ["./general-ledger-mapping-crud.component.scss"],
})
export class GeneralLedgerMappingCrudComponent implements OnInit {
  commonCaptions: any;
  captions: any;
  generalLedgerForm: UntypedFormGroup;
  mappingData: any;
  isValid: boolean = false;
  businessUnitFromMisc: string;
  operatingUnitFromMisc: string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() dataInput: any;
  @Input() LinkType: number;

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private localization: RetailLocalization,
    private dialogRef: MatDialogRef<GeneralLedgerMappingCrudComponent>,
    private utilities: RetailUtilities,
    private setting: PropertySettingDataService
  ) {}

  async ngOnInit() {
    await this.getGLSettingsFromMisc();
    this.initializeForm();
    this.captions = this.localization.captions.retailsetup;
    this.commonCaptions = this.localization.captions.common;
    this.generalLedgerForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((val) => {
        this.isValid = this.generalLedgerForm.valid;
      });
    if (this.dataInput[0][this.dataInput[1]] != "—") {
      this.patchValue(this.dataInput);
    }
  }

  initializeForm() {
    this.generalLedgerForm = this.fb.group({
      glAccountId: ["", Validators.required],
      departmentId: ["", Validators.required],
      businessUnit: [this.businessUnitFromMisc || "", Validators.required],
      operatingUnit: [this.operatingUnitFromMisc || "", Validators.required],
      isActive: [true],
    });
    
    // Trigger validation for the entire form
    this.generalLedgerForm.updateValueAndValidity();
  }

  patchValue(data) {
    console.log(data);
    const propName = data[1]; // Extract dynamic property name
    const formData = data[0]; // Data object to pull values from
  
    // Patch form values
    this.generalLedgerForm.patchValue({
      glAccountId: formData[`${propName} GlAccountId`] || "",
      departmentId: formData[`${propName} DepartmentId`] || "",
      businessUnit: formData[`${propName} BusinessUnit`] || this.businessUnitFromMisc,
      operatingUnit: formData[`${propName} OperatingUnit`] || this.operatingUnitFromMisc,
      isActive: formData[`${propName} IsActive`] !== undefined ? formData[`${propName} IsActive`] : true,
    });
  }

  saveMappingType() {
    if (this.generalLedgerForm.invalid) {
      this.utilities.showAlert(
        this.captions.showInputValidateMessage,
        AlertType.Error,
        ButtonType.Ok
      );
      return;
    }
  
    this.mappingData = this.generalLedgerForm.getRawValue();
    this.dialogRef.close(this.mappingData);
  }

  onClick() {
    this.dialogRef.close();
  }

  onNoClick() {
    if (this.isValid) {
      this.utilities.showAlert(
        this.commonCaptions.saveChangesMessage,
        AlertType.Warning,
        ButtonType.YesNo,
        (res) => {
          if (res === AlertAction.YES) {
            this.dialogRef.close();
          }
        }
      );
    } else {
      this.isValid = false;
      this.dialogRef.close();
    }
  }

  isActivechange(event) {
    this.generalLedgerForm.controls.isActive.setValue(event);
  }

  private async getGLSettingsFromMisc() {
    const miscSettings = await this.setting.GetMiscConfigurationSetting();
    const businessUnitSetting = miscSettings.find(
      (x) => x.switch === MiscellaneousSwitch.BUSINESS_UNIT_VALUE
    );
    const operatingUnitSetting = miscSettings.find(
      (x) => x.switch === MiscellaneousSwitch.OPERATING_UNIT_VALUE
    );
  
    this.businessUnitFromMisc = businessUnitSetting?.value?.toString() || '';
    this.operatingUnitFromMisc = operatingUnitSetting?.value?.toString() || '';
  }
}

import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { Localization } from 'src/app/common/localization/localization';
import { AgDateRangeConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { SpaOccupancyBusiness } from './spa-occupancy.business';
import { AppliedEnum } from './spa-occupancy.model';
import { ReplaySubject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { AlertType, ButtonType, SPAScheduleBreakPoint } from 'src/app/shared/globalsContant';
import { AlertAction } from 'src/app/common/enums/shared-enums';

@Component({
  selector: 'app-spa-occupancy',
  templateUrl: './spa-occupancy.component.html',
  styleUrls: ['./spa-occupancy.component.scss'],
  providers: [SpaOccupancyBusiness],
  encapsulation: ViewEncapsulation.None
})
export class SpaOccupancyComponent implements OnInit {

  captions: any;
  occupancyForm: FormGroup;
  dateRangeInputs: AgDateRangeConfig;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  headerOptions = [];
  tableContent = [];
  originalTableContent = [];
  floatLabel: string;
  overrideOcc: string = '0';
  sorTypeenum = SorTypeEnum;
  appliedEnum = AppliedEnum;
  sortType: string;
  appliedAll: string = '0';
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isViewOnly: boolean = true;
  occupancyTableEnabled: boolean = true;
  occupancyTableChanged:boolean = false;
  startDate: string;
  endDate: string;
  hasPageAccess: boolean = true;
  @ViewChild('picker') picker;

  constructor(private localization: Localization, private fb: FormBuilder, public business: SpaOccupancyBusiness, 
    private utils: SpaUtilities, private propertyInfo: SpaPropertyInformation, private breakpointAccess: BreakPointAccess) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.hasPageAccess = this.breakpointAccess.CheckForAccess([SPAScheduleBreakPoint.SpaOccupancy]);
    if(!this.hasPageAccess)
    {
      window.history.back();
    }
    if(this.hasPageAccess)
    {
      this.isViewOnly = this.breakpointAccess.IsViewOnly(SPAScheduleBreakPoint.SpaOccupancy);
      this.initialize();
      this.headerOptions = this.business.getHeaderOptions();
      this.occupancyForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
        this.actionButton.disabledproperty = !(this.occupancyForm.valid && this.occupancyForm.dirty);
      });
    }
  }

  initialize() {
    const propertyDate = this.localization.ConvertDateToISODateTime(this.propertyInfo.CurrentDate);
    this.occupancyForm = this.fb.group({
      startDate: [propertyDate, Validators.required],
      endDate: [propertyDate, Validators.required],
    })
    this.startDate = propertyDate;
    this.endDate = propertyDate;
    this.dateRangeInputs = {
      form: this.occupancyForm,
      startDateControlName: 'startDate',
      endDateControlName: 'endDate',
      placeHolder: "Date",
      isDateRequired: true,
      className: 'ag_form-control--1',
      errorMessage: this.captions.lbl_missingDate,
      minDate:this.localization.getDate(this.propertyInfo.CurrentDate)

    }
    this.actionButton = {
      type: "primary",
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: "secondary",
      label: this.captions.btn_cancel,
    };
    this.getOccupancyDetails();
  }

  startDateChange(e) {
    if(this.occupancyTableChanged) {
      this.utils.showAlert(this.captions.warn_dateChangelost, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.NO) {
          this.occupancyTableEnabled = true;
          this.occupancyForm.controls.startDate.setValue(this.startDate);
          this.occupancyForm.controls.endDate.setValue(this.endDate);
          this.occupancyForm.updateValueAndValidity();
        }
      });
    }
    this.occupancyTableEnabled = this.occupancyForm.controls.startDate.valid && this.occupancyForm.controls.endDate.valid ? true : false
  }
  endDateChange(e) {
    this.occupancyTableEnabled = this.occupancyForm.controls.startDate.valid && this.occupancyForm.controls.endDate.valid ? true : false;
      this.getOccupancyDetails();
  }

  pickerOpenChange(e) {
    this.startDate = this.occupancyForm.get('startDate').value
    this.endDate = this.occupancyForm.get('endDate').value
  }

  appliedToggleChange(e, rowData) {

    if (rowData == 'all') {
      this.tableContent.forEach(x => {
        x.applied = e.value;
        x.overrideOcc = 0;
    });
    this.overrideOcc = '0';
    }
    else {
      rowData.applied = e.value;
      rowData.overrideOcc = 0;
    }
    // if(e.value == '0'){
    //   this.occupancyForm.markAsDirty();
    //   this.saveButtonValidation();
    // }
    this.occupancyForm.markAsDirty();
    this.saveButtonValidation();
    this.occupancyTableChanged = true;
  }

  async onSave() {
    let saveData = this.business.mapUpdateOccupancy(this.tableContent, this.occupancyForm.get('startDate').value);
    this.utils.ToggleLoader(true);
    var response = await this.business.saveOccupancy(saveData);
    this.utils.ToggleLoader(false);
    if(response){
      this.originalTableContent = [...this.tableContent];
      this.utils.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok);
    }
    else
      this.utils.showError(this.localization.getError(-2));
  }

  onCancel() {
    this.occupancyForm.reset();
    const propertyDate = this.localization.ConvertDateToISODateTime(this.propertyInfo.CurrentDate);
    this.occupancyForm.controls.startDate.setValue(propertyDate);
    this.occupancyForm.controls.endDate.setValue(propertyDate);
    this.occupancyTableEnabled = true;
    this.tableContent = this.originalTableContent.map(obj => ({...obj}));
  }

  updateOverrideValue(e) {
    if(e.target.value > 100) {
      e.preventDefault();
      this.occupancyForm.setErrors({invalid : true})
    } else {
      this.tableContent.forEach(x => x.overrideOcc = e.target.value);
      if(this.occupancyForm.controls.startDate.valid && this.occupancyForm.controls.endDate.valid)
      this.occupancyForm.setErrors(null)
    }
    this.occupancyForm.markAsDirty();
    this.saveButtonValidation();
    this.occupancyTableChanged = true;
  }
  IndividualOverride(e) {
    if(e.target.value > 100) {
      e.preventDefault();
      this.occupancyForm.setErrors({invalid : true})
    } else {
      if(this.occupancyForm.controls.startDate.valid && this.occupancyForm.controls.endDate.valid)
      this.occupancyForm.setErrors(null)
    }
    this.occupancyForm.markAsDirty();
    this.saveButtonValidation();
    this.occupancyTableChanged = true;
  }

  setSortField(key: string) {
    key;
    this.headerOptions.forEach(x => {
      if (x.sortingKey == key) {
        if (!x.sortType) {
          x['sortType'] = this.sorTypeenum.asc;
        } else if (x.sortType) {
          x['sortType'] = x.sortType == SorTypeEnum.asc ? SorTypeEnum.desc : SorTypeEnum.asc
        }
        this.sortType = x.sortType;
      } else {
        x['sortType'] = null;
      }
    })
    this.originalTableContent.forEach(record => {
      this.sortType = SorTypeEnum.asc ? record.sort((a, b) => a[key].localeCompare(b[key])) : record.sort((a, b) => b[key].localeCompare(a[key]))
    });
    this.tableContent = [...this.tableContent]
  }

  async getOccupancyDetails() {
    this.occupancyTableChanged = false;
    let startDate = this.occupancyForm.get('startDate').value;
    let endDate = this.occupancyForm.get('endDate').value;
    if (startDate && endDate) {
      this.utils.ToggleLoader(true);
      var response = await this.business.getOccupancyDetails(this.localization.convertDateTimeToAPIDateTime(startDate), this.localization.convertDateTimeToAPIDateTime(endDate));
      this.tableContent = this.business.mapOccupancyDetails(response);
      this.originalTableContent = this.tableContent.map(obj => ({...obj}));
      this.utils.ToggleLoader(false);
    }
  }

  saveButtonValidation(){
    this.actionButton.disabledproperty = !(this.occupancyForm.valid && this.occupancyForm.dirty);
  }
  disableDates() {
    this.occupancyForm.controls.startDate.disable();
    this.occupancyForm.controls.endDate.disable();
  }
  enableDates() {
    this.occupancyForm.controls.startDate.enable();
    this.occupancyForm.controls.endDate.enable();
  }
}

import { Injectable } from "@angular/core";
import { HttpMethod, HttpServiceCall } from "../../shared/service/http-call.service";
import { BaseResponse, PaymentMethods } from "../../shared/business/shared.modals";
import { Host, USER, THERAPIST } from "../../shared/globalsContant";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { DropDownData, TherapistData, LinkCodes, MultiPackTypes, Users, AllReports, PaymentMethodDetail } from "../business/report.modals";
import { RetailPropertyInformation } from "../../common/services/retail-property-information.service";
import { ReportBusinessService } from "../business/report-business.service";
import { SubPropertyModel, CustomField } from "../retail-reports.modal";
import * as _ from 'lodash';
import * as myGlobals from '../../shared/globalsContant';
import { RetailDataAwaiters } from "../../shared/events/awaiters/retail.data.awaiters";
import { RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';
import { Subject } from "rxjs";
import { Vendor } from "../../retail-vendor-setup/retail-vendor-setup.modals";
import { map } from "jquery";
@Injectable()
export class ReportDataService {
    notifier: Subject<void> = new Subject<void>();

    constructor(private http: HttpServiceCall, private utils: RetailUtilities, 
        private localization: RetailLocalization, private propertyInfo: RetailPropertyInformation,
        private business: ReportBusinessService, private featureFlagInfo: RetailFeatureFlagInformationService) {
    }

    public async getAllLocation(): Promise<DropDownData[]> {
        let locations = await this.invokeCalls<any[]>('GetAllLoc', HttpMethod.Get, Host.spaManagement);
        return locations.map(loc => {
            return {
                id: loc.id,
                description: loc.description,
                showInDropDown: true
            };
                });
    }

    public async getCustomField(customFieldColumnName: string): Promise<DropDownData[]> {

        let customFields = await this.invokeCalls<CustomField[]>('GetCustomFieldsWithValues', HttpMethod.Get, Host.spaManagement);
        return customFields.find(cf => cf.columnName.toLowerCase() == customFieldColumnName.toLowerCase())
            .customFieldValues.map(loc => {
                return {
                    id: loc.id,
                    description: loc.description,
                    showInDropDown: true
                }
            });
    }

    public async getAllTherapist(reportCode: string, includeInactive: boolean = true): Promise<DropDownData[]> {
        let therapists = await this.invokeCalls<any[]>('GetAllTherapist', HttpMethod.Get, Host.spaManagement);
        therapists = this.fillAllValidTherapist(therapists, reportCode);
        let data: DropDownData[] = therapists.map(therapist => {
            return {
                description: therapist.firstName + ' ' + therapist.lastName,
                id: therapist.id,
                isActive: therapist.isActive,
                showInDropDown: therapist.isActive
            }
        });
        if (!includeInactive) {
            data = this.business.toggleIncludeInactive(false, data);
        }

        return data;
    }

    public async getAllLinkCodes(): Promise<DropDownData[]> {
        let _result = await this.invokeCalls<any[]>('GetAllLinkCodes', HttpMethod.Get, Host.spaManagement);
        return _result.map((lc: LinkCodes) => {
            let _isActive: boolean = (this.utils.getDate(lc.startDate) <= this.propertyInfo.CurrentDate && this.propertyInfo.CurrentDate >= this.utils.getDate(lc.endDate));
            return {
                id: lc.id,
                description: lc.description,
                isActive: _isActive,
                showInDropDown: true
            }
        });
    }

    public async getAllMultiPacks(): Promise<DropDownData[]> {
        let _result = await this.invokeCalls<any[]>('getAllMultiPacks', HttpMethod.Get, Host.retailPOS, { isMultipack: true });
        return _result.map((mt: MultiPackTypes) => {
            return {
                id: mt.id,
                description: mt.itemDescription,
                showInDropDown: true
            }
        });
    }

    public async getAllOutlets(): Promise<DropDownData[]> {
        let _result = await this.invokeCalls<any[]>('GetOutletsByProperty', HttpMethod.Get, Host.retailManagement, null, { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
        return _result.map((lc: SubPropertyModel) => {
            return {
                id: lc.subPropertyID,
                description: lc.subPropertyName,
                showInDropDown: true
            };
        });
    }

    public async getAllOutletsByUser(): Promise<DropDownData[]> {
        let _result = await this.invokeCalls<any[]>('GetSubPropertyAccessByUser', HttpMethod.Get, Host.retailManagement, 
                            null, { userId: this.utils.GetPropertyInfo("UserId") });
        return _result.map((lc: any) => {
            return {
                id: lc.subPropertyID,
                description: lc.subPropertyName,
                showInDropDown: lc.isActive,
                isActive: lc.isActive
            };
        });
    }

    public async getAllVendor(): Promise<DropDownData[]> {
        const vendors = await this.invokeCalls<any[]>('GetVendorInfo', HttpMethod.Put, Host.retailManagement);
        return  vendors.map((lc: any) => {
        return {
            id: lc.id,
            description: lc.name,
            showInDropDown: lc.isActive,
            isActive: lc.isActive
        };
    });

    }
    public async GetAllCategory(): Promise<DropDownData[]> {
        let _result = await this.invokeCalls<any[]>('GetCategoryAndSubCategoryLink', HttpMethod.Get, Host.retailManagement, null, { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
        return _result.map((lc: LinkCodes) => {
            let _isActive: boolean = (this.propertyInfo.CurrentDate <= this.utils.getDate(lc.endDate));
            return {
                id: lc.id,
                description: lc.description,
                isActive: _isActive,
                showInDropDown: true
            };
        });
    }


    public async GetAllCategoryAndSubCategoryLink(): Promise<DropDownData[]> {
        try {
            return await this.invokeCalls<any[]>('GetCategoryAndSubCategoryLink', 
            HttpMethod.Get, Host.retailManagement, null, { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    /**
     * GetAll
     */
    public async GetAllUsers(): Promise<DropDownData[]> {
        let response = await this.invokeCalls<Users[]>('GetAllUsers', HttpMethod.Get, 
        Host.authentication, null, { tenantId: Number(this.utils.GetPropertyInfo('TenantId')) });
        return response.map((element: Users) => {
            return {
                id: element.userId,
                description: element.firstName + ' ' + element.lastName,
                showInDropDown: true,
                isActive: element.isActive
            };
        });

    }

    public async GetAllUsersByPropertyId(): Promise<DropDownData[]> {
        const response = await this.invokeCalls<Users[]>('GetUserInfoByPropertyId', HttpMethod.Get,
            Host.authentication, null, {
            propertyId: Number(this.utils.GetPropertyInfo('PropertyId')),
            productId: Number(this.utils.GetPropertyInfo('ProductId'))
        });
        return response.map((element: Users) => {
            return {
                id: element.userId,
                description: element.firstName + ' ' + element.lastName,
                showInDropDown: true,
                isActive: element.isActive
            };
        }).sort((a, b) => a.description.localeCompare(b.description));

    }

    public async GetAllCategoryGroups(): Promise<DropDownData[]> {
        let response = await this.invokeCalls<any[]>('GetAllCategoryGroups', HttpMethod.Get, Host.retailManagement, null, { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
        let clerks = [];
        response.sort((a, b) => a.name.toLowerCase() !== b.name.toLowerCase() ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 : 0);
        clerks = response.map((element: any) => {
            return {
                id: element.id,
                description: element.name,
                showInDropDown: element.isActive,
                isActive: element.isActive
            };
        });
        return clerks;

    }

    public async GetAllCategories(): Promise<DropDownData[]> {
        let response = await this.invokeCalls<any[]>('GetAllCategories', HttpMethod.Get, Host.retailManagement, null, { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
        response.sort((a, b) => a.name.toLowerCase() !== b.name.toLowerCase() ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 : 0);
        return response.map(element => {
            return {
                id: element.id,
                description: element.name,
                categoryGroup: element.retailCategoryGroupId,
                isActive: element.isActive,
                listOrder: element.listOrder,
                eligibleForDiscount: element.eligibleForDiscount,
                showInDropDown: element.isActive
            }
        });

    }

    public async GetDiscountTypes(): Promise<DropDownData[]> {
        let response = await this.invokeCalls<any[]>('GetDiscountTypes', HttpMethod.Get, Host.retailManagement, null);
        return response.map(element => {
            return {
                id: element.id,
                description: element.type,
                active: element.isActive,
                listOrder: element.listOrder,
                showInDropDown: true
            };
        });

    }

      public async GetMachineName(): Promise<DropDownData[]> {
        let response = await this.invokeCalls<any[]>('GetMachineNames', HttpMethod.Get, Host.authentication, null);
        response = _.orderBy(response, 'listOrder', 'asc');
        return response.map(element => {
            return {
                id: element.id,
                description: element.name,
                active: element.isActive,
                listOrder: element.listOrder,
                showInDropDown: true
            };
        });

    }

    private fillAllValidTherapist(Alltherapist: TherapistData[], reportCode: string): TherapistData[] {
        if (reportCode == AllReports.ServiceCharge) {
            Alltherapist = Alltherapist.filter(therapist => therapist.allowServiceCharge);
        } else if (reportCode == AllReports.CommissionReport) {
            Alltherapist = Alltherapist.filter(therapist => therapist.allowCommission);
        } else if (reportCode == AllReports.Gratuity) {
            Alltherapist = Alltherapist.filter(therapist => therapist.allowGratuity);
        }
        return Alltherapist;
    }


    private async invokeCalls<T>(callDesc: string, callType: HttpMethod, host: Host, body?: any, uRIParams?: any): Promise<T> {
        this.notifier = new Subject<void>();
        let response: BaseResponse<T> = await this.http.cancellableObservalble<T>({
            callDesc: callDesc,
            host: host,
            method: callType,
            body: body,
            uriParams: uRIParams
        }, this.notifier).toPromise();

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage("Error", errMsg);
    }

    public async getAllTherapistWithStaffType(reportCode: string, includeInactive: boolean = true): Promise<DropDownData[]> {
        let therapists = await RetailDataAwaiters.getAllTherapists();
        if (therapists == null) {
            return Promise.resolve([]);
        }
        therapists = this.fillAllValidTherapist(therapists, reportCode);
        let data: DropDownData[] = therapists.map(therapist => {
            return {
                description: therapist.firstName + ' ' + therapist.lastName,
                id: therapist.id,
                isActive: therapist.isActive,
                showInDropDown: therapist.isActive,
                staffType: THERAPIST
            }
        });
        if (!includeInactive) {
            data = this.business.toggleIncludeInactive(false, data);
        }

        return data;
    }

    public async getAllStaffs(reportCode,productId:number): Promise<DropDownData[]> {
        const therapists = await this.getAllTherapistWithStaffType(reportCode);
        const users = await this.GetAllUsersByPropertyId();
        const usersreportconfig: any = await this.invokeCalls('GetAllUserRetailConfiguration', HttpMethod.Get, Host.retailManagement);

        let userData = [];
        users.forEach((element) => {
            let data = usersreportconfig.filter((res) => {
                return res.userId === element.id;
            });

            let FormattedUserData = data.map(res => {
                if ((reportCode === AllReports.CommissionReport && res.allowCommission)
                    || (reportCode === AllReports.Gratuity && res.allowGratuity)
                    || (reportCode === AllReports.ServiceCharge && res.allowServiceCharge) || (reportCode === AllReports.Surcharges)) {
                    return {
                        description: element.description,
                        id: res.userId,
                        isActive: element.isActive,
                        showInDropDown: element.isActive,
                        staffType: USER
                    };
                } else if (reportCode === AllReports.StaffMemberSummary) {
                    if (res.allowCommission || res.allowGratuity || res.allowServiceCharge) {
                        return {
                            description: element.description,
                            id: res.userId,
                            isActive: element.isActive,
                            showInDropDown: element.isActive,
                            staffType: USER
                        };
                    }
                }
            });
            FormattedUserData[0] ? userData.push(FormattedUserData[0]) : '';
        });

        if(productId == myGlobals.Product.SPA && reportCode == AllReports.StaffMemberSummary)
        {
            therapists.forEach(element => {
                element.description =  element.description + this.localization.captions.common.TherapistDropDown
            });

            userData.forEach(element => {
                element.description =  element.description +this.localization.captions.common.UserDropDown
            });
        }

        let allStaff = _.concat(therapists, userData);
        return _.orderBy(allStaff, [user => user.description.toLowerCase()], 'asc')
    }

    public async GetPaymentMethodByProduct(): Promise<DropDownData[]> {
        let response = await this.invokeCalls<PaymentMethodDetail[]>('PaymentMethod', HttpMethod.Get, Host.payment, null);
        return response.map((element: PaymentMethodDetail) => {
            return {
                id: element.paymentTypeId,
                description: this.localizePaymentMethod(element.paymentTypeId, element.paymentMethod),
                showInDropDown: !([PaymentMethods.IDTECH, PaymentMethods.PendingSettlement, PaymentMethods.V1GiftCardIdTech, PaymentMethods.ExternalGiftCardIdTech, PaymentMethods.AgilysysGiftCardIdTech].includes(element.paymentTypeId)),
                isActive: element.isActive,
            };
        });
    }

    localizePaymentMethod(type: number, desc: string): string {
        let paymentName = myGlobals.PaymentMethodValue[type];
        if (paymentName == desc) {
            let paymentMethodName = this.localization.captions.shop.paymentMethods[type.toString()];
            switch (type) {
                case PaymentMethods.ExternalGiftCard:
                case PaymentMethods.AgilysysGiftCard:
                    if (this.featureFlagInfo.GatewayType) {
                        paymentMethodName = `${this.featureFlagInfo.GatewayType} ${this.localization.captions.shop.GiftCard.GiftCard}`;
                    }
                    break;
                default:
                    break;
            }
            return paymentMethodName ? paymentMethodName : desc;
        }
        return desc;
    }

    cancelOngoingCall() {
        this.notifier.next();
        this.notifier.complete();
    }
}
